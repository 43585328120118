/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import SelectBlock from '../../../../components/SelectBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectService from '../../../../services/project/projectService';
import constants from '../../../../constants/constants';

const ProjectListingPage = (props) => {
  const { showAlert, auth, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectFilter, setProjectFilter] = useState({ label: 'Active', value: 'Active' });

  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getAllProjects();
  }, [projectFilter]);

  const getAllProjects = () => {
    setIsLoading(true);
    projectService.getProjects(projectFilter.value === 'Completed').then((res) => {
      setProjects(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteProject = (id) => {
    hideConfirmModal();
    setIsLoading(true);

    projectService.deleteProject(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllProjects();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const downloadDirectory = async (projectId) => {
    setIsLoading(true);
    try {
      window.open(`${constants.API_URL}ProjectDocument/DownloadProjectDirectory?token=${auth.authToken}&projectId=${projectId}`, '_blank');
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Details"
              className="primary--bg ml-0"
              size="xxs"
              to={`/project/edit/${projects[dataIndex].id}`}
            />

            <Button
              text="Download Directory"
              className="info--bg"
              size="xxs"
              onClick={() => {
                downloadDirectory(projects[dataIndex].id);
              }}
            />

            {
              auth.accessLevel >= 50 && (
                <Button
                  text="Delete"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Delete Project',
                      text: 'Are you sure you want to delete this project?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        deleteProject(projects[dataIndex].id);
                        hideConfirmModal();
                      }
                    });
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'projectNumber',
      label: 'Project#',
      options: columnOptions,
    },
    {
      name: 'client',
      label: 'Client',
      options: columnOptions,
    },
    {
      name: 'name',
      label: 'ProjectName',
      options: columnOptions,
    },
    {
      name: 'contractors',
      label: 'Contractor(s)',
      options: columnOptions,
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Projects"
                headerSize="lg"
                primaryButtonText={auth.accessLevel >= 40 ? 'New Project' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonTo="/project/create"
              />
              <ContentBlock cols={3}>
                <SelectBlock
                  label="Filter"
                  placeholder="Select a project"
                  id="show-project"
                  name="show-project"
                  options={[{ label: 'Active', value: 'Active' }, { label: 'Completed', value: 'Completed' }]}
                  isRequired
                  value={projectFilter}
                  onChange={(opt) => {
                    setProjectFilter(opt);
                  }}
                />
              </ContentBlock>
              <CustomBlock>
                <MUIDataTable
                  data={projects}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* CONFIRM DELETE MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isConfirmDeleteModalVisible}
        size="md"
        contentHeader="Delete Client"
        contentDescription="Are you sure you want to delete this client?"
        primaryModalActionText="Delete"
        primaryModalActionColor="danger--bg"
        primaryModalActionOnClick={() => { }}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="grey--bg"
        onHide={() => {
          setIsConfirmDeleteModalVisible(false);
        }}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(ProjectListingPage);