import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'DailyFlangeLogForm/';

const getDailyFlangeLogFormById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetDailyFlangeLogFormById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const createDailyFlangeLogForm = async (projectId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateDailyFlangeLogForm?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const submitDailyFlangeLogForm = async (id) => axiosInstance.post(`${ENDPOINTORIGIN}SubmitDailyFlangeLogForm?dailyFlangeLogFormId=${id}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteDailyFlangeLogForm = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteDailyFlangeLogForm?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getDailyFlangeLogFormLineItems = async (projectId, dailyFlangeLogFormId) => axiosInstance.get(`${ENDPOINTORIGIN}GetDailyFlangeLogFormLineItems?projectId=${projectId}&dailyFlangeLogFormId=${dailyFlangeLogFormId}`, constants.REQUIRE_INTERCEPTORS);

const createDailyFlangeLogFormLineItem = async (dailyFlangeLogFormId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateDailyFlangeLogFormLineItem?dailyFlangeLogFormId=${dailyFlangeLogFormId}`, {}, constants.REQUIRE_INTERCEPTORS);

const updateDailyFlangeLogFormLineItem = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateDailyFlangeLogFormLineItem`, model, constants.REQUIRE_INTERCEPTORS);

const deleteDailyFlangeLogFormLineItem = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteDailyFlangeLogFormLineItem?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getDailyFlangeLogFormById,
  createDailyFlangeLogForm,
  submitDailyFlangeLogForm,
  deleteDailyFlangeLogForm,
  getDailyFlangeLogFormLineItems,
  createDailyFlangeLogFormLineItem,
  updateDailyFlangeLogFormLineItem,
  deleteDailyFlangeLogFormLineItem
};