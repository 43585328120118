/* eslint-disable arrow-parens */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */

// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import {
  alpha,
  makeStyles,
  withStyles
} from '@material-ui/core/styles';
// ICONS
import {
  FcFolder,
  FcOpenedFolder,
  FcDocument
} from 'react-icons/fc';
import {
  TbTrash,
  TbPencil,
  TbFilePlus,
  TbFolderPlus,
  TbCloudDownload
} from 'react-icons/tb';
// COMPONENTS
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { Modal, Button } from 'react-bootstrap';
import { FilePond, registerPlugin } from 'react-filepond';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// CUSTOM COMPONENTS
import InputBlock from './InputBlock';
import Section from './Section';
import ContentBlock from './ContentBlock';
import CustomBlock from './CustomBlock';
import FormBlock from './FormBlock';
import ModalBlock from './ModalBlock';
import CheckboxBlock from './CheckboxBlock';
import FileUploadBlock from './FileUploadBlock';
// SERVICES AND HELPERS
import * as _ from 'lodash';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import * as confirmModal from '../redux/confirmModalRedux';
// ASSETS
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// import constants from '../constants/constants';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const TransitionComponent = (props) => {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
    }
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: { '& .close': { opacity: 0.3 } },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  }
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
  root: {
    height: '100%',
    flexGrow: 1,
    maxWidth: 460
  }
});

const RecursiveDocumentTree = props => {
  const { showConfirmModal, hideConfirmModal } = props;

  const {
    folderStructure,
    setFolderStructure,
    addNewFileFolder,
    renameFileFolder,
    deleteFileFolder,
    isActionsVisible,
    downloadFile
  } = props;

  const [showIndex, setShowIndex] = useState(-1);
  const [editObj, setEditObj] = useState({
    id: -1,
    name: '',
    type: ''
  });
  const [isMulti, setIsMulti] = useState(false);
  const classes = useStyles();
  const [file, setFile] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploaded, setUploaded] = useState(0);
  const [uploading, setUploading] = useState(0);
  const mainIconSize = 35;
  const actionIconSize = 30;

  const deleteRecursively = (arr, id) => {
    for (const node of arr) {
      if (node.id === id) {
        const index = arr.findIndex(x => x.id === id);
        arr.splice(index, 1);
      }

      if (node.children) {
        deleteRecursively(node.children, id);
      }
    }
  };

  const actions = [
    {
      id: 1,
      icon: <TbFolderPlus size={actionIconSize} />,
      action: (obj) => setEditObj({ ...obj, type: 'Add Folder', name: '' }),
      folderOnly: true
    },
    {
      id: 2,
      icon: <TbFilePlus size={actionIconSize} />,
      action: (obj) => setEditObj({ ...obj, type: 'Add File', name: '' }),
      folderOnly: true
    },
    {
      id: 3,
      icon: <TbPencil size={actionIconSize} />,
      action: (obj) => {
        if (obj.id === 99999) {
          return;
        }
        setEditObj({
          ...obj,
          type: `Rename ${obj.children ? 'Folder' : 'File'}`
        });
      },
      folderOnly: false
    },
    {
      id: 4,
      icon: <TbTrash size={actionIconSize} />,
      action: (obj) => {
        if (obj.id === 99999) {
          return;
        }
        showConfirmModal({
          title: 'Delete File/Folder',
          text: 'Are you sure you want to remove this file/folder?',
          rightBtnText: 'Confirm',
          btnAction: () => {
            deleteFileFolder(obj.id)
              .then(() => {
                const clonedFolderStructure = _.cloneDeep(folderStructure);
                deleteRecursively(clonedFolderStructure.children, obj.id);

                setFolderStructure(clonedFolderStructure);
                hideConfirmModal();
              })
              .catch(() => {
              });
          }
        });
      },
      folderOnly: false
    }
  ];

  const findNodeById = (arr, id) => {
    for (const node of arr) {
      if (node.id === id) return node;

      if (node.children) {
        const desiredNode = findNodeById(node.children, id);
        if (desiredNode) return desiredNode;
      }
    }

    return false;
  };

  const renameRecursively = (id, name) => {
    renameFileFolder(id, name).then(() => {
      const clonedFolderStructure = _.cloneDeep(folderStructure);

      const nodeFound = findNodeById(clonedFolderStructure.children, id);
      nodeFound.name = name;

      setFolderStructure(clonedFolderStructure);
      hideModalHandler();
    });
  };

  const addFolderHandler = (id, name) => {
    if (name === '') {
      return;
    }

    addNewFileFolder({
      parentId: id === 99999 ? null : id,
      name,
      file: null
    }).then(res => {
      const clonedFolderStructure = _.cloneDeep(folderStructure);

      const nodeFound = findNodeById([clonedFolderStructure], id);
      const newObj = {
        name,
        children: null,
        id: res.id,
        filePath: null
      };

      if (nodeFound.children === null) {
        nodeFound.children = [newObj];
      } else {
        const tempArr = sortByName([...nodeFound.children, newObj]);
        nodeFound.children = tempArr;
      }

      setFolderStructure(clonedFolderStructure);
      hideModalHandler();
    });
  };

  const saveMultipleFiles = async (id) => {
    setUploading(fileList.length);
    setIsLoading(true);
    let index = 0;
    const savedFiles = [];

    for (const x of fileList) {
      await addNewFileFolder({
        parentId: id === 99999 ? null : id,
        name: x.name,
        file: x
      }).then((res) => {
        index += 1;
        setUploaded(index);
        savedFiles.push({
          name: x.name,
          children: null,
          id: res.id,
          filePath: res.filePath
        });
      });
    }

    const clonedFolderStructure = _.cloneDeep(folderStructure);
    const nodeFound = findNodeById([clonedFolderStructure], id);

    if (nodeFound.children === null) {
      nodeFound.children = sortByName([...savedFiles]);
    } else {
      const tempArr = sortByName([...nodeFound.children, ...savedFiles]);
      nodeFound.children = tempArr;
    }
    setFolderStructure(clonedFolderStructure);
    hideModalHandler();
  };

  const addFileHandler = (id, name) => {
    if (name === '' || file.length === 0) {
      return;
    }
    setIsLoading(true);

    addNewFileFolder({
      parentId: id === 99999 ? null : id,
      name,
      file
    }).then(res => {
      const clonedFolderStructure = _.cloneDeep(folderStructure);

      const nodeFound = findNodeById([clonedFolderStructure], id);
      const newObj = {
        name,
        children: null,
        id: res.id,
        filePath: res.filePath
      };

      if (nodeFound.children === null) {
        nodeFound.children = [newObj];
      } else {
        const tempArr = sortByName([...nodeFound.children, newObj]);
        nodeFound.children = tempArr;
      }

      setFolderStructure(clonedFolderStructure);
      hideModalHandler();
    }).catch(() => {

    });
  };

  const sortByName = (arr) => arr.sort((a, b) => {
    a = a.name.toLowerCase();
    b = b.name.toLowerCase();
    if (a === b) return 0;

    return a < b ? -1 : 1;
  });

  const ActionLabelComponent = (propsT) => (
    <CustomBlock className="label-block">
      <CustomBlock
        role="button"
        className="label-container"
        tabIndex={0}
        onClick={() => {
          if (editObj.id !== propsT.id) {
            setShowIndex(propsT.id);
            setEditObj({
              id: -1,
              name: ''
            });
          }
        }}
      >
        <p>{propsT.name}</p>
      </CustomBlock>
      {propsT.id === showIndex && (
        <CustomBlock
          role="button"
          tabIndex={0}
          className="actions-container"
          onClick={(e) => e.preventDefault()}
        >
          {
            propsT.filePath
            && (
              <a
                onClick={
                  () => {
                    const encodedFilePath = encodeURIComponent(propsT.filePath)
                      .replace(/%2F/g, '/');

                    downloadFile(encodedFilePath);
                  }
                }
              >
                <TbCloudDownload size={actionIconSize} />
              </a>
            )
          }
          {
            isActionsVisible
            && (
              actions.filter((x) => propsT.filePath ? x.folderOnly === false : true)
                .map((action, index) => (
                  <a key={index} onClick={() => action.action(propsT)}>
                    {action.icon}
                  </a>
                ))
            )
          }
        </CustomBlock>
      )}
    </CustomBlock>
  );

  const renderTree = nodes => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={(
        <ActionLabelComponent
          filePath={nodes.filePath}
          id={nodes.id}
          name={nodes.name}
        />
      )}
    >
      {Array.isArray(nodes.children) || nodes.filePath === null ? (
        nodes.children ? nodes.children.length > 0 ? (
          nodes.children.map(node => renderTree(node))
        ) : (
          <></>
        ) : <></>
      ) : null}
    </StyledTreeItem>
  );

  const hideModalHandler = () => {
    setEditObj({
      id: -1,
      name: ''
    });
    setIsMulti(false);
    setUploading(0);
    setUploaded(0);
    setFileList([]);
    setFile([]);
    setIsLoading(false);
  };

  return (
    <div>
      <Modal>
        <Modal.Header closeButton>
          <Modal.Title>{editObj.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            editObj.type === 'Add File' && (
              <>
                <FilePond
                  allowMultiple={isMulti}
                  // acceptedFileTypes={['image/*']}
                  labelIdle="Drag & Drop image or <span class='filepond--label-action'>Browse</span>"
                  onupdatefiles={(fileT) => {
                    if (fileT.length > 0) {
                      const selectedFile = fileT[0].file;
                      setEditObj({ ...editObj, name: selectedFile.name });
                      setFile(selectedFile);
                      setFileList(fileT.map(x => x.file));
                    } else {
                      setFile([]);
                      setFileList([]);
                    }
                  }}
                />
                <FormControlLabel
                  control={(
                    <Switch
                      checked={isMulti}
                      onChange={() => {
                        setIsMulti(!isMulti);
                      }}
                      value="checkedB"
                      color="primary"
                    />
                  )}
                  label="Multi File Upload"
                />
              </>
            )

          }
          {
            !isMulti && (
              <InputBlock
                className="form-control"
                value={editObj.name}
                onChange={e => setEditObj({ ...editObj, name: e.target.value })}
              />
            )
          }
        </Modal.Body>
        {
          uploading > 0 && (
            <div className="container">
              <strong>
                {uploaded}
                /
                {uploading}
                uploaded.
              </strong>
            </div>
          )
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModalHandler}>
            Close
          </Button>
          <button
            disabled={isLoading}
            className="btn btn-success mr-2"
            variant="primary"
            onClick={() => {
              if (['Rename File', 'Rename Folder'].includes(editObj.type)) {
                renameRecursively(editObj.id, editObj.name);
              } else if (editObj.type === 'Add Folder') {
                addFolderHandler(editObj.id, editObj.name);
              } else {
                if (isMulti) {
                  saveMultipleFiles(editObj.id);
                  return;
                }
                addFileHandler(editObj.id, editObj.name);
              }
            }}
          >
            {isLoading ? 'Please wait...' : 'Save Changes'}
          </button>
        </Modal.Footer>
      </Modal>

      {/* CREATE SPLIT LOAD MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={editObj.id > 0}
        centered
        size="md"
        contentHeader={editObj.type}
        primaryModalActionText="Save Changes"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={() => {
          if (['Rename File', 'Rename Folder'].includes(editObj.type)) {
            renameRecursively(editObj.id, editObj.name);
          } else if (editObj.type === 'Add Folder') {
            addFolderHandler(editObj.id, editObj.name);
          } else {
            if (isMulti) {
              saveMultipleFiles(editObj.id);
              return;
            }
            addFileHandler(editObj.id, editObj.name);
          }
        }}
        isPrimaryModalActionIsLoading={isLoading}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={hideModalHandler}
      >
        <FormBlock>
          <Section hasNoContainer>
            {
              !isMulti && (
                <ContentBlock>
                  <InputBlock
                    label="Item Name"
                    placeholder="Enter item name"
                    value={editObj.name}
                    onChange={e => setEditObj({ ...editObj, name: e.target.value })}
                  />
                </ContentBlock>
              )
            }

            <ContentBlock>
              {
                editObj.type === 'Add File' && (
                  <>
                    <FileUploadBlock
                      allowMultiple={isMulti}
                      // acceptedFileTypes={['image/*']}
                      onupdatefiles={(fileT) => {
                        if (fileT.length > 0) {
                          const selectedFile = fileT[0].file;
                          setEditObj({ ...editObj, name: selectedFile.name });
                          setFile(selectedFile);
                          setFileList(fileT.map(x => x.file));
                        } else {
                          setFile([]);
                          setFileList([]);
                        }
                      }}
                    />
                    <CheckboxBlock
                      label="Multi File Upload"
                      id="isMulti"
                      isChecked={isMulti}
                      onChange={() => {
                        setIsMulti(!isMulti);
                      }}
                    />
                  </>
                )
              }
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      <TreeView
        className={classes.root}
        defaultExpanded={['1']}
        defaultCollapseIcon={<FcOpenedFolder size={mainIconSize} />}
        defaultExpandIcon={<FcFolder size={mainIconSize} />}
        defaultEndIcon={<FcDocument size={mainIconSize} />}
      >
        {renderTree(folderStructure)}
      </TreeView>
    </div>
  );
};

export default connect(null, confirmModal.actions)(RecursiveDocumentTree);