/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { combineReducers } from 'redux';
import * as auth from './authRedux';
import * as alertToast from './alertToastRedux';
import * as confirmModal from './confirmModalRedux';
import * as projectDetail from './projectDetailRedux';

const appReducer = combineReducers({
  auth: auth.reducer,
  alertToast: alertToast.reducer,
  confirmModal: confirmModal.reducer,
  projectDetail: projectDetail.reducer
});

export const rootReducer = (state, action) => appReducer(state, action);