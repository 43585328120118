import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'NDERequest/';

const getNDERequests = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetNDERequests?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const getNDERequestById = async (ndeRequestId) => axiosInstance.get(`${ENDPOINTORIGIN}GetNDERequestById?ndeRequestId=${ndeRequestId}`, constants.REQUIRE_INTERCEPTORS);

const getNDERequestLineItems = async (projectId, ndeRequestId) => axiosInstance.get(`${ENDPOINTORIGIN}GetNDERequestLineItems?projectId=${projectId}&ndeRequestId=${ndeRequestId}`, constants.REQUIRE_INTERCEPTORS);

const createNDERequest = async (projectId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateNDERequest?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const createNDERequestWithLineItems = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateNDERequestWithLineItems`, model, constants.REQUIRE_INTERCEPTORS);

const createNDERequestLineItem = async (ndeRequestId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateNDERequestLineItem?ndeRequestId=${ndeRequestId}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteNDERequestLineItem = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteNDERequestLineItem?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const deleteNDERequest = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteNDERequest?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const updateNDERequestLineItem = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateNDERequestLineItem`, model, constants.REQUIRE_INTERCEPTORS);

const sendNotificationToNDEContractor = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SendNotificationToNDEContractor`, model, constants.REQUIRE_INTERCEPTORS);

const sendNotificationToAdmins = async (projectId, ndeRequestId) => axiosInstance.get(`${ENDPOINTORIGIN}SendNotificationToAdmins?projectId=${projectId}&ndeRequestId=${ndeRequestId}`, constants.REQUIRE_INTERCEPTORS);

const getNDERequestLog = async (ndeRequestId) => axiosInstance.get(`${ENDPOINTORIGIN}GetNDERequestLog?ndeRequestId=${ndeRequestId}`, constants.REQUIRE_INTERCEPTORS);

const completeNDERequest = async (ndeRequestId) => axiosInstance.put(`${ENDPOINTORIGIN}CompleteNDERequest?ndeRequestId=${ndeRequestId}`, {}, constants.REQUIRE_INTERCEPTORS);

export {
  getNDERequests,
  getNDERequestById,
  getNDERequestLineItems,
  createNDERequest,
  createNDERequestWithLineItems,
  createNDERequestLineItem,
  deleteNDERequestLineItem,
  deleteNDERequest,
  updateNDERequestLineItem,
  sendNotificationToNDEContractor,
  sendNotificationToAdmins,
  getNDERequestLog,
  completeNDERequest
};