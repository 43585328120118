// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// ICONS
import { TbLogout } from 'react-icons/tb';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';
import NavBlock from './NavBlock';

const DrawerNav = (props) => {
  const {
    isOpen,
    backgroundColor,
    navigationItems,
    onClick,
    onClickLogout,
    // closeActionOnClick,
    hasOverlay,
    willCloseOnMaskPress
  } = props;

  return (
    <>
      {
        (isOpen && hasOverlay)
        && (
          <Overlay
            onClick={willCloseOnMaskPress && onClick}
            overlayDarkness="dark"
          />
        )
      }
      <CustomBlock className={`drawer-nav ${backgroundColor} ${isOpen && 'open'}`}>
        <CustomBlock className="actions-block" />

        <CustomBlock className="nav-items">
          {navigationItems}

          <CustomBlock className="logout-element">
            <NavBlock
              title="Log Out"
              onClick={onClickLogout}
              iconLeft={(
                <TbLogout
                  size={30} className="primary--clr"
                  strokeWidth="2"
                />
)}
            />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

DrawerNav.propTypes = {
  // MAIN PROPS
  backgroundColor: PropTypes.string,
  navigationItems: PropTypes.any,

  // FUNCTION PROPS
  onClick: PropTypes.func,
  onClickLogout: PropTypes.func,
  // closeActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  isOpen: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  willCloseOnMaskPress: PropTypes.bool,
};

DrawerNav.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'white--sbg',
  navigationItems: null,

  // FUNCTION PROPS
  onClick: null,
  onClickLogout: null,
  // closeActionOnClick: null,

  // BOOLEAN PROPS
  isOpen: false,
  hasOverlay: false,
  willCloseOnMaskPress: true,
};

export default DrawerNav;