/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
import { TbChecks, TbAlertCircle } from 'react-icons/tb';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
// SERVICES
import * as dailyFormService from '../../../../services/project/dailyFormService';
import * as dailyInspectionVtForm from '../../../../services/project/dailyInspectionVtFormService';
import * as dailyFlangeLogForm from '../../../../services/project/dailyFlangeLogFormService';

const DailyFormsPage = (props) => {
  const {
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    projectDetail,
    setProjectDetails
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [forms, setForms] = useState([]);
  const { projectId } = useParams();

  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    setProjectDetails({ ...projectDetail, projectId });
    getAllForms();
  }, []);

  const getAllForms = () => {
    setIsLoading(true);
    dailyFormService.getDailyForms(projectId).then((res) => {
      setForms(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          const { id, name } = forms[dataIndex];

          return (
            (
              <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
                <Button
                  text="View"
                  className="primary--bg ml-0"
                  size="xxs"
                  to={name === 'Daily Flange Inspection Report' ? `/project/daily-flange-form/${projectId}/${id}` : `/project/daily-inspection-vt-form/${projectId}/${id}`}
                />

                {
                  !projectDetail.isCompleted && (
                    <Button
                      text="Delete"
                      className="danger--bg"
                      size="xxs"
                      onClick={() => {
                        showConfirmModal({
                          title: 'Delete Form',
                          text: 'Are you sure you want to delete this form?',
                          rightBtnText: 'Confirm',
                          btnAction: () => {
                            dailyFormService.deleteDailyForm(id, name === 'Daily Flange Inspection Report' ? 'Flange' : 'VT').then((res) => {
                              showAlert({ text: res.message, state: 'success' });
                              getAllForms();
                            }).catch((ex) => {
                              showAlert({ text: ex.message, state: 'error' });
                            }).finally(() => {
                              hideConfirmModal();
                            });
                          }
                        });
                      }}
                    />
                  )
                }

              </CustomBlock>
            )
          );
        }
      }
    },
    {
      name: 'name',
      label: 'Form',
      options: columnOptions,
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: columnOptions,
    },
    {
      name: 'createdOn',
      label: 'Created On',
      options: columnOptions,
    },
    {
      name: 'submittedBy',
      label: 'Submitted By',
      options: columnOptions,
    },
    {
      name: 'submittedOn',
      label: 'Submitted On',
      options: columnOptions,
    },
    {
      name: 'isSubmitted',
      label: 'Submitted?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { isSubmitted } = forms[dataIndex];

          return (
            <p>{isSubmitted ? <TbChecks className="success--clr" size={30} /> : <TbAlertCircle className="orange--clr" size={30} />}</p>
          );
        }
      },
    }
  ];

  const createDailyInspectionVtForm = () => {
    setIsLoading(true);
    hideConfirmModal();
    dailyInspectionVtForm.createDailyInspectionVtForm(projectId).then(() => {
      getAllForms();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createDailyFLangeLogForm = () => {
    setIsLoading(true);
    hideConfirmModal();
    dailyFlangeLogForm.createDailyFlangeLogForm(projectId).then(() => {
      getAllForms();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Daily Forms"
                headerSize="lg"
                primaryButtonText={!projectDetail.isCompleted ? 'New Daily Inspection VT Form' : ''}
                primaryButtonIconLeft={!projectDetail.isCompleted && <IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => showConfirmModal({
                  title: 'Create Daily Inspection VT Form?',
                  text: 'Are you sure you want to create a new Daily Inspection VT Form?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    createDailyInspectionVtForm();
                  }
                })}
                secondaryButtonText={!projectDetail.isCompleted ? 'New Flange Inspection Report' : ''}
                secondaryButtonIconLeft={!projectDetail.isCompleted && <IoMdAddCircle className={iconColor} size={iconSize} />}
                secondaryButtonOnClick={() => showConfirmModal({
                  title: 'Create Flange Inspection Report?',
                  text: 'Are you sure you want to create a new Flange Inspection Report?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    createDailyFLangeLogForm();
                  }
                })}
              />

              <CustomBlock>
                <MUIDataTable
                  data={forms}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* CONFIRM DELETE MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isConfirmDeleteModalVisible}
        size="md"
        contentHeader="Delete Client"
        contentDescription="Are you sure you want to delete this client?"
        primaryModalActionText="Delete"
        primaryModalActionColor="danger--bg"
        primaryModalActionOnClick={() => { }}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="grey--bg"
        onHide={() => {
          setIsConfirmDeleteModalVisible(false);
        }}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(DailyFormsPage);