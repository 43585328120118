import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'DailyInspectionVtForm/';

const getDailyInspectionVtFormById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetDailyInspectionVtFormById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const createDailyInspectionVtForm = async (projectId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateDailyInspectionVtForm?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const submitDailyInspectionVtForm = async (id) => axiosInstance.post(`${ENDPOINTORIGIN}SubmitDailyInspectionVtForm?dailyInspectionVtFormId=${id}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteDailyInspectionVtForm = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteDailyInspectionVtForm?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getDailyInspectionVtFormLineItems = async (projectId, dailyInspectionVtFormId) => axiosInstance.get(`${ENDPOINTORIGIN}GetDailyInspectionVtFormLineItems?projectId=${projectId}&dailyInspectionVtFormId=${dailyInspectionVtFormId}`, constants.REQUIRE_INTERCEPTORS);

const createDailyInspectionVtFormLineItem = async (dailyInspectionVtFormId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateDailyInspectionVtFormLineItem?dailyInspectionVtFormId=${dailyInspectionVtFormId}`, {}, constants.REQUIRE_INTERCEPTORS);

const updateDailyInspectionVtFormLineItem = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateDailyInspectionVtFormLineItem`, model, constants.REQUIRE_INTERCEPTORS);

const deleteDailyInspectionVtFormLineItem = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteDailyInspectionVtFormLineItem?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getDailyInspectionVtFormById,
  createDailyInspectionVtForm,
  submitDailyInspectionVtForm,
  deleteDailyInspectionVtForm,
  getDailyInspectionVtFormLineItems,
  createDailyInspectionVtFormLineItem,
  updateDailyInspectionVtFormLineItem,
  deleteDailyInspectionVtFormLineItem
};