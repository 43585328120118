/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// import moment from 'moment';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid-pro';
// COMPONENTS
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
import {
  HiOutlineTrash,
  HiDocumentReport,
  HiOutlineRefresh
} from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import DataGridFilterModule from '../../../../components/DataGridFilterModule';
// SERVICES
import * as weldLogService from '../../../../services/project/weldLogService';
import * as testPackageLogService from '../../../../services/project/testPackageLogService';
import * as ndeRequestService from '../../../../services/project/ndeRequestService';
// REDUX
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import ExcelImportExportComponent from '../../../../components/ExcelImportExportComponent';

const WeldLogPage = (props) => {
  const {
    auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    history,
    projectDetail,
    setProjectDetails
  } = props;
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const [originalSource, setOriginalSource] = useState([]);
  const [testPackageLogs, setTestPackageLogs] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const apiRef = useGridApiRef();

  useEffect(() => {
    setIsLoading(false);
    setProjectDetails({ ...projectDetail, projectId });
    getWeldLogs();
    getContractors();
  }, []);

  const getWeldLogs = () => {
    setIsLoading(true);
    weldLogService.getWeldLogs(projectId).then((res) => {
      setSource(res.weldLogs);
      setOriginalSource(res.weldLogs);
      setTestPackageLogs(['', ...res.testPackageLogs]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createNDERequestForm = () => {
    const weldsToPost = source.filter((x) => rowSelectionModel.includes(x.id));
    if (weldsToPost.length > 0) {
      setIsLoading(true);
      hideConfirmModal();
      ndeRequestService.createNDERequestWithLineItems({ projectId, welds: weldsToPost }).then((res) => {
        history.push(`/project/nde-request-form/${projectId}/${res.id}`);
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const getContractors = () => {
    setIsLoading(true);

    testPackageLogService.getContractors(projectId).then((res) => {
      setContractors(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const addLog = () => {
    setIsLoading(true);
    weldLogService.createWeldLog(projectId).then((res) => {
      const newLineItem = {
        id: res.id,
        projectId,
        hcw: null,
        weldNo: res.weldNo,
        testPackageNumber: null,
        lineNumber: null,
        contractor: null,
        workListNumber: null,
        unit: null,
        scopeRevReWork: null,
        dwgNo: null,
        moc: null,
        baseMaterial: null,
        pipeSize: null,
        pipeSchedule: null,
        stencil1: null,
        stencil2: null,
        stencil3: null,
        stencil4: null,
        weldType: null,
        weldLocation: null,
        process: null,
        spec: null,
        vtDate: null,
        vtBy: null,
        pmiPercentage: null,
        pmiReportNumber: null,
        pmiDate: null,
        ptMtPercentage: null,
        ptMtReportNumber: null,
        ptMtDate: null,
        ptMtBy: null,
        accRej: null,
        xrayUtPercentage: null,
        xrayUtReportNumber: null,
        rtUtDate: null,
        weldAcceptedRejected: null,
        r1AcceptedRejected: null,
        r2AcceptedRejected: null,
        afterStressPercentage: null,
        afterStressReportNumber: null,
        afterStressDate: null,
        rtUtAsPercentage: null,
        rtUtAsReportNumber: null,
        rtUtAsDate: null,
        pwhtPercentage: null,
        pwhtReportNumber: null,
        pwhtDate: null,
        bhnPercentage: null,
        bhnReportNumber: null,
        bhnDate: null,
        wpsPqr: null,
        systems: null,
        comments: null,
        bakeOut: null,
        preheat: null,
      };
      setSource([...source, newLineItem]);
      setOriginalSource([...source, newLineItem]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAfterEdit = (model) => {
    weldLogService.updateWeldLog(model).then(() => {
      const temp = [...source];
      const indexToReplace = temp.map((a) => a.id).indexOf(model.id);
      // model.mocNumber = res.moc;
      temp[indexToReplace] = model;
      setSource(temp);

      // original
      const originalTemp = [...originalSource];
      const originalIndexToReplace = originalTemp.map((a) => a.id).indexOf(model.id);
      originalTemp[originalIndexToReplace] = model;

      setOriginalSource(originalTemp);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeCell = (id) => {
    hideConfirmModal();
    weldLogService.deleteWeldLog(id).then((res) => {
      getWeldLogs();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
          {
            (auth.accessLevel >= 50 && !projectDetail.isCompleted) && (
              <Button
                size="icon"
                className="danger--bg ml-0"
                iconLeft={<HiOutlineTrash className={iconColor} size={20} />}
                onClick={() => {
                  showConfirmModal({
                    title: 'Delete Row',
                    text: 'Are you sure you want to delete this row?',
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      removeCell(params.id);
                    }
                  });
                }}
              />
            )
          }

          <Button
            size="icon"
            className={params.row.testPackageNumber ? 'info--bg' : 'grey--bg'}
            iconLeft={<HiDocumentReport className={iconColor} size={20} />}
            onClick={() => {
              if (params.row.testPackageNumber) {
                history.push(`/project/weld-log-report/${projectId}/${params.row.testPackageNumber}${params.row.contractor ? `/${encodeURIComponent(params.row.contractor)}` : ''}`);
              } else {
                showAlert({ text: 'Select Test Package to view report', state: 'warning' });
              }
            }}
          />
        </CustomBlock>
      )
    },
    {
      field: 'hcw',
      headerName: 'HCW',
      width: 125,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'weldNo',
      headerName: 'Weld No',
      width: 75,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      renderCell: (params) => (
        <Link to={`/project/log-document/Weld Log/${projectId}/${params.value}`}>
          {params.value}
        </Link>
      )
    },
    {
      field: 'testPackageNumber',
      headerName: 'Test Package #',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(testPackageLogs.filter((x) => x)))
    },
    {
      field: 'contractor',
      headerName: 'Contractor',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: contractors
    },
    {
      field: 'lineNumber',
      headerName: 'Line #',
      width: 160,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'workListNumber',
      headerName: 'Work List Number',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'moc',
      headerName: 'MOC #',
      width: 140,
      valueFormatter: ({ value }) => value,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'systems',
      headerName: 'Systems',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'scopeRevReWork',
      headerName: 'Scope / Rev / ReWork',
      width: 200,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Scope', 'Rev', 'Rework']
    },
    {
      field: 'dwgNo',
      headerName: 'DWG. No.',
      width: 120,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'spec',
      headerName: 'Pipe Spec',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'baseMaterial',
      headerName: 'Base Material',
      width: 120,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'pipeSize',
      headerName: 'Pipe Size',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'pipeSchedule',
      headerName: 'Pipe Schedule',
      width: 150,
      type: 'singleSelect',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      valueOptions: ['', '5', '10', '20', '30', 'STD', 'SCH40', '60', 'SCH80', 'XH', '100', '120', '140', '160', 'XXH']
    },
    {
      field: 'stencil1',
      headerName: 'Stencil1',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'stencil2',
      headerName: 'Stencil2',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'stencil3',
      headerName: 'Stencil3',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'stencil4',
      headerName: 'Stencil4',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'weldType',
      headerName: 'Weld Type',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'BW', 'SW', 'OLET', 'FILLET', 'ATTACHMENT']
    },
    {
      field: 'weldLocation',
      headerName: 'Weld Location',
      width: 125,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Shop Weld', 'Field Weld', 'Tie-in Weld']
    },
    {
      field: 'process',
      headerName: 'Process',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 100,
      // type: 'singleSelect',
      // valueOptions: ['', 'GTAW', 'SMAW', 'GT/SM']
    },
    {
      field: 'vtDate',
      headerName: 'VT/QC Date',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'vtBy',
      headerName: 'VT/QC By',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'pmiPercentage',
      headerName: 'PMI %',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A'] // C
    },
    {
      field: 'pmiReportNumber',
      headerName: 'PMI Report #',
      width: 125,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'pmiDate',
      headerName: 'PMI Date',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'ptMtPercentage', // N
      headerName: 'PT/MT %',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A']
    },
    {
      field: 'ptMtReportNumber',
      headerName: 'PT/MT Report #',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'ptMtDate',
      headerName: 'PT/MT Date',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'ptMtBy',
      headerName: 'PT/MT By',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'accRej',
      headerName: 'ACC ./ REJ.',
      width: 130,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'ACC.', 'REJ.', 'Qualifier ACC.', 'Qualifier REJ.']
    },
    {
      field: 'xrayUtPercentage',
      headerName: 'XRAY/UT %', // C
      width: 125,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A']
    },
    {
      field: 'xrayUtReportNumber',
      headerName: 'XRAY/UT Report #',
      width: 135,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'rtUtDate',
      headerName: 'RT/UT Date',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'weldAcceptedRejected',
      headerName: 'Weld Acc/Rej',
      width: 125,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'ACC.', 'REJ.', 'Qualifier ACC.', 'Qualifier REJ.', 'TRACER ACC.', 'TRACER REJ.']
    },
    {
      field: 'r1AcceptedRejected',
      headerName: 'R1 Acc/Rej',
      width: 130,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'ACC.', 'REJ.', 'Qualifier ACC.', 'Qualifier REJ.', 'TRACER ACC.', 'TRACER REJ.']
    },
    {
      field: 'r2AcceptedRejected',
      headerName: 'R2 Acc/Rej',
      width: 130,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'ACC.', 'REJ.', 'Qualifier ACC.', 'Qualifier REJ.', 'TRACER ACC.', 'TRACER REJ.']
    },
    {
      field: 'afterStressPercentage',
      headerName: 'RT/UT BS %',
      width: 130,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A']
    },
    {
      field: 'afterStressReportNumber',
      headerName: 'RT/UT BS Report #',
      width: 160,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'afterStressDate',
      headerName: 'RT/UT BS Date',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'rtUtAsPercentage',
      headerName: 'RT/UT AS %',
      width: 135,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A']
    },
    {
      field: 'rtUtAsReportNumber',
      headerName: 'RT/UT AS Report #',
      width: 135,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'rtUtAsDate',
      headerName: 'RT/UT AS Date',
      width: 135,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date',
    },
    {
      field: 'bakeOut',
      headerName: 'Bakeout',
      width: 135,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'preheat',
      headerName: 'Pre Heat',
      width: 135,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'pwhtPercentage',
      headerName: 'PWHT %', // A
      width: 135,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A']
    },
    {
      field: 'pwhtReportNumber',
      headerName: 'PWHT Report #',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'pwhtDate',
      headerName: 'PWHT Date',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'bhnPercentage',
      headerName: 'BHN %', // C
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A']
    },
    {
      field: 'bhnReportNumber',
      headerName: 'BHN Report #', // C
      width: 130,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'bhnDate',
      headerName: 'BHN Date', // C
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'wpsPqr',
      headerName: 'WPS/PQR',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 300,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Weld Log"
                headerSize="lg"
                primaryButtonText={auth.accessLevel >= 40 && !projectDetail.isCompleted ? 'Add New Row' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={addLog}
                secondaryButtonText="Refresh Table"
                secondaryButtonIconLeft={<HiOutlineRefresh className={iconColor} size={iconSize} />}
                secondaryButtonOnClick={getWeldLogs}
              />
              {
                (!projectDetail.isCompleted && auth.accessLevel >= 40) && (
                  <CustomBlock className="flex flex-row-reverse mb-10" style={{ marginTop: '-30px' }}>
                    <Button
                      text="Create NDE Request"
                      isDisabled={rowSelectionModel.length === 0}
                      className={rowSelectionModel.length > 0 ? 'primary--bg btn-sm' : 'grey--bg btn-sm'}
                      onClick={() => {
                        showConfirmModal({
                          title: 'Create NDE Request Form',
                          text: 'Are you sure you want to create a new NDE Request Form?',
                          rightBtnText: 'Confirm',
                          btnAction: () => {
                            createNDERequestForm();
                          }
                        });
                      }}
                    />
                  </CustomBlock>
                )
              }
              <DataGridFilterModule
                columns={columns}
                rows={source}
                originalRows={originalSource}
                updateRowFn={setSource}
              />

              <ExcelImportExportComponent
                columns={columns}
                data={source}
                excelSheetName={`Weld Log: ${projectDetail.projectName}`}
                type="WELDLOG"
                fetchLogs={getWeldLogs}
                projectId={projectId}
              />

              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGridPro
                  checkboxSelection={!projectDetail.isCompleted && auth.accessLevel >= 40}
                  rows={source}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  initialState={{ pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'action', 'hcw', 'weldNo', 'testPackageNumber', 'contractor'] } }}
                  pageSize={100}
                  ref={apiRef}
                  keepNonExistentRowsSelected
                  onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                      </GridToolbarContainer>
                    ),
                  }}
                  onCellEditCommit={({ id, field, value }) => {
                    const originalModel = source.find((x) => x.id === id);
                    if (originalModel) {
                      originalModel[field] = value;
                      handleAfterEdit(originalModel);
                    }
                  }}
                />
              </Box>
            </CustomBlock>

          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(WeldLogPage);