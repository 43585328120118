/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
// COMPONENTS
import Table from 'react-bootstrap/Table';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
import {
  HiOutlineTrash,
  // HiDocumentReport,
  HiOutlineRefresh
} from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import DataGridFilterModule from '../../../../components/DataGridFilterModule';
// SERVICES
import * as psvLogService from '../../../../services/project/psvLogService';
import * as testPackageLogService from '../../../../services/project/testPackageLogService';
// REDUX
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import ExcelImportExportComponent from '../../../../components/ExcelImportExportComponent';

const PsvLogPage = (props) => {
  const {
    auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    history,
    projectDetail,
    setProjectDetails
  } = props;
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const [originalSource, setOriginalSource] = useState([]);
  const [testPackageLogs, setTestPackageLogs] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [psvLogHistory, setPsvLogHistory] = useState([]);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const apiRef = useGridApiRef();

  useEffect(() => {
    setIsLoading(false);
    setProjectDetails({ ...projectDetail, projectId });
    getPsvLogs();
    getContractors();
  }, []);

  const getPsvLogs = () => {
    setIsLoading(true);
    psvLogService.getPsvLogs(projectId).then((res) => {
      setSource(res.psvLogs);
      setOriginalSource(res.psvLogs);
      setTestPackageLogs(['', ...res.testPackageLogs]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getContractors = () => {
    setIsLoading(true);

    testPackageLogService.getContractors(projectId).then((res) => {
      setContractors(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const addLog = () => {
    setIsLoading(true);
    psvLogService.createPsvLog(projectId).then((res) => {
      const newLineItem = {
        id: res.id,
        projectId,
        reliefValveNumber: null,
        reliefValveSerialNumber: null,
        testPackageNumber: null,
        lineNumber: null,
        contractor: null,
        psvSpecSheet: null,
        requiredSetPressure: null,
        reliefValveMakeModel: null,
        unit: null,
        systemNumber: null,
        pandID: null,
        pipeSpec: null,
        systemId: null,
        psvDescription: null,
        moc: null,
        inletFlangeSize: null,
        inletFlangeRating: null,
        outletFlangeSize: null,
        outletFlangeRating: null,
        inletGasketMaterial: null,
        inletBoltMaterial: null,
        outletGasketMaterial: null,
        outletBoltMaterial: null,
        flangeFaceAccRej: null,
        reportedBoltupDate: null,
        threadsFullyEngaged: null,
        hammerTestAccRej: null,
        hammerTestBy: null,
        torqueRequired: null,
        torqueCompletedBy: null,
        setPressureVerified: null,
        dateTorqued: null,
        qCCompleteBy: null,
        qCCompletedDate: null,
        qACompleteBy: null,
        qACompletedDate: null,
        popTestDate: null,
        popTestReportNumber: null,
        finalReviewBy: null,
        apiCompleteBy: null,
        finalReportDate: null,
        comments: null,
        apiReportDate: null
      };
      setSource([...source, newLineItem]);
      setOriginalSource([...source, newLineItem]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAfterEdit = (model) => {
    psvLogService.updatePsvLog(model).then((res) => {
      // const temp = [...source];
      // const indexToReplace = temp.map((a) => a.id).indexOf(model.id);
      // model.mocNumber = res.moc;
      // temp[indexToReplace] = model;
      // setSource(temp);
      console.log(res);

      // original
      const originalTemp = [...originalSource];
      const originalIndexToReplace = originalTemp.map((a) => a.id).indexOf(model.id);
      originalTemp[originalIndexToReplace] = model;

      setOriginalSource(originalTemp);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeCell = (id) => {
    hideConfirmModal();
    setIsLoading(true);
    psvLogService.deletePsvLog(id).then((res) => {
      getPsvLogs();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchPsvLogHistory = () => {
    setIsLoading(true);
    psvLogService.getPsvLogHistory(projectId).then((res) => {
      setIsLogModalOpen(true);
      setPsvLogHistory(res);
    }).catch((ex) => {
      showAlert({ state: 'error', text: ex.message });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 75,
      renderCell: (params) => (
        <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
          {
            (auth.accessLevel >= 50 && !projectDetail.isCompleted) && (
              <Button
                size="icon"
                className="danger--bg ml-0"
                iconLeft={<HiOutlineTrash className={iconColor} size={20} />}
                onClick={() => {
                  showConfirmModal({
                    title: 'Delete Row',
                    text: 'Are you sure you want to delete this row?',
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      removeCell(params.id);
                    }
                  });
                }}
              />
            )
          }
        </CustomBlock>
      )
    },
    {
      field: 'reliefValveNumber',
      headerName: 'Relief Valve #',
      width: 125,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'reliefValveSerialNumber',
      headerName: 'Relief Valve Serial #',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'testPackageNumber',
      headerName: 'Test Package #',
      width: 130,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(['', ...testPackageLogs.filter((x) => x)]))
    },
    {
      field: 'contractor',
      headerName: 'Contractor',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', ...contractors]
    },
    {
      field: 'lineNumber',
      headerName: 'Line #',
      width: 160,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'psvSpecSheet',
      headerName: 'PSV Spec Sheet',
      width: 175,
      editable: false,
      renderCell: (params) => (
        params.row.reliefValveNumber && (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              size="icon"
              className="primary--bg"
              text="View"
              onClick={() => {
                history.push(`/project/log-document/${params.row.reliefValveNumber}/${projectId}/PSV Spec Sheet`);
              }}
            />
          </CustomBlock>
        )
      )
    },
    {
      field: 'requiredSetPressure',
      headerName: 'Required Set Pressure',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'reliefValveMakeModel',
      headerName: 'Relief Valve Make/Model',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'unit',
      headerName: 'Unit/Unit #',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'systemNumber',
      headerName: 'System #',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'pandID',
      headerName: 'P&ID#',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'pipeSpec',
      headerName: 'Pipe Spec',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'systemId',
      headerName: 'Work Order #',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'moc',
      headerName: 'MOC #',
      width: 140,
      valueFormatter: ({ value }) => value,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'psvDescription',
      headerName: 'PSV Description',
      width: 225,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'inletFlangeSize',
      headerName: 'Inlet Flange Size',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'inletFlangeRating',
      headerName: 'Inlet Flange Rating',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'outletFlangeSize',
      headerName: 'Outlet Flange Size',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'outletFlangeRating',
      headerName: 'Outlet Flange Rating',
      width: 155,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'inletGasketMaterial',
      headerName: 'Inlet Gasket Material',
      width: 160,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'outletGasketMaterial',
      headerName: 'Outlet Gasket Material',
      width: 160,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'outletBoltMaterial',
      headerName: 'Outlet Bolt Material',
      width: 155,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'inletBoltMaterial',
      headerName: 'Inlet Bolt Material',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'flangeFaceAccRej',
      headerName: 'Flange Face ACC/REJ?',
      width: 160,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'ACC.', 'REJ.', 'Qualifier ACC.', 'Qualifier REJ.']
    },
    {
      field: 'reportedBoltupDate',
      headerName: 'Reported Bolt-up Date',
      width: 160,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'threadsFullyEngaged',
      headerName: 'Threads Fully Engaged Y/N?',
      width: 195,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'hammerTestAccRej',
      headerName: 'Hammer Test ACC/REJ?',
      width: 180,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'ACC.', 'REJ.', 'Qualifier ACC.', 'Qualifier REJ.']
    },
    {
      field: 'hammerTestBy',
      headerName: 'Hammer Test By',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'torqueRequired',
      headerName: 'Torque Required Y/N?',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'torqueCompletedBy',
      headerName: 'Torque Completed By',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'setPressureVerified',
      headerName: 'Set Pressure Verified',
      width: 165,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'dateTorqued',
      headerName: 'Date Torqued',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'qcCompleteBy',
      headerName: 'QC Complete By',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'qcCompletedDate',
      headerName: 'QC Complete Date',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'qaCompleteBy',
      headerName: 'QA Complete By',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'qaCompletedDate',
      headerName: 'QA Complete Date',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'popTestReportNumber',
      headerName: 'Pop Test Report #',
      width: 145,
      editable: false,
      renderCell: (params) => (
        params.row.reliefValveNumber && (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              size="icon"
              className="primary--bg"
              text="View"
              onClick={() => {
                history.push(`/project/log-document/${params.row.reliefValveNumber}/${projectId}/Pop Test Report`);
              }}
            />
          </CustomBlock>
        )
      )
    },
    {
      field: 'popTestDate',
      headerName: 'Pop Test Date',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'apiReport',
      headerName: 'API Report #',
      width: 145,
      editable: false,
      renderCell: (params) => (
        params.row.reliefValveNumber && (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              size="icon"
              className="primary--bg"
              text="View"
              onClick={() => {
                history.push(`/project/log-document/${params.row.reliefValveNumber}/${projectId}/API Report`);
              }}
            />
          </CustomBlock>
        )
      )
    },
    {
      field: 'apiCompleteBy',
      headerName: 'API Complete By',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'apiReportDate',
      headerName: 'API Report Date',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'finalReviewBy',
      headerName: 'Final Review By',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'finalReviewDate',
      headerName: 'Final Review Date',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 300,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="PSV Log"
                headerSize="lg"
                primaryButtonText={auth.accessLevel >= 40 && !projectDetail.isCompleted ? 'Add New Row' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={addLog}
                secondaryButtonText="Refresh Table"
                secondaryButtonIconLeft={<HiOutlineRefresh className={iconColor} size={iconSize} />}
                secondaryButtonOnClick={getPsvLogs}
              />

              <Section hasNoContainer>
                <ContentBlock className="md-3 mb-10">
                  <CustomBlock className="content-container--actions flex-start pl-0 mt-0">

                    <Button
                      size="xs"
                      className="info--bg ml-5"
                      text="View Log"
                      onClick={() => {
                        fetchPsvLogHistory();
                      }}
                    />
                  </CustomBlock>
                </ContentBlock>

              </Section>

              <DataGridFilterModule
                columns={columns}
                rows={source}
                originalRows={originalSource}
                updateRowFn={setSource}
              />

              <ExcelImportExportComponent
                columns={columns}
                data={source}
                excelSheetName={`PSV Log: ${projectDetail.projectName}`}
                type="PSVLOG"
                fetchLogs={getPsvLogs}
                projectId={projectId}
              />

              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGridPro
                  rows={source}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  initialState={{ pinnedColumns: { left: ['action', 'reliefValveNumber', 'reliefValveSerialNumber', 'testPackageNumber', 'contractor'] } }}
                  pageSize={100}
                  ref={apiRef}
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                      </GridToolbarContainer>
                    ),
                  }}
                  onCellEditCommit={({ id, field, value }) => {
                    const originalModel = source.find((x) => x.id === id);
                    if (originalModel) {
                      originalModel[field] = value;
                      handleAfterEdit(originalModel);
                    }
                  }}
                />
              </Box>
            </CustomBlock>

          </ContentBlock>
        </Section>
      </CustomBlock>

      <ModalBlock
        hasCloseAction
        isVisible={isLogModalOpen}
        centered
        size="xl"
        contentHeader="PSV Log History"
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => setIsLogModalOpen(false)}
      >
        <Section hasNoContainer>
          <Table striped bordered>
            <thead>
              <tr>
                <th>From</th>
                <th>Log</th>
                <th>Date Time</th>
              </tr>
            </thead>
            <tbody>
              {
                psvLogHistory.map((log) => (
                  <tr>
                    <td>{log.createdBy}</td>
                    <td>{log.log}</td>
                    <td>{log.dateCreated}</td>
                  </tr>
                ))
              }

            </tbody>
          </Table>
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(PsvLogPage);