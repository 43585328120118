import * as Yup from 'yup';

export default Yup.object().shape({
  password: Yup.string()
    .required('New password is required')
    .min(5, 'Minimum length of Password is 5')
    .max(100),
  confirmPassword: Yup.string()
    .required('Re-enter your new password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});