// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const Image = (props) => {
  const {
    to,
    onClick,
    containerClassName,
    className,
    hasRoundCorners,
    source,
    alt,
  } = props;

  const history = useHistory();

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <>
      {
        (to !== '' || null || onClick !== null)
          ? (
            <button
              onClick={to ? () => navigateToPath(to) : onClick}
              className={containerClassName}
              type="button"
            >
              <img
                className={`image${hasRoundCorners ? '--rounded' : ''} ${className}`}
                src={source}
                alt={alt}
              />
            </button>
          ) : (
            <img
              className={`image${hasRoundCorners ? '--rounded' : ''} ${className}`}
              src={source}
              alt={alt}
            />
          )
      }
    </>
  );
};

Image.propTypes = {
  // MAIN PROPS
  to: PropTypes.string,
  onClick: PropTypes.func,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  hasRoundCorners: PropTypes.bool,
  source: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

Image.defaultProps = {
  // MAIN PROPS
  to: '',
  alt: 'Image',
  onClick: null,
  containerClassName: '',
  className: '',
  hasRoundCorners: false,
};

export default Image;