/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */

// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
// COMPONENTS
import Table from 'react-bootstrap/Table';
import CreatableSelect from 'react-select/creatable';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
import { HiOutlineTrash, HiOutlineRefresh } from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
import InputBlock from '../../../../components/InputBlock';
import FormBlock from '../../../../components/FormBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import DataGridFilterModule from '../../../../components/DataGridFilterModule';
// SERVICES
import * as ndeRequestService from '../../../../services/project/ndeRequestService';
import * as projectService from '../../../../services/project/projectService';
import * as weldLogService from '../../../../services/project/weldLogService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
import * as helper from '../../../../helpers/helper';

const NDRequestFormPage = (props) => {
  const {
    auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    projectDetail,
    setProjectDetails,
    history
  } = props;
  const { projectId, ndeRequestId } = useParams();

  const initialEmailModel = {
    projectId,
    ndeRequestId,
    subject: 'NDE Request Form',
    message: `NDE Request Form has been sent to review. Please check the link below. \n ${window.location.href}`,
    emails: []
  };
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const [ndeRequest, setNDERequest] = useState({
    projectId: 0,
    name: '',
    isCompleted: false,
    completedByUserId: 0,
    completedDateTime: null
  });
  const [originalSource, setOriginalSource] = useState([]);
  const [weldLogs, setWeldLogs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const [emailOptions, setEmailOptions] = useState(
    [
      { label: 'Work Station 1', value: 'Precise.t1w1@pndtllc.com' },
      { label: 'Work Station 2', value: 'Precise.t1w2@pndtllc.com' },
      { label: 'Work Station 3', value: 'Precise.t1w3@pndtllc.com' },
      { label: 'Work Station 4', value: 'Precise.t1w4@pndtllc.com' },
      { label: 'Durabook Tab 1', value: 'Precise.t1tab1@pndtllc.com' },
      { label: 'Durabook Tab 2', value: 'Precise.t1tab2@pndtllc.com' },
      { label: 'Durabook Tab 3', value: 'Precise.t1tab3@pndtllc.com' },
      { label: 'Durabook Tab 4', value: 'Precise.t1tab4@pndtllc.com' },
      { label: 'Durabook Tab 5', value: 'Precise.t1tab5@pndtllc.com' },
      { label: 'Durabook Tab 6', value: 'Precise.t1tab6@pndtllc.com' },
    ]
  );
  const [model, setModel] = useState(initialEmailModel);
  const [selectedEmails, setSelectedEmails] = useState(null);
  const [ndeRequestLog, setNDERequestLog] = useState([]);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [contractors, setContractors] = useState([]);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const apiRef = useGridApiRef();

  useEffect(() => {
    setIsLoading(false);
    setProjectDetails({ ...projectDetail, projectId });
    getNDERequests();
    getContractorUsers();
    getNDERequest();
    getContractors();
    fetchDrawings();
  }, []);

  const getNDERequest = () => {
    setIsLoading(true);
    ndeRequestService.getNDERequestById(ndeRequestId).then((res) => {
      setNDERequest(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getContractors = () => {
    setIsLoading(true);

    weldLogService.getContractors(projectId).then((res) => {
      setContractors(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchDrawings = () => {
    setIsLoading(true);

    weldLogService.getDrawingNos(projectId).then((res) => {
      setDrawings(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getNDERequests = () => {
    setIsLoading(true);
    ndeRequestService.getNDERequestLineItems(projectId, ndeRequestId).then((res) => {
      setSource(res.ndeRequests);
      setOriginalSource(res.ndeRequests);
      setWeldLogs(res.weldLogs);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getContractorUsers = () => {
    projectService.getContractorUsersForProject(projectId).then((res) => {
      setEmailOptions([...emailOptions, ...res]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const addLog = () => {
    setIsLoading(true);
    ndeRequestService.createNDERequestLineItem(ndeRequestId).then((res) => {
      const newLineItem = {
        id: res.id,
        projectId,
        ndeRequestId,
        weldNo: null,
        testPackageNumber: null,
        contractor: null,
        workListNumber: null,
        lineNumber: null,
        unit: null,
        scopeRevReWork: null,
        dwgNo: null,
        baseMaterial: null,
        pipeSize: null,
        pipeSchedule: null,
        stencil1: null,
        stencil2: null,
        stencil3: null,
        stencil4: null,
        weldType: null,
        weldLocation: null,
        process: null,
        ndePercentage: null,
        prePWHT: null,
        postPWHT: null,
        paut: null,
        rt: null,
        pt: null,
        mt: null,
        ht: null,
        pmi: null,
        fn: null,
        bhn: null,
        dateSentOut: null,
        dateCompleted: null,
        dateReviewedBy: null,
        dateDataEntered: null,
        requestBy: null,
        comments: null,
      };
      setSource([...source, newLineItem]);
      setOriginalSource([...source, newLineItem]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAfterEdit = (mdel) => {
    ndeRequestService.updateNDERequestLineItem(mdel).then((res) => {
      const temp = [...source];
      const indexToReplace = temp.map((a) => a.id).indexOf(mdel.id);
      temp[indexToReplace] = res.ndeRequest;
      setSource(temp);

      // original
      const originalTemp = [...originalSource];
      const originalIndexToReplace = originalTemp.map((a) => a.id).indexOf(mdel.id);
      originalTemp[originalIndexToReplace] = res.ndeRequest;

      setOriginalSource(originalTemp);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeCell = (id) => {
    hideConfirmModal();
    setIsLoading(true);
    ndeRequestService.deleteNDERequestLineItem(id).then((res) => {
      getNDERequests();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      hide: projectDetail.isCompleted || ndeRequest.isCompleted,
      width: 75,
      renderCell: (params) => (
        <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
          {
            auth.accessLevel >= 50 && (
              <Button
                size="icon"
                className="danger--bg ml-0"
                iconLeft={<HiOutlineTrash className={iconColor} size={20} />}
                onClick={() => {
                  showConfirmModal({
                    title: 'Delete Row',
                    text: 'Are you sure you want to delete this row?',
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      removeCell(params.id);
                    }
                  });
                }}
              />
            )
          }
        </CustomBlock>
      )
    },
    {
      field: 'dwgNo',
      headerName: 'DWG. No.',
      width: 120,
      // editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      type: 'singleSelect',
      valueOptions: Array.from(new Set(drawings.filter((x) => x)))
    },
    {
      field: 'weldNo',
      headerName: 'Weld No',
      width: 75,
      // editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      type: 'singleSelect',
      valueOptions: Array.from(new Set(weldLogs.filter((x) => x)))
    },
    {
      field: 'contractor',
      headerName: 'Contractor',
      width: 175,
      // editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      type: 'singleSelect',
      valueOptions: contractors
    },
    {
      field: 'testPackageNumber',
      headerName: 'Test Package Number',
      width: 175,
    },
    {
      field: 'lineNumber',
      headerName: 'Line Number',
      width: 175,
    },
    {
      field: 'workListNumber',
      headerName: 'Work List Number',
      width: 175,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 145,
    },
    {
      field: 'scopeRevReWork',
      headerName: 'Scope / Rev / ReWork',
      width: 200,
    },
    {
      field: 'spec',
      headerName: 'Pipe Spec',
      width: 100,
    },
    {
      field: 'baseMaterial',
      headerName: 'Base Material',
      width: 120,
    },
    {
      field: 'pipeSize',
      headerName: 'Pipe Size',
      width: 100,
    },
    {
      field: 'pipeSchedule',
      headerName: 'Pipe Schedule',
      width: 150,
      type: 'singleSelect',
    },
    {
      field: 'stencil1',
      headerName: 'Stencil',
      width: 100,
    },
    {
      field: 'stencil2',
      headerName: 'Stencil',
      width: 100,
    },
    {
      field: 'stencil3',
      headerName: 'Stencil',
      width: 100,
    },
    {
      field: 'stencil4',
      headerName: 'Stencil',
      width: 100,
    },
    {
      field: 'weldType',
      headerName: 'Weld Type',
      width: 100,
    },
    {
      field: 'weldLocation',
      headerName: 'Weld Location',
      width: 125,
    },
    {
      field: 'process',
      headerName: 'Process',
      width: 100,
    },
    {
      field: 'bakeOut',
      headerName: 'Bake Out',
      width: 100,
    },
    {
      field: 'preheat',
      headerName: 'Pre Heat',
      width: 100,
    },
    {
      field: 'ndePercentage',
      headerName: 'NDE %',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', '5%', '10%', '20%', '100%', 'N/A']
    },
    {
      field: 'prePWHT',
      headerName: 'Pre PWHT',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'postPWHT',
      headerName: 'Post PWHT',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'paut',
      headerName: 'PA/UT',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'rt',
      headerName: 'RT',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'pt',
      headerName: 'PT',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'mt',
      headerName: 'MT',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'ht',
      headerName: 'HT',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'pmi',
      headerName: 'PMI',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'fn',
      headerName: 'FN',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'bhn',
      headerName: 'BHN',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'dateSentOut',
      headerName: 'Date Sent Out',
      type: 'date',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 150,
    },
    {
      field: 'dateCompleted',
      headerName: 'Date Completed',
      type: 'date',
      editable: (auth.accessLevel >= 40 || auth.accessLevel === 15) && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 150,
    },
    {
      field: 'dateReviewedBy',
      headerName: 'Date Reviewed By',
      type: 'date',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 150,
    },
    {
      field: 'dateDataEntered',
      headerName: 'Date Data Entered',
      type: 'date',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 150,
    },
    {
      field: 'requestBy',
      headerName: 'Request By',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 100,
    },
    {
      field: 'comments',
      headerName: 'Comments',
      editable: auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted),
      width: 300,
    }
  ];

  const handleModalOnClose = () => {
    setIsModalOpen(false);
    setModel(initialEmailModel);
    setSelectedEmails(null);
  };

  const formik = useFormik({
    initialValues: model,
    validationSchema: Yup.object().shape({
      projectId: Yup.number(),
      subject: Yup.string().required().max(250),
      message: Yup.string().required().max(10000)
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.emails.length === 0) {
        showAlert({ state: 'error', text: 'Please select at least one email' });
        return;
      }

      sendNotificationToContractors(values);
    },
  });

  const sendNotificationToContractors = (value) => {
    setIsLoading(true);
    ndeRequestService.sendNotificationToNDEContractor(value).then((res) => {
      showAlert({ state: 'success', text: res.message });
      getNDERequests();
    }).catch((ex) => {
      showAlert({ state: 'error', text: ex.message });
    }).finally(() => {
      handleModalOnClose();
      setIsLoading(false);
    });
  };

  const notifyAdmin = () => {
    setIsLoading(true);
    hideConfirmModal();

    ndeRequestService.sendNotificationToAdmins(projectId, ndeRequestId).then((res) => {
      showAlert({ state: 'success', text: res.message });
    }).catch((ex) => {
      showAlert({ state: 'error', text: ex.message });
    }).finally(() => {
      handleModalOnClose();
      setIsLoading(false);
    });
  };

  const fetchNDELog = () => {
    setIsLoading(true);

    ndeRequestService.getNDERequestLog(ndeRequestId).then((res) => {
      setIsLogModalOpen(true);
      setNDERequestLog(res);
    }).catch((ex) => {
      showAlert({ state: 'error', text: ex.message });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const completeNDERequest = () => {
    setIsLoading(true);

    ndeRequestService.completeNDERequest(ndeRequestId).then((res) => {
      history.push(`/project/nde-request-form/${projectId}`);
      showAlert({ state: 'success', text: res.message });
    }).catch((ex) => {
      showAlert({ state: 'error', text: ex.message });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="NDE Request Form"
                headerSize="lg"
                primaryButtonText={(auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted)) ? '' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={addLog}
                secondaryButtonText="Refresh Table"
                secondaryButtonIconLeft={<HiOutlineRefresh className={iconColor} size={iconSize} />}
                secondaryButtonOnClick={getNDERequests}
              />
              <Section hasNoContainer>
                <ContentBlock className="md-3 mb-10">
                  <CustomBlock className="content-container--actions flex-start pl-0 mt-0">

                    {
                      (auth.accessLevel >= 40 && !(projectDetail.isCompleted || ndeRequest.isCompleted)) && (
                        <Button
                          size="xs"
                          className="primary--bg ml-0"
                          text="Send NDE Request"
                          onClick={() => {
                            setIsModalOpen(true);
                          }}
                        />
                      )
                    }

                    {
                      auth.accessLevel === 15 && !(projectDetail.isCompleted || ndeRequest.isCompleted) && (
                        <Button
                          size="xs"
                          className="primary--bg ml-0"
                          text="Notify Admin"
                          onClick={() => {
                            showConfirmModal({
                              title: 'Notify Admin',
                              text: 'Are you sure you want to notify admin about this NDE Request?',
                              rightBtnText: 'Confirm',
                              btnAction: () => {
                                notifyAdmin();
                              }
                            });
                          }}
                        />
                      )
                    }

                    <Button
                      size="xs"
                      className="info--bg ml-5"
                      text="View Log"
                      onClick={() => {
                        fetchNDELog();
                      }}
                    />

                    {
                      !ndeRequest.isCompleted && (
                        <Button
                          size="xs"
                          className="secondary--bg ml-5"
                          text="Complete NDE Request"
                          onClick={() => {
                            showConfirmModal({
                              title: 'Notify Admin',
                              text: 'Are you sure you want to notify admin about this NDE Request?',
                              rightBtnText: 'Confirm',
                              btnAction: () => {
                                completeNDERequest();
                              }
                            });
                          }}
                        />
                      )
                    }

                  </CustomBlock>
                </ContentBlock>

              </Section>

              <DataGridFilterModule
                columns={columns}
                rows={source}
                originalRows={originalSource}
                updateRowFn={setSource}
              />
              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGridPro
                  rows={source}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  pageSize={100}
                  initialState={{ pinnedColumns: { left: ['action', 'weldNo', 'contractor', 'dwgNo', 'testPackageNumber'] } }}
                  ref={apiRef}
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                      </GridToolbarContainer>
                    ),
                  }}
                  onCellEditCommit={({ id, field, value }) => {
                    const originalModel = source.find((x) => x.id === id);
                    if (originalModel) {
                      originalModel[field] = value;
                      handleAfterEdit(originalModel);
                    }
                  }}
                />
              </Box>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      <ModalBlock
        hasCloseAction
        isVisible={isModalOpen}
        centered
        size="lg"
        contentHeader="Send Notification"
        primaryModalActionText="Create"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={handleModalOnClose}
      >
        <FormBlock onSubmit={formik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock className="mb-20">
              <div className="input-block-container ">
                <span className="static-label  dark--clr ">
                  Select Recipient(s)
                  <span className="danger--clr fw-600">*</span>
                </span>
              </div>
              <CreatableSelect
                isRequired
                isMulti
                isClearable
                label="Select Recipient(s)"
                options={emailOptions}
                value={selectedEmails}
                closeMenuOnSelect={false}
                onChange={(option) => {
                  if (option.length > 0) {
                    if (option[option.length - 1].__isNew__) {
                      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

                      if (!emailRegex.test(option[option.length - 1].value)) {
                        return;
                      }
                    }
                  }
                  setSelectedEmails(option);
                  let tempArr = [];

                  if (option) {
                    tempArr = option.map((x) => ({
                      name: x.label,
                      emailAddress: x.value
                    }));
                  }
                  formik.setFieldValue('emails', tempArr);
                }}
              />
            </ContentBlock>

            <ContentBlock>
              <InputBlock
                label="Subject"
                isRequired
                errorMessage={formik.errors.subject}
                inputState={`${helper.getInputClasses(formik, 'subject')}`}
                {...formik.getFieldProps('subject')}
              />
            </ContentBlock>

            <ContentBlock>
              <TextAreaBlock
                label="Message"
                isRequired
                className="sm"
                errorMessage={formik.errors.message}
                inputState={`${helper.getInputClasses(formik, 'message')}`}
                {...formik.getFieldProps('message')}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      <ModalBlock
        hasCloseAction
        isVisible={isLogModalOpen}
        centered
        size="xl"
        contentHeader="NDE Request Logs"
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => setIsLogModalOpen(false)}
      >
        <Section hasNoContainer>
          <Table striped bordered>
            <thead>
              <tr>
                <th>From</th>
                <th>Log</th>
                <th>Date Time</th>
              </tr>
            </thead>
            <tbody>
              {
                ndeRequestLog.map((log) => (
                  <tr>
                    <td>{log.createdBy}</td>
                    <td>{log.log}</td>
                    <td>{log.dateCreated}</td>
                  </tr>
                ))
              }

            </tbody>
          </Table>
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(NDRequestFormPage);