/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Chart } from 'react-google-charts';
// import moment from 'moment';
// COMPONENTS
import Table from 'react-bootstrap/Table';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
// SERVICES
import * as flangeLogService from '../../../../services/project/flangeLogService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const FlangeLogReportPage = (props) => {
  const { showAlert } = props;
  const { projectId, testPackage, isTestPackage } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [calcData, setCalcData] = useState({
    totalNumberOfBoltUps: 0,
    boltUpsReportedComplete: 0,
    totalBoltUpsQcComplete: 0,
    percentReportedComplete: 0,
    percentRemaining: 0
  });
  const [chartData, setChartData] = useState([
    ['Size', 'Total number of bolt ups', 'Bolt ups Reported Completed', 'Total bolt ups QC Complete']
  ]);

  useEffect(() => {
    getWeldLogReport();
  }, []);

  const getWeldLogReport = () => {
    setIsLoading(true);
    flangeLogService.getReportData(projectId, testPackage, isTestPackage).then((res) => {
      setData(res);
      const totalNumberOfBoltUps = res.reduce((a, v) => a + v.totalNumberOfBoltUps, 0);
      const boltUpsReportedComplete = res.reduce((a, v) => a + v.boltUpsReportedComplete, 0);
      const totalBoltUpsQcComplete = res.reduce((a, v) => a + v.totalBoltUpsQcComplete, 0);
      const percentReportedComplete = totalNumberOfBoltUps === 0 ? 0 : boltUpsReportedComplete / totalNumberOfBoltUps;
      const percentRemaining = 100 - (percentReportedComplete * 100);

      setCalcData({
        totalNumberOfBoltUps,
        boltUpsReportedComplete,
        totalBoltUpsQcComplete,
        percentReportedComplete,
        percentRemaining
      });

      const modifiedChartData = [...chartData, ['Total', totalNumberOfBoltUps, boltUpsReportedComplete, totalBoltUpsQcComplete]];

      adjustChartData(res, modifiedChartData);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const adjustChartData = (arr, modifiedChartData) => {
    arr.forEach((x) => {
      modifiedChartData.push([x.size, x.totalNumberOfBoltUps, x.boltUpsReportedComplete, x.totalBoltUpsQcComplete]);
    });

    setChartData(modifiedChartData);
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title={`Flange Log Report: ${testPackage}`}
                headerSize="lg"
              />

              <Section hasNoContainer>
                <ContentBlock cols={5}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Size</th>
                        <th>Total number of bolt ups</th>
                        <th>Bolt ups Reported Complete</th>
                        <th>Total bolt ups QC Complete</th>
                        <th>Percent Reported complete</th>
                        <th>Percent remaining</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Total</th>
                        <td>{calcData.totalNumberOfBoltUps}</td>
                        <td>{calcData.boltUpsReportedComplete}</td>
                        <td>{calcData.totalBoltUpsQcComplete}</td>
                        <td>
                          {(calcData.percentReportedComplete * 100).toFixed(2)}
                          %
                        </td>
                        <td>
                          {calcData.percentRemaining.toFixed(2)}
                          %
                        </td>
                      </tr>
                      {
                        data.map((x) => (
                          <tr>
                            <td>{x.size}</td>
                            <td>{x.totalNumberOfBoltUps}</td>
                            <td>{x.boltUpsReportedComplete}</td>
                            <td>{x.totalBoltUpsQcComplete}</td>
                            <td>
                              {(x.percentReportedComplete * 100).toFixed(2)}
                              %
                            </td>
                            <td>
                              {x.totalNumberOfBoltUps === 0 ? '0.00' : x.percentRemaining.toFixed(2)}
                              %
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </ContentBlock>

                <ContentBlock cols={7} className="mt-100">
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={chartData}
                    options={{
                      chart: {
                        title: 'Flange Tracker Summary',
                        subtitle: 'Flange Bolt-up Completion Summary',
                      },
                    }}
                  />
                </ContentBlock>
              </Section>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(FlangeLogReportPage);