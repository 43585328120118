import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Contractor/';

const getAllContractors = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllContractors?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getContractorById = async (contractorId) => axiosInstance.get(`${ENDPOINTORIGIN}GetContractorById?contractorId=${contractorId}`, constants.REQUIRE_INTERCEPTORS);

const createContractor = async (contractor) => axiosInstance.post(`${ENDPOINTORIGIN}CreateContractor`, contractor, constants.REQUIRE_INTERCEPTORS);

const updateContractor = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateContractor`, model, constants.REQUIRE_INTERCEPTORS);

const deleteContractor = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteContractor?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getAvailableUsers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAvailableUsers`, constants.REQUIRE_INTERCEPTORS);

const removeUserAssignment = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}RemoveUserAssignment?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const assignContractorUsers = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}AssignContractorUsers`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getAllContractors,
  getContractorById,
  createContractor,
  updateContractor,
  deleteContractor,
  getAvailableUsers,
  removeUserAssignment,
  assignContractorUsers
};