import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'PsvLog/';

const getPsvLogs = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetPsvLogs?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const createPsvLog = async (projectId) => axiosInstance.post(`${ENDPOINTORIGIN}CreatePsvLog?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const deletePsvLog = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeletePsvLog?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const updatePsvLog = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdatePsvLog`, model, constants.REQUIRE_INTERCEPTORS);

const getReportData = async (projectId, testPackage) => axiosInstance.get(`${ENDPOINTORIGIN}GetReportData?projectId=${projectId}&testPackage=${testPackage}`, constants.REQUIRE_INTERCEPTORS);

const getPsvLogHistory = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetPsvLogHistory?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const bulkUpdate = async (projectId, model) => axiosInstance.post(`${ENDPOINTORIGIN}BulkUpdate?projectId=${projectId}`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getPsvLogs,
  createPsvLog,
  deletePsvLog,
  updatePsvLog,
  getReportData,
  getPsvLogHistory,
  bulkUpdate
};