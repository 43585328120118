import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'FlangeLog/';

const getFlangeLogs = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetFlangeLogs?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const getReportData = async (projectId, testPackage, isTestPackage) => axiosInstance.get(`${ENDPOINTORIGIN}GetReportData?projectId=${projectId}&testPackage=${testPackage}&isTestPackage=${isTestPackage}`, constants.REQUIRE_INTERCEPTORS);

const createFlangeLog = async (projectId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateFlangeLog?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteFlangeLog = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteFlangeLog?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const updateFlangeLog = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateFlangeLog`, model, constants.REQUIRE_INTERCEPTORS);

const bulkUpdate = async (projectId, model) => axiosInstance.post(`${ENDPOINTORIGIN}BulkUpdate?projectId=${projectId}`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getFlangeLogs,
  createFlangeLog,
  deleteFlangeLog,
  updateFlangeLog,
  getReportData,
  bulkUpdate
};