// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
// COMPONENTS
import { Wizard } from 'react-use-wizard';
// ICONS1
import { AiFillFileExcel } from 'react-icons/ai';
import { BiImport } from 'react-icons/bi';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import ModalBlock from './ModalBlock';
import ExcelImportWizard from './ExcelImportWizard';
import ExcelImportChangeLogWizard from './ExcelImportChangeLogWizard';
// REDUX
import * as alert from '../redux/alertToastRedux';

const ExcelImportExportComponent = (props) => {
  const {
    columns,
    data,
    excelSheetName,
    showAlert,
    type,
    fetchLogs,
    projectId
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [excelJson, setExcelJson] = useState(null);

  const createExcel = async () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    // Create an array for headers based on the columns array
    const headers = columns.map((column) => {
      if (column.field === 'action') {
        return 'Id'; // Replace "Actions" with "id"
      }
      return column.headerName;
    });

    // Add headers as the first row
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

    // Populate rows based on the data array and column.field
    data.forEach((rowData) => {
      const row = columns.map((column) => {
        if (column.field === 'action') {
          return rowData.id;
        }

        return rowData[column.field];
      });

      XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });
    });

    const colWidths = columns.map((x) => ({ width: x.field === 'action' ? 6 : (x.width ? x.width : 150) / 10 }));
    ws['!cols'] = colWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the Excel sheet
    XLSX.writeFile(wb, `${excelSheetName}.xlsx`);
  };

  const handleModalOnClose = () => {
    setIsModalOpen(false);
    setUploadedFile([]);
  };

  return (
    <>
      <CustomBlock className="flex--end mb-sm-3">
        <CustomBlock className="content-header-button-actions flex--end">
          <Button
            className="btn-success"
            text="Download"
            onClick={createExcel}
            iconLeft={<AiFillFileExcel size={25} />}
          />
          <Button
            className="btn-primary ml-10"
            text="Import"
            onClick={() => setIsModalOpen(true)}
            iconLeft={<BiImport size={25} />}
          />
        </CustomBlock>
      </CustomBlock>

      <ModalBlock
        fullscreen
        hasCloseAction
        centered
        isVisible={isModalOpen}
        size="xl"
        contentHeader={`Import Excel Sheet - ${excelSheetName}`}
        onHide={() => handleModalOnClose()}
      >
        <Wizard>
          <ExcelImportWizard
            columns={columns}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            showAlert={showAlert}
            setExcelJson={setExcelJson}
            data={data}
          />
          <ExcelImportChangeLogWizard
            columns={columns}
            excelJson={excelJson}
            data={data}
            type={type}
            fetchLogs={fetchLogs}
            projectId={projectId}
            handleModalOnClose={handleModalOnClose}
          />
        </Wizard>
      </ModalBlock>
    </>
  );
};

export default connect(null, { ...alert.actions })(ExcelImportExportComponent);