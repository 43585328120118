/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Overlay from '../../../../../components/Overlay';
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import InputBlock from '../../../../../components/InputBlock';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import SelectBlock from '../../../../../components/SelectBlock';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as clientService from '../../../../../services/management/clientService';
import ClientValidator from '../../../../../helpers/validators/management/client/ClientValidator';
import * as helper from '../../../../../helpers/helper';

const initialClientModel = {
  id: 0,
  name: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  isActive: true,
  menus: []
};

const ClientManagementPage = (props) => {
  const {
    showAlert,
    history,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const { clientId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState(initialClientModel);
  const [projects, setProjects] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserOptions, setSelectedUserOptions] = useState([]);

  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    if (clientId) {
      populateForm();
    }
  }, []);

  const formik = useFormik({
    initialValues: client,
    validationSchema: ClientValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (clientId) {
        updateClient(values);
      } else {
        createClient(values);
      }
    },
  });

  const populateForm = () => {
    setIsLoading(true);
    clientService.getClientById(clientId).then((res) => {
      setClient(res.client);
      setProjects(res.projects);
      setUsers(res.availableUsers.map((x) => helper.setToOptionModel(`${x.firstName} ${x.lastName} (${x.role})`, x.id)));
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createClient = (model) => {
    setIsLoading(true);
    clientService.createClient(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/management/client/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateClient = (model) => {
    setIsLoading(true);
    clientService.updateClient(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const removeUserAssignment = (id) => {
    hideConfirmModal();
    setIsLoading(true);
    clientService.removeUserAssignment(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      populateForm();
      handleModalClose();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Details"
              className="primary--bg ml-0"
              size="xxs"
              to={`/project/edit/${projects[dataIndex].id}`}
            />

            {
              auth.accessLevel >= 50 && (
                <Button
                  text="Delete"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Delete Client',
                      text: 'Are you sure you want to delete this client?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        hideConfirmModal();
                      }
                    });
                  }}
                />
              )
            }

          </CustomBlock>
        )
      }
    },
    {
      name: 'projectNumber',
      label: 'Project#',
      options: columnOptions,
    },
    {
      name: 'name',
      label: 'ProjectName',
      options: columnOptions,
    }
  ];

  const options = {
    selectableRows: 'none',
    filter: false,
    sort: false,
    print: false,
    download: false,
  };

  const projectColumns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        display: auth.accessLevel >= 40,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove  User',
                  text: 'Are you sure you want to remove this user from this client?',
                  rightBtnText: 'Confirm',
                  btnAction: () => removeUserAssignment(client.users[dataIndex].id)
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'User',
    },
    {
      name: 'role',
      label: 'Role',
      options: columnOptions,
    },
  ];

  const handleModalClose = () => {
    setIsModalVisible(false);

    setTimeout(() => {
      setSelectedUserOptions([]);
    }, 100);
  };

  const saveClientUsers = () => {
    if (selectedUserOptions.length <= 0) {
      return;
    }
    setIsLoading(true);
    clientService.assignClientUsers({
      clientId: parseInt(clientId, 10),
      users: selectedUserOptions
    }).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      populateForm();
      handleModalClose();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.handleSubmit}>
            <ContentHeader
              title="Client Details"
              headerSize="lg"
            />

            <Section hasNoContainer>
              <ContentBlock>
                <ContentHeader
                  title="Basic Information"
                  headerSize="md"
                  className="alt-font fw-600 pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Client Name"
                  isRequired
                  errorMessage={formik.errors.name}
                  inputState={`${helper.getInputClasses(formik, 'name')}`}
                  {...formik.getFieldProps('name')}
                />
              </ContentBlock>

              <ContentBlock>
                <CheckboxBlock
                  label="Is active?"
                  id="isActive"
                  {...formik.getFieldProps('isActive')}
                  isChecked={formik.values.isActive}
                />
              </ContentBlock>

              <ContentBlock className="mt-30">
                <ContentHeader
                  title="Contact Information"
                  headerSize="md"
                  className="alt-font fw-600 alt--clr pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Contact Name"
                  placeholder="e.g. John Doe"
                  errorMessage={formik.errors.contactName}
                  inputState={`${helper.getInputClasses(formik, 'contactName')}`}
                  {...formik.getFieldProps('contactName')}
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Contact Phone"
                  placeholder="e.g. (999)999-9999"
                  mask="(999)999-9999"
                  errorMessage={formik.errors.contactPhone}
                  inputState={`${helper.getInputClasses(formik, 'contactPhone')}`}
                  {...formik.getFieldProps('contactPhone')}
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Contact Email"
                  placeholder="e.g. john.doe@precisionndt.net"
                  errorMessage={formik.errors.contactEmail}
                  inputState={`${helper.getInputClasses(formik, 'contactEmail')}`}
                  {...formik.getFieldProps('contactEmail')}
                />
              </ContentBlock>

              <ContentBlock className="mt-30">
                <ContentHeader
                  title="Location Information"
                  headerSize="md"
                  className="alt-font fw-600 alt--clr pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Street Address"
                  placeholder="e.g. 3900 N Causeway Blvd"
                  isRequired
                  errorMessage={formik.errors.address1}
                  inputState={`${helper.getInputClasses(formik, 'address1')}`}
                  {...formik.getFieldProps('address1')}
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Suite/Unit/Bldg No."
                  placeholder="e.g. #1350"
                  errorMessage={formik.errors.address2}
                  inputState={`${helper.getInputClasses(formik, 'address2')}`}
                  {...formik.getFieldProps('address2')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="City"
                  placeholder="e.g. New Orleans"
                  isRequired
                  errorMessage={formik.errors.city}
                  inputState={`${helper.getInputClasses(formik, 'city')}`}
                  {...formik.getFieldProps('city')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="State"
                  placeholder="e.g. Louisiana"
                  isRequired
                  errorMessage={formik.errors.state}
                  inputState={`${helper.getInputClasses(formik, 'state')}`}
                  {...formik.getFieldProps('state')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="Zip Code"
                  placeholder="e.g. 70002"
                  mask="99999"
                  isRequired
                  errorMessage={formik.errors.zipCode}
                  inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                  {...formik.getFieldProps('zipCode')}
                />
              </ContentBlock>

              {
                (clientId)
                && (
                  <>
                    <ContentBlock className="mt-60">
                      <ContentHeader
                        title="Client Users"
                        headerSize="md"
                        className="secondary--clr alt-font fw-600 pb-0"
                        primaryButtonText={auth.accessLevel >= 40 ? 'Assign User' : ''}
                        primaryButtonIconLeft={
                          <IoMdAddCircle className={iconColor} size={iconSize} />
                        }
                        primaryButtonOnClick={() => {
                          setIsModalVisible(true);
                        }}
                      />
                      <CustomBlock>
                        <MUIDataTable
                          data={client.users}
                          columns={projectColumns}
                          options={options}
                        />
                      </CustomBlock>
                    </ContentBlock>

                    <ContentBlock className="mt-60">
                      <ContentHeader
                        title="Projects"
                        headerSize="md"
                        className="secondary--clr alt-font fw-600 pb-0"
                        primaryButtonText={auth.accessLevel >= 40 ? 'Add Project' : ''}
                        primaryButtonIconLeft={
                          <IoMdAddCircle className={iconColor} size={iconSize} />
                        }
                        primaryButtonOnClick={() => {
                          history.push('/project/create');
                        }}
                      />
                      <CustomBlock>
                        <MUIDataTable
                          data={projects}
                          columns={columns}
                          options={options}
                        />
                      </CustomBlock>
                    </ContentBlock>
                  </>
                )
              }

              <ContentBlock className="content-container--actions flex-end">
                <Button
                  text="Cancel"
                  className="danger--bg"
                  onClick={() => history.push('/management/clients')}
                />

                <Button
                  text={clientId ? 'Save Changes' : 'Create'}
                  className="primary--bg"
                  onClick={formik.handleSubmit}
                />
              </ContentBlock>
            </Section>
          </FormBlock>
        </Section>
      </CustomBlock>

      {/* ASSIGNMENT MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isModalVisible}
        size="md"
        contentHeader="Assign Users"
        primaryModalActionText={users.length > 0 ? 'Add' : ''}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={saveClientUsers}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={handleModalClose}
      >
        <FormBlock>
          <Section hasNoContainer>
            <ContentBlock>
              {
                users.length > 0 ? (
                  <SelectBlock
                    label="Select Users"
                    options={users}
                    isRequired
                    isMulti
                    closeMenuOnSelect={false}
                    value={selectedUserOptions}
                    onChange={(selectedOpt) => {
                      selectedOpt = selectedOpt === null ? [] : selectedOpt;
                      setSelectedUserOptions(selectedOpt);
                    }}
                  />
                )
                  : (
                    <strong>
                      No Users Available. All the users are assigned to other clients.
                    </strong>
                  )
              }

            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...alert.actions,
  ...confirmModal.actions
})(ClientManagementPage);