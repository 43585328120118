/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
// COMPONENTS
import { HiOutlineTrash } from 'react-icons/hi';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import DataGridFilterModule from '../../../../components/DataGridFilterModule';
// SERVICES
import * as dailyFlangeLogFormService from '../../../../services/project/dailyFlangeLogFormService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';

const DailyFlangeLogFormPage = (props) => {
  const {
    // auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    history,
    projectDetail,
    setProjectDetails
  } = props;
  const { projectId, dailyFlangeFormId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const [originalSource, setOriginalSource] = useState([]);
  const [flanges, setFlanges] = useState([]);
  const [flangeLogForm, setFlangeLogForm] = useState({ isSubmitted: true });

  const iconSize = 22;
  const iconColor = 'white--clr';
  const apiRef = useGridApiRef();

  useEffect(() => {
    setIsLoading(false);
    setProjectDetails({ ...projectDetail, projectId });
    getLineItems();
    getFlangeLogForm();
  }, []);

  const getFlangeLogForm = () => {
    dailyFlangeLogFormService.getDailyFlangeLogFormById(dailyFlangeFormId).then((res) => {
      setFlangeLogForm(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
    });
  };

  const getLineItems = () => {
    setIsLoading(true);
    dailyFlangeLogFormService.getDailyFlangeLogFormLineItems(projectId, dailyFlangeFormId).then((res) => {
      setSource(res.lineItems);
      setOriginalSource(res.lineItems);
      setFlanges(['', ...res.flangeLogs]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const addLog = () => {
    setIsLoading(true);
    dailyFlangeLogFormService.createDailyFlangeLogFormLineItem(dailyFlangeFormId).then((res) => {
      const newLine = {
        id: res.id,
        dailyFlangeLogFormId: dailyFlangeFormId,
        flangeId: res.flangeId,
        burnsId: null,
        iso: null,
        systemId: null,
        testPackageNumber: null,
        size: null,
        material: null,
        rating: null,
        flangeFace: null,
        acceptedBy: null,
        reportedBoltUpdate: null,
        typeOfGasketInstalled: null,
        typeOfBoltsUsed: null,
        threadsFullyEngaged: null,
        hammerTest: null,
        hammerTestBy: null,
        torqueRequired: null,
        torqueCompleteBy: null,
        dateTorqued: null,
        qcComplete: null,
        reportedDate: null,
      };
      setSource([...source, newLine]);
      setOriginalSource([...originalSource, newLine]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAfterEdit = (model) => {
    dailyFlangeLogFormService.updateDailyFlangeLogFormLineItem({ ...model, projectId }).then((res) => {
      const temp = [...source];
      const indexToReplace = temp.map((a) => a.id).indexOf(model.id);
      model = { ...model, ...res };
      temp[indexToReplace] = model;
      setSource(temp);

      // original
      const originalTemp = [...originalSource];
      const originalIndexToReplace = originalTemp.map((a) => a.id).indexOf(model.id);
      originalTemp[originalIndexToReplace] = model;

      setOriginalSource(originalTemp);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeCell = (id) => {
    hideConfirmModal();
    dailyFlangeLogFormService.deleteDailyFlangeLogFormLineItem(id).then((res) => {
      getLineItems();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const submitForm = () => {
    setIsLoading(true);
    hideConfirmModal();
    dailyFlangeLogFormService.submitDailyFlangeLogForm(dailyFlangeFormId).then(() => {
      history.push(`/project/daily-forms/${projectId}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      hide: flangeLogForm.isSubmitted || projectDetail.isCompleted,
      renderCell: (params) => (
        <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
          {
            !projectDetail.isCompleted && (
              <Button
                size="icon"
                className="danger--bg ml-0"
                iconLeft={<HiOutlineTrash className={iconColor} size={20} />}
                onClick={() => {
                  showConfirmModal({
                    title: 'Delete Row',
                    text: 'Are you sure you want to delete this row?',
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      removeCell(params.id);
                    }
                  });
                }}
              />
            )
          }
        </CustomBlock>
      )
    },
    {
      field: 'flangeId',
      headerName: 'Flange Id',
      width: 125,
      type: 'singleSelect',
      valueOptions: flanges,
      editable: true,
    },
    {
      field: 'testPackageNumber',
      headerName: 'Test Package #',
      width: 150,
    },
    {
      field: 'projectNumber',
      headerName: 'Project Number',
      width: 150,
    },
    {
      field: 'iso',
      headerName: 'ISO',
      width: 100,
    },
    {
      field: 'systemId',
      headerName: 'System ID',
      width: 100,
    },
    {
      field: 'size',
      headerName: 'Size',
      type: 'singleSelect',
      valueOptions: ['', 0.75, 1, 1.5, 2, 3, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 30, 48, 60],
    },
    {
      field: 'material',
      headerName: 'Material',
    },
    {
      field: 'rating',
      headerName: 'Rating',
    },
    {
      field: 'flangeFace',
      headerName: 'Flange Face ACC/REJ',
      width: 175,
      type: 'singleSelect',
      valueOptions: ['', 'Accepted', 'Rejected'],
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'acceptedBy',
      headerName: 'Accepted By',
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'reportedBoltUpdate',
      headerName: 'Reported bolt-up date',
      width: 175,
      type: 'date',
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'typeOfGasketInstalled',
      headerName: 'Type of gasket installed',
      width: 175,
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'typeOfBoltsUsed',
      headerName: 'Type of Bolts used',
      width: 175,
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'threadsFullyEngaged',
      headerName: 'Threads fully engaged Y/N',
      width: 200,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No'],
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'hammerTest',
      headerName: 'Hammer test ACC/REJ',
      width: 175,
      type: 'singleSelect',
      valueOptions: ['', 'Accepted', 'Rejected'],
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'hammerTestBy',
      headerName: 'Hammer test by',
      width: 175,
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'torqueRequired',
      headerName: 'Torque req. Y/N',
      width: 145,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No'],
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'torqueCompleteBy',
      headerName: 'Torque complete by',
      width: 175,
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'dateTorqued',
      headerName: 'Date Torqued',
      width: 150,
      type: 'date',
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'reportedDate',
      headerName: 'Reported Date',
      width: 150,
      type: 'date',
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
    {
      field: 'qcComplete',
      headerName: 'QC Complete',
      editable: !flangeLogForm.isSubmitted && !projectDetail.isCompleted
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Daily Flange Inspection Report"
                headerSize="lg"
                primaryButtonText={(!flangeLogForm.isSubmitted && !projectDetail.isCompleted) ? 'Add New Row' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={addLog}
                secondaryButtonText={(!flangeLogForm.isSubmitted && !projectDetail.isCompleted) ? 'Submit Form' : ''}
                secondaryButtonOnClick={() => {
                  showConfirmModal({
                    title: 'Submit Form',
                    text: 'Are you sure you want to submit this form?',
                    rightBtnText: 'Confirm',
                    btnAction: () => submitForm()
                  });
                }}
              />
              <DataGridFilterModule
                columns={columns}
                rows={source}
                originalRows={originalSource}
                updateRowFn={setSource}
              />
              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGridPro
                  rows={source}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  pageSize={100}
                  initialState={{ pinnedColumns: { left: ['action', 'flangeId', 'testPackageNumber'] } }}
                  ref={apiRef}
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                      </GridToolbarContainer>
                    ),
                  }}
                  onCellEditCommit={({ id, field, value }) => {
                    const originalModel = source.find((x) => x.id === id);
                    if (originalModel) {
                      originalModel[field] = value;
                      handleAfterEdit(originalModel);
                    }
                  }}
                />
              </Box>
            </CustomBlock>

          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(DailyFlangeLogFormPage);