/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React from 'react';
// import moment from 'moment';
// COMPONENTS
import Table from 'react-bootstrap/Table';
// ICONS
// CUSTOM COMPONENTS
// SERVICES
// REDUX

const WeldLogReportTable = (props) => {
  const {
    tableName,
    fivePercentCompleteSw,
    fivePercentInCompleteSw,
    fivePercentCompleteFw,
    fivePercentInCompleteFw,
    fivePercentCompleteTw,
    fivePercentInCompleteTw,
    tenPercentCompleteSw,
    tenPercentInCompleteSw,
    tenPercentCompleteFw,
    tenPercentInCompleteFw,
    tenPercentCompleteTw,
    tenPercentInCompleteTw,
    twentyPercentCompleteSw,
    twentyPercentInCompleteSw,
    twentyPercentCompleteFw,
    twentyPercentInCompleteFw,
    twentyPercentCompleteTw,
    twentyPercentInCompleteTw,
    hundredPercentCompleteSw,
    hundredPercentInCompleteSw,
    hundredPercentCompleteFw,
    hundredPercentInCompleteFw,
    hundredPercentCompleteTw,
    hundredPercentInCompleteTw,
    // naSwTotal,
    // naFwTotal,
    // naTwTotal
  } = props;

  return (
    <Table
      striped
      bordered
      hover
    >
      <thead>
        <tr>
          <th colSpan={4} style={{ textAlign: 'center' }}>{tableName}</th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>%</th>
          <th>SW</th>
          <th>FW</th>
          <th>TW</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>5%</td>
          <td>
            {fivePercentCompleteSw}
            /
            {fivePercentInCompleteSw}
          </td>
          <td>
            {fivePercentCompleteFw}
            /
            {fivePercentInCompleteFw}
          </td>
          <td>
            {fivePercentCompleteTw}
            /
            {fivePercentInCompleteTw}
          </td>
        </tr>
        <tr>
          <td>10%</td>
          <td>
            {tenPercentCompleteSw}
            /
            {tenPercentInCompleteSw}
          </td>
          <td>
            {tenPercentCompleteFw}
            /
            {tenPercentInCompleteFw}
          </td>
          <td>
            {tenPercentCompleteTw}
            /
            {tenPercentInCompleteTw}
          </td>
        </tr>
        <tr>
          <td>20%</td>
          <td>
            {twentyPercentCompleteSw}
            /
            {twentyPercentInCompleteSw}
          </td>
          <td>
            {twentyPercentCompleteFw}
            /
            {twentyPercentInCompleteFw}
          </td>
          <td>
            {twentyPercentCompleteTw}
            /
            {twentyPercentInCompleteTw}
          </td>
        </tr>
        <tr>
          <td>100%</td>
          <td>
            {hundredPercentCompleteSw}
            /
            {hundredPercentInCompleteSw}
          </td>
          <td>
            {hundredPercentCompleteFw}
            /
            {hundredPercentInCompleteFw}
          </td>
          <td>
            {hundredPercentCompleteTw}
            /
            {hundredPercentInCompleteTw}
          </td>
        </tr>
        {/* <tr>
          <td>N/A</td>
          <td>{naSwTotal}</td>
          <td>{naFwTotal}</td>
          <td>{naTwTotal}</td>
        </tr> */}
      </tbody>
    </Table>
  );
};

export default WeldLogReportTable;