import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'WeldLog/';

const getWeldLogs = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetWeldLogs?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const createWeldLog = async (projectId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateWeldLog?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteWeldLog = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteWeldLog?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const updateWeldLog = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateWeldLog`, model, constants.REQUIRE_INTERCEPTORS);

const getReportData = async (projectId, testPackage, contractor) => axiosInstance.get(`${ENDPOINTORIGIN}GetReportData?projectId=${projectId}&testPackage=${testPackage}&contractor=${contractor}`, constants.REQUIRE_INTERCEPTORS);

const getCompositeReportData = async (projectId, contractor) => axiosInstance.get(`${ENDPOINTORIGIN}GetCompositeReportData?projectId=${projectId}&contractor=${contractor}`, constants.REQUIRE_INTERCEPTORS);

const getContractors = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetContractors?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const getDrawingNos = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetDrawingNos?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const bulkUpdate = async (projectId, model) => axiosInstance.post(`${ENDPOINTORIGIN}BulkUpdate?projectId=${projectId}`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getWeldLogs,
  createWeldLog,
  deleteWeldLog,
  updateWeldLog,
  getReportData,
  getCompositeReportData,
  getContractors,
  getDrawingNos,
  bulkUpdate
};