/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-children-prop */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import { CirclePicker } from 'react-color';
// ICONS
import { FiCheckCircle } from 'react-icons/fi';
import { FaSquare } from 'react-icons/fa';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import InputBlock from '../../../../../components/InputBlock';
import Overlay from '../../../../../components/Overlay';
// import CheckboxBlock from '../../../../../components/CheckboxBlock';
// SERVICES AND HELPERS
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
import * as statusService from '../../../../../services/management/statusService';
import * as helper from '../../../../../helpers/helper';
import StatusValidator from '../../../../../helpers/validators/management/status/StatusValidator';

const initialModel = {
  id: 0,
  name: '',
  colorCode: '#000000',
  requiresComment: true
};

const CustomStatusListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [statusModel, setStatusModel] = useState(initialModel);

  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getAllStatuses();
  }, []);

  const formik = useFormik({
    initialValues: statusModel,
    validationSchema: StatusValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveStatus(values);
    },
  });

  const getAllStatuses = () => {
    setIsLoading(true);
    statusService.getStatuses().then((res) => {
      setStatuses(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const saveStatus = (model) => {
    setIsLoading(true);
    statusService.saveStatus(model).then((res) => {
      handleModalClose();
      getAllStatuses();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchStatusById = (id) => {
    setIsLoading(true);

    statusService.getStatusById(id).then((res) => {
      setStatusModel(res);
      setModalVisible(true);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteStatus = (id) => {
    setIsLoading(true);

    statusService.deleteStatus(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllStatuses();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setStatusModel(initialModel);
    formik.resetForm();
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => {
          const { isSystemDefined, id } = statuses[dataIndex];

          return (
            <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
              <Button
                text="View"
                className="primary--bg"
                size="xxs"
                onClick={() => {
                  fetchStatusById(statuses[dataIndex].id);
                }}
              />
              {
                (!isSystemDefined)
                && (
                  <Button
                    text="Delete"
                    className="danger--bg"
                    size="xxs"
                    onClick={() => {
                      showConfirmModal({
                        title: 'Delete Status',
                        text: 'Are you sure you want to delete this status?',
                        rightBtnText: 'Confirm',
                        btnAction: () => deleteStatus(id)
                      });
                    }}
                  />
                )

              }
            </CustomBlock>
          );
        }
      }
    },
    {
      name: 'name',
      label: 'Status Name',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { name, isSystemDefined } = statuses[dataIndex];

          return (
            <p>
              {name}
              {isSystemDefined && <span className="info--clr fw-600"> (System)</span>}
            </p>
          );
        }
      },
    },
    {
      name: 'colorCode',
      label: 'Status',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { colorCode, name } = statuses[dataIndex];

          return (
            <Button
              text={name}
              className="badge"
              size="xxs"
              style={{
                color: colorCode,
                backgroundColor: `${colorCode}2D`,
                transform: 'scale(1.1)'
              }}
            />
          );
        }

      },
    },
    // {
    //   name: 'requiresComment',
    //   label: 'Requires Comment?',
    //   options: {
    //     ...columnOptions,
    //     customBodyRenderLite: (dataIndex) => (statuses[dataIndex].requiresComment ? 'Yes' : 'No')
    //   }
    // },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Custom Statuses"
                headerSize="lg"
                primaryButtonText="Add New Status"
                primaryButtonIconLeft={<FiCheckCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => setModalVisible(true)}
              />

              <CustomBlock>
                <MUIDataTable
                  data={statuses}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* VIEW/UPDATE MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={modalVisible}
        size="md"
        contentHeader={statusModel.id === 0 ? 'New Status' : 'Update Status'}
        primaryModalActionText={statusModel.id === 0 ? 'Add' : 'Update'}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={() => formik.handleSubmit()}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => handleModalClose()}
        children={
          (
            <FormBlock>
              <Section hasNoContainer>
                <ContentBlock>
                  <InputBlock
                    label="Status Name and Color"
                    errorMessage={formik.errors.name}
                    inputState={statusModel.isSystemDefined ? 'disabled' : `${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                    iconLeft={<FaSquare size={30} style={{ color: formik.values.colorCode }} />}
                  />
                  <CustomBlock className="ml-10 mr-10">
                    <CirclePicker
                      className="w-100"
                      color={formik.values.colorCode}
                      onChangeComplete={(c) => {
                        formik.setFieldValue('colorCode', c.hex);
                      }}
                    />
                  </CustomBlock>
                  {
                    formik.values.name
                    && (
                      <ContentBlock className="flex-center mt-30">
                        <Button
                          text={formik.values.name}
                          className="badge"
                          size="xxs"
                          style={{
                            color: formik.values.colorCode,
                            backgroundColor: `${formik.values.colorCode}2D`,
                            transform: 'scale(1.5)'
                          }}
                        />
                      </ContentBlock>
                    )
                  }
                  {/* <ContentBlock className="mt-20">
                    <CheckboxBlock
                      label="Is Additional Information Required?"
                      id="requiresComment"
                      {...formik.getFieldProps('requiresComment')}
                      isChecked={formik.values.requiresComment}
                    />
                  </ContentBlock> */}
                </ContentBlock>
              </Section>
            </FormBlock>
          )
        }
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(CustomStatusListingPage);