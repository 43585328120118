/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
// COMPONENTS
import { HiOutlineTrash } from 'react-icons/hi';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import DataGridFilterModule from '../../../../components/DataGridFilterModule';
// SERVICES
import * as dailyInspectionVtFormService from '../../../../services/project/dailyInspectionVtFormService';
import * as testPackageLogService from '../../../../services/project/testPackageLogService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';

const DailyInspectionVtFormPage = (props) => {
  const {
    // auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    history,
    projectDetail,
    setProjectDetails
  } = props;
  const { projectId, dailyInspectionVtFormId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const [originalSource, setOriginalSource] = useState([]);
  const [testPackageLogs, setTestPackageLogs] = useState([]);
  const [weldNos, setWeldNos] = useState([]);
  const [vtForm, setVtForm] = useState({ isSubmitted: true });
  const [contractors, setContractors] = useState([]);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const apiRef = useGridApiRef();

  useEffect(() => {
    setIsLoading(false);
    setProjectDetails({ ...projectDetail, projectId });
    getLineItems();
    getVtForm();
    getContractors();
  }, []);

  const getVtForm = () => {
    dailyInspectionVtFormService.getDailyInspectionVtFormById(dailyInspectionVtFormId).then((res) => {
      setVtForm(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
    });
  };

  const getContractors = () => {
    setIsLoading(true);

    testPackageLogService.getContractors(projectId).then((res) => {
      setContractors(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getLineItems = () => {
    setIsLoading(true);
    dailyInspectionVtFormService.getDailyInspectionVtFormLineItems(projectId, dailyInspectionVtFormId).then((res) => {
      setSource(res.lineItems);
      setOriginalSource(res.lineItems);
      setTestPackageLogs(['', ...res.testPackageLogs]);
      setWeldNos(['', ...res.weldLogs]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const addLog = () => {
    setIsLoading(true);
    dailyInspectionVtFormService.createDailyInspectionVtFormLineItem(dailyInspectionVtFormId).then((res) => {
      const newLineItem = {
        id: res.id,
        dailyInspectionVtFormId,
        weldNo: null,
        testPackageNo: null,
        welderId: null,
        size: null,
        sch: null,
        material: null,
        lineSpec: null,
        lineNumber: null,
        process: null,
        vtOkRej: null,
        vtBy: null,
        date: moment(res.date).format('MM/DD/YYYY'),
      };

      setSource([...source, newLineItem]);
      setOriginalSource([...originalSource, newLineItem]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAfterEdit = (model) => {
    dailyInspectionVtFormService.updateDailyInspectionVtFormLineItem({ ...model, projectId }).then((res) => {
      const temp = [...source];
      const indexToReplace = temp.map((a) => a.id).indexOf(model.id);
      model = { ...model, ...res };
      temp[indexToReplace] = model;
      setSource(temp);

      // original
      const originalTemp = [...originalSource];
      const originalIndexToReplace = originalTemp.map((a) => a.id).indexOf(model.id);
      originalTemp[originalIndexToReplace] = model;

      setOriginalSource(originalTemp);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeCell = (id) => {
    hideConfirmModal();
    dailyInspectionVtFormService.deleteDailyInspectionVtFormLineItem(id).then((res) => {
      getLineItems();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const submitForm = () => {
    hideConfirmModal();
    setIsLoading(true);
    dailyInspectionVtFormService.submitDailyInspectionVtForm(dailyInspectionVtFormId).then(() => {
      history.push(`/project/daily-forms/${projectId}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      hide: vtForm.isSubmitted || projectDetail.isCompleted,
      sortable: false,
      renderCell: (params) => (
        <Button
          size="icon"
          className="danger--bg ml-0"
          iconLeft={<HiOutlineTrash className={iconColor} size={20} />}
          onClick={() => {
            showConfirmModal({
              title: 'Delete Cell',
              text: 'Are you sure you want to delete this cell?',
              rightBtnText: 'Confirm',
              btnAction: () => {
                removeCell(params.id);
              }
            });
          }}
        />
      )
    },
    {
      field: 'weldNo',
      headerName: 'Weld#',
      width: 85,
      valueFormatter: ({ value }) => value,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(weldNos.filter((x) => x))),
      editable: !vtForm.isSubmitted,
    },
    {
      field: 'contractor',
      headerName: 'Contractor',
      width: 160,
      type: 'singleSelect',
      editable: !vtForm.isSubmitted,
      valueOptions: contractors
    },
    {
      field: 'testPackageNo',
      headerName: 'Test Package No',
      width: 150,
      // editable: !vtForm.isSubmitted,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(testPackageLogs.filter((x) => x)))
    },
    // {
    //   field: 'welderId',
    //   headerName: 'Welder Id',
    //   width: 105,
    //   editable: !vtForm.isSubmitted,
    // },
    {
      field: 'size',
      headerName: 'Size',
      // editable: !vtForm.isSubmitted,
      width: 100,
    },
    {
      field: 'sch',
      headerName: 'Sch',
      // editable: !vtForm.isSubmitted,
      width: 125,
    },
    {
      field: 'material',
      headerName: 'Material',
      // editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'lineSpec',
      headerName: 'Line Spec',
      // editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'lineNumber',
      headerName: 'Line Number',
      // editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'stencil1',
      headerName: 'Stencil1',
      editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'stencil2',
      headerName: 'Stencil2',
      editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'stencil3',
      headerName: 'Stencil3',
      editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'stencil4',
      headerName: 'Stencil4',
      editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'process',
      headerName: 'Process',
      editable: !vtForm.isSubmitted,
      width: 150,
    },
    {
      field: 'vtOkRej',
      headerName: 'VT OK/REJ',
      editable: !vtForm.isSubmitted,
      width: 175,
      type: 'singleSelect',
      valueOptions: ['', 'Ok', 'Rejected']
    },
    {
      field: 'vtBy',
      headerName: 'Vt By',
      editable: !vtForm.isSubmitted,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      editable: !vtForm.isSubmitted,
      type: 'date'
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Daily Inspection VT Progress Report"
                headerSize="lg"
                primaryButtonText={!vtForm.isSubmitted && !projectDetail.isCompleted ? 'Add New Row' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={addLog}
                secondaryButtonText={!vtForm.isSubmitted && !projectDetail.isCompleted ? 'Submit Form' : ''}
                secondaryButtonOnClick={() => {
                  showConfirmModal({
                    title: 'Submit Form',
                    text: 'Are you sure you want to submit this form?',
                    rightBtnText: 'Confirm',
                    btnAction: () => submitForm()
                  });
                }}
              />
              <DataGridFilterModule
                columns={columns}
                rows={source}
                originalRows={originalSource}
                updateRowFn={setSource}
              />
              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGridPro
                  rows={source}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  pageSize={100}
                  ref={apiRef}
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                      </GridToolbarContainer>
                    ),
                  }}
                  // autoHeight
                  // showCellRightBorder
                  // showColumnRightBorder
                  onCellEditCommit={({ id, field, value }) => {
                    const originalModel = source.find((x) => x.id === id);
                    if (originalModel) {
                      originalModel[field] = value;
                      handleAfterEdit(originalModel);
                    }
                  }}
                />
              </Box>
            </CustomBlock>

          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(DailyInspectionVtFormPage);