import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'ProjectDocument/';

const getProjectDocumentsByName = async (projectId, parentName, childName) => axiosInstance.get(`${ENDPOINTORIGIN}GetProjectDocumentsByName?projectId=${projectId}&parentName=${parentName}&childName=${childName}`, constants.REQUIRE_INTERCEPTORS);

const getProjectDocuments = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetProjectDocuments?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const saveProjectDocument = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SaveProjectDocument`, model, constants.REQUIRE_INTERCEPTORS);

const deleteProjectDocument = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteProjectDocument?projectDocumentId=${id}`, constants.REQUIRE_INTERCEPTORS);

const renameProjectDocument = async (projectDocumentId, newName) => axiosInstance.put(`${ENDPOINTORIGIN}RenameProjectDocument?projectDocumentId=${projectDocumentId}&newName=${encodeURIComponent(newName)}`, {}, constants.REQUIRE_INTERCEPTORS);

const openProjectDocument = async (filePath) => axiosInstance.get(`${ENDPOINTORIGIN}ViewDocument?filePath=${filePath}`, constants.REQUIRE_INTERCEPTORS);

export {
  getProjectDocumentsByName,
  getProjectDocuments,
  saveProjectDocument,
  deleteProjectDocument,
  renameProjectDocument,
  openProjectDocument
};