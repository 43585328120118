/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { useWizard } from 'react-use-wizard';
import readXlsxFile from 'read-excel-file';
import * as _ from 'lodash';
import moment from 'moment';
// CUSTOM COMPONENTS
import { Alert } from 'react-bootstrap';
import ContentBlock from './ContentBlock';
import Button from './Button';
import Section from './Section';
import FileUploadBlock from './FileUploadBlock';
import TextHeader from './TextHeader';
// SERVICES AND HELPERS
import constants from '../constants/constants';

const ExcelImportWizard = (props) => {
  const {
    columns,
    uploadedFile,
    setUploadedFile,
    // showAlert,
    setExcelJson,
    data
  } = props;
  const { nextStep } = useWizard();
  const [errMessage, setErrMessage] = useState('');

  const getJsonKey = (headerName) => (headerName === 'Id' ? 'id' : columns.find((x) => x.headerName === headerName)?.field);

  const formatDateOrReturnOriginal = (input) => {
    if (input === null) {
      return '';
    }
    const parsedDate = moment(input);

    return (parsedDate.isValid() && typeof input === 'object') ? parsedDate.format('MM/DD/YYYY') : input;
  };

  const validateExcel = async () => {
    const rows = await readXlsxFile(uploadedFile[0].file);
    const [headerRow, ...dataRows] = rows;

    const jsonData = dataRows.map((row) => {
      const rowData = {};
      headerRow.forEach((header, index) => {
        rowData[getJsonKey(header)] = formatDateOrReturnOriginal(row[index]);
      });
      return rowData;
    });

    console.log('Json Data is ', jsonData);

    let isValid = true;

    for (let i = 0; i < jsonData.length; i += 1) {
      const columnsInUploadedExcelSheet = Object.keys(jsonData[i]).filter((x) => x !== 'id');
      const defaultColumns = columns.map((x) => x.field).filter((x) => x !== 'action');

      const columnsNotInExcel = _.difference(defaultColumns, columnsInUploadedExcelSheet);

      if (columnsNotInExcel.length > 0) {
        isValid = false;
        setErrMessage(`${columnsNotInExcel.join(', ')}`);
        return;
      }
    }
    setExcelJson(jsonData.filter((a) => a.id === 0 || data.some((b) => b.id === a.id)));

    if (isValid) {
      nextStep();
    }
  };

  return (
    <Section hasNoContainer>
      <TextHeader
        title="Step 1: Excel Upload"
        size="md"
        subtitle="Choose a Excel (.xls or .xlsx) file to upload."
        subtitleSize="sm"
        className="primary--clr mb-5"
        subtitleClassName="mb-20"
      />
      {
        errMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrMessage('')}
            dismissible
          >
            <Alert.Heading>Error! Mapping Columns</Alert.Heading>
            <p>
              Invalid Excel sheet uploaded. Please upload a valid excel file.
              Excel sheet does not contain column:
              <strong>
                {errMessage}
              </strong>
            </p>
          </Alert>
        )
      }

      <ContentBlock>
        <FileUploadBlock
          files={uploadedFile}
          labelIdle="Upload Excel File to create report."
          onupdatefiles={setUploadedFile}
          acceptedFileTypes={constants.EXCEL_FILE_TYPES}
        />
      </ContentBlock>

      <ContentBlock className="content-container--actions flex-end">
        <Button
          text="Continue"
          className={uploadedFile.length > 0 ? 'teal--bg' : 'light-grey--sbg'}
          onClick={validateExcel}
          isDisabled={uploadedFile.length === 0}
        />
      </ContentBlock>
    </Section>
  );
};

export default ExcelImportWizard;