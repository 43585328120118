/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-confusing-arrow */
// DEPENDENCIES
import React, { useState } from 'react';
import * as _ from 'lodash';
import moment from 'moment';
// COMPONENTS
import Accordion from 'react-bootstrap/Accordion';
// CUSTOM COMPONENETS
import SelectBlock from './SelectBlock';
import ContentBlock from './ContentBlock';
import CustomBlock from './CustomBlock';
import Section from './Section';
import Button from './Button';

const DataGridFilterModule = (props) => {
  const { columns, originalRows, updateRowFn } = props;
  const [filter, setFilter] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleFilterOnClick = () => {
    const temp = [];

    originalRows.forEach((row) => {
      let shouldAdd = true;
      if (Object.values(filter).every((x) => !x.length)) {
        updateRowFn(originalRows);
        return;
      }
      for (const key in filter) {
        if (filter[key].length > 0) {
          if (!filter[key].includes(row[key])) {
            shouldAdd = false;
            break;
          }
        }
      }
      if (shouldAdd) {
        temp.push(row);
      }
      updateRowFn(temp);
    });
  };

  const resetFilter = () => {
    const resetModel = {};
    for (const key of Object.keys(selectedOptions)) {
      resetModel[key] = null;
    }

    setSelectedOptions(resetModel);
    setFilter({});
    updateRowFn(originalRows);
  };

  const determineOptionsDataType = (val) => ((val instanceof Date) ? moment(val).format('MM/D/YYYY') : val);

  return (
    <CustomBlock className="input-section mb-15">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filters</Accordion.Header>
          <Accordion.Body>
            <Section hasNoContainer>
              {columns
                .filter((x) => !['action', 'id'].includes(x.field))
                .map((column) => (
                  <ContentBlock cols={3}>
                    <SelectBlock
                      key={column.field}
                      isMulti
                      value={selectedOptions[column.field]}
                      label={column.headerName}
                      closeMenuOnSelect={false}
                      placeholder="All"
                      // options={_.uniqBy(originalRows, column.field)
                      //   // .filter((x) => x[column.field])
                      //   .map((row) => ({
                      //     label: row[column.field] ?
                      //  determineOptionsDataType(row[column.field]) : '<NONE>',
                      //     value: determineOptionsDataType(row[column.field]),
                      //   }))}
                      options={
                        _.sortBy(_.uniqBy(originalRows, column.field)
                          // .filter((x) => x[column.field])
                          .map((row) => ({
                            label: row[column.field] ? determineOptionsDataType(row[column.field]) : '<NONE>',
                            value: determineOptionsDataType(row[column.field]),
                          })), (item) => (item.label === '<NONE>') ? -Infinity : 0)
                      }
                      onChange={(opt) => {
                        const temp = {};
                        temp[column.field] = opt.map((x) => x.value);

                        const tempSelected = {};
                        tempSelected[column.field] = opt;

                        setSelectedOptions({ ...selectedOptions, ...tempSelected });
                        setFilter({ ...filter, ...temp });
                      }}
                    />
                  </ContentBlock>
                ))}
            </Section>
            <ContentBlock className="content-container--actions flex-end">
              <Button
                onClick={resetFilter}
                text="Clear"
                className="danger--bg mb-10"
                size="xs"
              />

              <Button
                onClick={handleFilterOnClick}
                text="Apply Filter"
                className="primary--bg ml-5 mb-10"
                size="xs"
              />
            </ContentBlock>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </CustomBlock>
  );
};

export default DataGridFilterModule;