/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// import moment from 'moment';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
// COMPONENTS
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
import {
  HiOutlineTrash,
  HiDocumentReport,
  HiOutlineRefresh
} from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import DataGridFilterModule from '../../../../components/DataGridFilterModule';
// SERVICES
import * as flangeLogService from '../../../../services/project/flangeLogService';
import * as testPackageLogService from '../../../../services/project/testPackageLogService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
import ExcelImportExportComponent from '../../../../components/ExcelImportExportComponent';

const FlangeLogPage = (props) => {
  const {
    auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    history,
    projectDetail,
    setProjectDetails
  } = props;
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const [originalSource, setOriginalSource] = useState([]);
  const [testPackageLogs, setTestPackageLogs] = useState([]);
  const [psvLogs, setPsvLogs] = useState([]);
  const [contractors, setContractors] = useState([]);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const apiRef = useGridApiRef();

  useEffect(() => {
    setIsLoading(false);
    setProjectDetails({ ...projectDetail, projectId });
    getFlangeLogs();
    getContractors();
  }, []);

  const getFlangeLogs = () => {
    setIsLoading(true);
    flangeLogService.getFlangeLogs(projectId).then((res) => {
      setSource(res.flangeLogs);
      setOriginalSource(res.flangeLogs);
      setTestPackageLogs(['', ...res.testPackageLogs]);
      setPsvLogs(['', ...res.psvLogs]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getContractors = () => {
    setIsLoading(true);

    testPackageLogService.getContractors(projectId).then((res) => {
      setContractors(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const addLog = () => {
    setIsLoading(true);
    flangeLogService.createFlangeLog(projectId).then((res) => {
      const newAddedLine = {
        id: res.id,
        projectId,
        flangeId: res.flangeId,
        burnsId: null,
        iso: null,
        systemId: null,
        testPackageNumber: null,
        lineNumber: null,
        size: null,
        material: null,
        rating: null,
        flangeFace: null,
        acceptedBy: null,
        reportedBoltUpdate: null,
        typeOfGasketInstalled: null,
        typeOfBoltsUsed: null,
        threadsFullyEngaged: null,
        hammerTest: null,
        hammerTestBy: null,
        torqueRequired: null,
        torqueCompleteBy: null,
        dateTorqued: null,
        qcCompleted: null,
        qcCompleteBy: null,
        qaCompleted: null,
        qaCompleteBy: null,
        moc: null,
        // reportedDate: null,
        systems: null
      };
      setSource([...source, newAddedLine]);
      setOriginalSource([...originalSource, newAddedLine]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAfterEdit = (model) => {
    flangeLogService.updateFlangeLog(model).then(() => {
      const temp = [...source];
      const indexToReplace = temp.map((a) => a.id).indexOf(model.id);
      // model.mocNumber = res.moc;
      temp[indexToReplace] = model;
      setSource(temp);

      // original
      const originalTemp = [...originalSource];
      const originalIndexToReplace = originalTemp.map((a) => a.id).indexOf(model.id);
      originalTemp[originalIndexToReplace] = model;

      setOriginalSource(originalTemp);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeCell = (id) => {
    hideConfirmModal();
    setIsLoading(true);
    flangeLogService.deleteFlangeLog(id).then((res) => {
      getFlangeLogs();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      valueFormatter: (params) => params.id,
      renderCell: (params) => (
        <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
          {
            (auth.accessLevel >= 50 && !projectDetail.isCompleted) && (
              <Button
                size="icon"
                className="danger--bg ml-0"
                iconLeft={<HiOutlineTrash className={iconColor} size={20} />}
                onClick={() => {
                  showConfirmModal({
                    title: 'Delete Row',
                    text: 'Are you sure you want to delete this row?',
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      removeCell(params.id);
                    }
                  });
                }}
              />
            )
          }

          <Button
            size="icon"
            className={(params.row.testPackageNumber || params.row.psvReliefValveNumber) ? 'warning--bg' : 'grey--bg'}
            iconLeft={<HiDocumentReport className={iconColor} size={20} />}
            onClick={() => {
              if (params.row.testPackageNumber) {
                history.push(`/project/flange-log-report/${projectId}/${params.row.testPackageNumber}/true`);
              } else if (params.row.psvReliefValveNumber) {
                history.push(`/project/flange-log-report/${projectId}/${params.row.psvReliefValveNumber}/false`);
              } else {
                showAlert({ text: 'Select Test Package or PSV # to view report', state: 'warning' });
              }
            }}
          />
        </CustomBlock>
      )
    },
    {
      field: 'flangeId',
      headerName: 'Flange Id',
      width: 125,
      valueFormatter: ({ value }) => value,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      renderCell: (params) => (
        <Link to={`/project/log-document/Flange Log/${projectId}/${params.value}`}>
          {params.value}
        </Link>
      )
    },
    {
      field: 'testPackageNumber',
      headerName: 'Test Package #',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 150,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(['', ...testPackageLogs.filter((x) => x)]))
    },
    {
      field: 'psvReliefValveNumber',
      headerName: 'PSV Relief Valve #',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 150,
      type: 'singleSelect',
      valueOptions: Array.from(new Set(['', ...psvLogs.filter((x) => x)]))
    },
    {
      field: 'contractor',
      headerName: 'Contractor',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 160,
      type: 'singleSelect',
      valueOptions: ['', ...contractors]
    },
    {
      field: 'lineNumber',
      headerName: 'Line #',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'projectNumber',
      headerName: 'Project Number',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'moc',
      headerName: 'MOC #',
      width: 140,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'systems',
      headerName: 'Systems',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'iso',
      headerName: 'ISO',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'systemId',
      headerName: 'System ID',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 100,
    },
    {
      field: 'size',
      headerName: 'Size',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60],
      width: 100,
    },
    {
      field: 'material',
      headerName: 'Material',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 100,
    },
    {
      field: 'rating',
      headerName: 'Rating',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 100,
    },
    {
      field: 'flangeFace',
      headerName: 'Flange Face ACC/REJ',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 175,
      type: 'singleSelect',
      valueOptions: ['', 'Accepted', 'Rejected']
    },
    {
      field: 'acceptedBy',
      headerName: 'Accepted By',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 100,
    },
    {
      field: 'reportedBoltUpdate',
      headerName: 'Reported bolt-up date',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 175,
      type: 'date',
    },
    {
      field: 'typeOfGasketInstalled',
      headerName: 'Type of gasket installed',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 175,
    },
    {
      field: 'typeOfBoltsUsed',
      headerName: 'Type of Bolts used',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 175,
    },
    {
      field: 'threadsFullyEngaged',
      headerName: 'Threads fully engaged Y/N',
      width: 200,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'hammerTest',
      headerName: 'Hammer test ACC/REJ',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 175,
      type: 'singleSelect',
      valueOptions: ['', 'Accepted', 'Rejected']
    },
    {
      field: 'hammerTestBy',
      headerName: 'Hammer test by',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 175,
    },
    {
      field: 'torqueRequired',
      headerName: 'Torque req. Y/N',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'torqueCompleteBy',
      headerName: 'Torque complete by',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 175,
    },
    {
      field: 'dateTorqued',
      headerName: 'Date Torqued',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    // {
    //   field: 'reportedDate',
    //   headerName: 'Reported Date',
    //   width: 150,
    //   editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    //   type: 'date'
    // },
    {
      field: 'qcCompleted',
      headerName: 'QC Completed Date',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'qcCompleteBy',
      headerName: 'QC Completed By',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'qaCompleted',
      headerName: 'QA Completed Date',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'qaCompleteBy',
      headerName: 'QA Completed By',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Flange Log"
                headerSize="lg"
                primaryButtonText={auth.accessLevel >= 40 && !projectDetail.isCompleted ? 'Add New Row' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={addLog}
                secondaryButtonText="Refresh Table"
                secondaryButtonIconLeft={<HiOutlineRefresh className={iconColor} size={iconSize} />}
                secondaryButtonOnClick={getFlangeLogs}
              />
              <DataGridFilterModule
                columns={columns}
                rows={source}
                originalRows={originalSource}
                updateRowFn={setSource}
              />

              <ExcelImportExportComponent
                columns={columns}
                data={source}
                excelSheetName={`Flange Log: ${projectDetail.projectName}`}
                type="FLANGELOG"
                fetchLogs={getFlangeLogs}
                projectId={projectId}
              />

              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGridPro
                  rows={source}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  initialState={{ pinnedColumns: { left: ['action', 'flangeId', 'testPackageNumber', 'psvReliefValveNumber', 'contractor'] } }}
                  pageSize={100}
                  ref={apiRef}
                  // componentsProps={{ cell: { tabIndex: 1 } }}
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport csvOptions={{ fileName: 'Flange Log' }} />
                      </GridToolbarContainer>
                    ),
                  }}
                  onCellEditCommit={({ id, field, value }) => {
                    const originalModel = source.find((x) => x.id === id);
                    if (originalModel) {
                      originalModel[field] = value;
                      handleAfterEdit(originalModel);
                    }
                  }}
                />
              </Box>
            </CustomBlock>

          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(FlangeLogPage);