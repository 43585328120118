import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Status/';

const getStatuses = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetStatuses`, constants.REQUIRE_INTERCEPTORS);

const getStatusById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetStatusById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const saveStatus = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SaveStatus`, model, constants.REQUIRE_INTERCEPTORS);

const deleteStatus = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteStatus?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getStatuses,
  getStatusById,
  saveStatus,
  deleteStatus,
};