/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// import moment from 'moment';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
// COMPONENTS
import Table from 'react-bootstrap/Table';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
import {
  HiOutlineTrash,
  HiDocumentReport,
  HiOutlineRefresh
} from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import DataGridFilterModule from '../../../../components/DataGridFilterModule';
// SERVICES
import * as testPackageLogService from '../../../../services/project/testPackageLogService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
import ExcelImportExportComponent from '../../../../components/ExcelImportExportComponent';

const TestPackageLogPage = (props) => {
  const {
    auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    history,
    projectDetail,
    setProjectDetails
  } = props;
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState([]);
  const [originalSource, setOriginalSource] = useState([]);
  const [miniTableData, setMiniTableData] = useState({
    ndePercentage: '0%',
    hydroPercentage: '0%',
    postPercentage: '0%',
    packagePercentage: '0%',
    hydroCount: '-',
    postCount: '-',
    packageCount: '-',
    hydroAsterickCount: '-',
    postAsterickCount: '-',
    packageAsterickCount: '-',
    unitAsterickCount: '-',
    overallTotal: '0'
  });
  const [psvData, setPsvData] = useState({
    totalApiTestReportCompleted: 0,
    totalPopTestReportCompleted: 0,
    totalQcCompleted: 0,
    totalPsv: 0,
  });
  console.log(psvData);
  const iconSize = 22;
  const iconColor = 'white--clr';
  const apiRef = useGridApiRef();

  useEffect(() => {
    setIsLoading(false);
    setProjectDetails({ ...projectDetail, projectId });
    getTestPackageLogs();
  }, []);

  useEffect(() => {
    if (source.length > 0) {
      calculateMiniTableData(source);
    }
  }, [source]);

  const getTestPackageLogs = () => {
    setIsLoading(true);
    testPackageLogService.getTestPackageLogs(projectId).then((res) => {
      setSource(res.testPackageLogs);
      setOriginalSource(res.testPackageLogs);
      setPsvData(res.psvLogData);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const calculateMiniTableData = (arr) => {
    const temp = {
      ndeCount: arr.filter((x) => x.ndeComplete).length,
      hydroCount: arr.filter((x) => x.hydroComplete).length,
      postCount: arr.filter((x) => x.postComplete).length,
      packageCount: arr.filter((x) => x.packageSold).length,
      hydroAsterickCount: arr.filter((x) => x.hydroComplete === '*').length,
      postAsterickCount: arr.filter((x) => x.postComplete === '*').length,
      packageAsterickCount: arr.filter((x) => x.packageSold === '*').length,
      unitAsterickCount: arr.filter((x) => x.unit === '*').length,
      overallTotal: arr.filter((x) => x.ndeComplete && x.hydroComplete && x.postComplete && x.packageSold).length
    };

    setMiniTableData({
      ...temp,
      ndePercentage: `${((temp.ndeCount / source.length) * 100).toFixed(2)}%`,
      hydroPercentage: `${((temp.hydroCount / source.length) * 100).toFixed(2)}%`,
      postPercentage: `${((temp.postCount / source.length) * 100).toFixed(2)}%`,
      packagePercentage: `${((temp.packageCount / source.length) * 100).toFixed(2)}%`,
    });
  };

  const addLog = () => {
    setIsLoading(true);
    testPackageLogService.createTestPackageLog(projectId).then((res) => {
      const newLineToAdd = {
        id: res.id,
        tp: res.tp,
        executionWo: null,
        unit: '',
        mocNumber: '',
        jobNumber: null,
        packageNumber: res.packageNumber,
        lineNumber: null,
        testPressure: null,
        material: '',
        stressRelief: '',
        ndePercentage: '',
        ndeComplete: null,
        hydroComplete: null,
        postComplete: null,
        packageSold: null,
        notes: '',
        comments: '',
        systems: ''
      };

      setSource([...source, newLineToAdd]);
      setOriginalSource([...originalSource, newLineToAdd]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAfterEdit = (model) => {
    testPackageLogService.updateTestPackageLog(model).then(() => {
      const temp = [...source];
      const indexToReplace = temp.map((a) => a.id).indexOf(model.id);
      temp[indexToReplace] = model;
      setSource(temp);

      const originalTemp = [...originalSource];
      const originalIndexToReplace = originalTemp.map((a) => a.id).indexOf(model.id);
      originalTemp[originalIndexToReplace] = model;

      setOriginalSource(originalTemp);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeCell = (id) => {
    hideConfirmModal();
    testPackageLogService.deleteTestPackageLog(id).then((res) => {
      getTestPackageLogs();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 125,
      renderCell: (params) => (
        <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
          {
            (auth.accessLevel >= 50 && !projectDetail.isCompleted) && (
              <Button
                size="icon"
                className="danger--bg ml-0"
                iconLeft={<HiOutlineTrash className={iconColor} size={20} />}
                onClick={() => {
                  showConfirmModal({
                    title: 'Delete Cell',
                    text: 'Are you sure you want to delete this cell?',
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      removeCell(params.id);
                    }
                  });
                }}
              />
            )
          }

          <Button
            size="icon"
            className="info--bg"
            iconLeft={<HiDocumentReport className={iconColor} size={20} />}
            onClick={() => {
              history.push(`/project/weld-log-report/${projectId}/${params.row.tp}${params.row.contractor ? `/${encodeURIComponent(params.row.contractor)}` : ''}`);
            }}
          />

          <Button
            size="icon"
            className="warning--bg"
            iconLeft={<HiDocumentReport className={iconColor} size={20} />}
            onClick={() => {
              history.push(`/project/flange-log-report/${projectId}/${params.row.tp}/true`);
            }}
          />
        </CustomBlock>
      )
    },
    {
      field: 'tp',
      headerName: 'TP',
      width: 75,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      valueFormatter: ({ value }) => value,
      renderCell: (params) => (
        <Link to={`/project/log-document/Test Packages/${projectId}/${params.value}`}>
          {params.value}
        </Link>
      )
    },
    {
      field: 'contractor',
      headerName: 'Contractor',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 160,
    },
    {
      field: 'lineNumber',
      headerName: 'Line #',
      width: 170,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'executionWo',
      headerName: 'Work List #',
      width: 175,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      valueFormatter: ({ value }) => value,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'jobNumber',
      headerName: 'Project Number',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 140,
      valueFormatter: ({ value }) => value,
    },
    {
      field: 'systems',
      headerName: 'Systems',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted
    },
    {
      field: 'mocNumber',
      headerName: 'MOC #',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 140,
      valueFormatter: ({ value }) => value,
    },
    {
      field: 'flangeLogs',
      headerName: 'Flange Logs',
      width: 200,
      valueFormatter: ({ value }) => value,
    },
    {
      field: 'testPressure',
      headerName: 'Test Pressure',
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      width: 150,
    },
    {
      field: 'material',
      headerName: 'Material',
      width: 110,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'stressRelief',
      headerName: 'Stress Relief',
      width: 145,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'singleSelect',
      valueOptions: ['', 'Yes', 'No']
    },
    {
      field: 'ndePercentage',
      headerName: 'NDE %',
      width: 100,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'ndeComplete',
      headerName: 'NDE Complete',
      width: 150,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'hydroComplete',
      headerName: 'Hydro Complete',
      width: 220,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'postComplete',
      headerName: 'Post Complete',
      width: 220,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'packageSold',
      headerName: 'Package Sold',
      width: 220,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
      type: 'date'
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 300,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    },
    {
      field: 'comments',
      headerName: 'Exception',
      width: 300,
      editable: auth.accessLevel >= 40 && !projectDetail.isCompleted,
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Test Package Log"
                headerSize="lg"
                primaryButtonText={auth.accessLevel >= 40 && !projectDetail.isCompleted ? 'Add New Row' : ''}
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonOnClick={addLog}
                secondaryButtonText="Refresh Table"
                secondaryButtonIconLeft={<HiOutlineRefresh className={iconColor} size={iconSize} />}
                secondaryButtonOnClick={getTestPackageLogs}
              />

              <ContentBlock cols={12} className="d-flex">
                <Table
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <th>Total Test Packages</th>
                      <th>NDE% Complete</th>
                      <th>Hydro% Complete</th>
                      <th>Post% Complete</th>
                      <th>Package Sold%</th>
                      <th>Overall Total #</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{source.length}</td>
                      <td>{miniTableData.ndePercentage}</td>
                      <td>{miniTableData.hydroPercentage}</td>
                      <td>{miniTableData.postPercentage}</td>
                      <td>{miniTableData.packagePercentage}</td>
                      <td>{miniTableData.overallTotal}</td>
                    </tr>
                  </tbody>
                  {/* <thead>
                    <tr>
                      <th>Total PSV Log</th>
                      <th>QC% Complete</th>
                      <th>Pop Test% Complete</th>
                      <th>API% Complete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{psvData.totalPsv}</td>
                      <td>{psvData.totalPsv === 0 ? 0 : `${((psvData.totalQcCompleted / psvData.totalPsv) * 100).toFixed(2)}%`}</td>
                      <td>{psvData.totalPsv === 0 ? 0 : `${((psvData.totalPopTestReportCompleted / psvData.totalPsv) * 100).toFixed(2)}%`}</td>
                      <td>{psvData.totalPsv === 0 ? 0 : `${((psvData.totalApiTestReportCompleted / psvData.totalPsv) * 100).toFixed(2)}%`}</td>
                    </tr>
                  </tbody> */}
                </Table>
              </ContentBlock>
              <DataGridFilterModule
                columns={columns}
                rows={source}
                originalRows={originalSource}
                updateRowFn={setSource}
              />
              <ExcelImportExportComponent
                columns={columns}
                data={source}
                excelSheetName={`Test Package Log: ${projectDetail.projectName}`}
                type="TESTPACKAGELOG"
                fetchLogs={getTestPackageLogs}
                projectId={projectId}
              />
              <Box sx={{ height: '60vh', width: '100%' }}>
                <DataGridPro
                  rows={source}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  initialState={{ pinnedColumns: { left: ['action', 'tp', 'contractor'] } }}
                  pageSize={100}
                  ref={apiRef}
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                      </GridToolbarContainer>
                    ),
                  }}
                  onCellEditCommit={({ id, field, value }) => {
                    const originalModel = source.find((x) => x.id === id);
                    if (originalModel) {
                      originalModel[field] = value;
                      handleAfterEdit(originalModel);
                    }
                  }}
                />
              </Box>
            </CustomBlock>

          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(TestPackageLogPage);