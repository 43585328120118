import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'DailyFormNotificationEmail/';

const getAll = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAll`, constants.REQUIRE_INTERCEPTORS);

const getById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const save = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}Save`, model, constants.REQUIRE_INTERCEPTORS);

const deleteEmail = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}Delete?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAll,
  getById,
  save,
  deleteEmail,
};