/* eslint-disable camelcase */

// DEPENDENCIES
import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { enableBodyScroll } from 'body-scroll-lock';
// ROUTES
import PrivateRoute from './routes/PrivateRoute';
// CUSTOM COMPONENTS
import ConfirmModal from './components/ConfirmModal';
import AlertToast from './components/AlertToast';
// AUTHENTICATION
import LoginPage from './pages/admin/authentication/LoginPage';
// MANAGEMENT PAGES - SYSTEM
import UserListingPage from './pages/admin/management/system/user/UserListingPage';
import UserProfilePage from './pages/admin/management/system/user/UserProfilePage';
// PROJECT
// -- Project
import ProjectListingPage from './pages/admin/project/project/ProjectListingPage';
import ProjectManagementPage from './pages/admin/project/project/ProjectManagementPage';
import ProjectDocumentPage from './pages/admin/project/project/ProjectDocumentPage';
import TestPackageLogPage from './pages/admin/project/project/TestPackageLogPage';
import FlangeLogPage from './pages/admin/project/project/FlangeLogPage';
import WeldLogPage from './pages/admin/project/project/WeldLogPage';
import PSVLogPage from './pages/admin/project/project/PSVLogPage';
import LogDocumentPage from './pages/admin/project/project/documents/LogDocumentPage';
import DailyFormsPage from './pages/admin/project/project/DailyFormsPage';
import DailyInspectionVtFormPage from './pages/admin/project/project/DailyInspectionVtFormPage';
import ProjectAccessPage from './pages/admin/project/project/ProjectAccessPage';
import NDERequestListingPage from './pages/admin/project/project/NDERequestListingPage';
// -- WALL CHART
// -- EQUIPMENT
// -- STATUS
import CustomStatusListingPage from './pages/admin/project/management/status/CustomStatusListingPage';
// -- CLIENT
import ClientListingPage from './pages/admin/project/management/client/ClientListingPage';
import ClientManagementPage from './pages/admin/project/management/client/ClientManagementPage';
// --  CONTRACTOR
import ContractorListingPage from './pages/admin/project/management/contractor/ContractorListingPage';
import ContractorManagementPage from './pages/admin/project/management/contractor/ContractorManagementPage';
import ProjectRoute from './routes/ProjectRoute';
import DailyFormNotificationEmailListingPage from './pages/admin/management/system/notificationEmail/DailyFormNotificationEmailListingPage';
import WeldLogReportPage from './pages/admin/project/project/WeldLogReportPage';
import FlangeLogReportPage from './pages/admin/project/project/FlangeLogReportPage';
import DailyFlangeLogFormPage from './pages/admin/project/project/DailyFlangeLogFormPage';
import NDERequestFormPage from './pages/admin/project/project/NDERequestFormPage';
import KPIReportPage from './pages/admin/project/project/KPIReportPage';
// -- COMPONENTS
// -- MENU ITEMS

const Routes = (props) => {
  const { auth } = props;
  const [isAuthorized, setIsAuthorized] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    const body = document.querySelector('body');
    window.scrollTo(0, 0);
    enableBodyScroll(body);
  }, [pathname]);

  const validateAccess = () => {
    if (auth.authToken !== '') {
      try {
        const decodedToken = jwt_decode(auth.authToken);
        setIsAuthorized(decodedToken);
      } catch (ex) {
        setIsAuthorized(false);
      }
    } else {
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    validateAccess();
  }, [auth]);

  return (
    <>
      <AlertToast />
      <ConfirmModal />

      <Switch>
        <Redirect
          exact
          from="/"
          to="/my-profile"
        />
        <Route
          path="/login"
          component={LoginPage}
        />

        {
          isAuthorized
            ? (
              <>
                {/* MANAGEMENT PAGES - SYSTEM */}
                <PrivateRoute
                  path="/management/users"
                  component={UserListingPage}
                  title="Manage Users"
                />

                <PrivateRoute
                  path="/my-profile"
                  component={UserProfilePage}
                  title="My Profile"
                />

                {/* PROJECTS */}
                <PrivateRoute
                  path="/projects"
                  component={ProjectListingPage}
                  title="All Projects"
                />

                <PrivateRoute
                  exact
                  path="/project/create"
                  component={ProjectManagementPage}
                  title="Project Details"
                />

                {/* PROJECT MANAGEMENT */}
                <ProjectRoute
                  path="/project/edit/:projectId"
                  component={ProjectManagementPage}
                  title="Project Details"
                />

                <ProjectRoute
                  path="/project/documents/:projectId"
                  component={ProjectDocumentPage}
                  title="Project Documents"
                />

                <ProjectRoute
                  path="/project/test-package-log/:projectId"
                  component={TestPackageLogPage}
                  title="Test Package Log"
                />

                <ProjectRoute
                  path="/project/log-document/:logName/:projectId/:folderName"
                  component={LogDocumentPage}
                  title="Log Documents"
                />

                <ProjectRoute
                  path="/project/flange-log/:projectId"
                  component={FlangeLogPage}
                  title="Flange Log"
                />

                <ProjectRoute
                  path="/project/flange-log-report/:projectId/:testPackage/:isTestPackage"
                  component={FlangeLogReportPage}
                  title="Flange Log Report"
                />

                <ProjectRoute
                  path="/project/weld-log/:projectId"
                  component={WeldLogPage}
                  title="Weld Log"
                />

                <ProjectRoute
                  path="/project/psv-log/:projectId"
                  component={PSVLogPage}
                  title="PSV Log"
                />

                <ProjectRoute
                  exact
                  path="/project/weld-log-report/:projectId"
                  component={WeldLogReportPage}
                  title="Composite Weld Log Report"
                />

                <ProjectRoute
                  exact
                  path="/project/weld-log-report/:projectId/:testPackage"
                  component={WeldLogReportPage}
                  title="Weld Log Report"
                />

                <ProjectRoute
                  exact
                  path="/project/weld-log-report/:projectId/:testPackage/:contractor"
                  component={WeldLogReportPage}
                  title="Weld Log Report"
                />

                <ProjectRoute
                  path="/project/daily-forms/:projectId"
                  component={DailyFormsPage}
                  title="Weld Log"
                />

                <ProjectRoute
                  path="/project/daily-inspection-vt-form/:projectId/:dailyInspectionVtFormId"
                  component={DailyInspectionVtFormPage}
                  title="Daily Inspection VT Form"
                />

                <ProjectRoute
                  path="/project/daily-flange-form/:projectId/:dailyFlangeFormId"
                  component={DailyFlangeLogFormPage}
                  title="Daily Flange Log Form"
                />

                <ProjectRoute
                  exact
                  path="/project/nde-request-form/:projectId/:ndeRequestId"
                  component={NDERequestFormPage}
                  title="NDE Request Form"
                />

                <ProjectRoute
                  exact
                  path="/project/nde-request-form/:projectId/"
                  component={NDERequestListingPage}
                  title="NDE Requests"
                />

                <ProjectRoute
                  path="/project/kpi-report/:projectId"
                  component={KPIReportPage}
                  title="KPI Report"
                />

                <ProjectRoute
                  path="/project/project-access/:projectId"
                  component={ProjectAccessPage}
                  title="Project Access"
                />

                {/* STATUS MANAGEMENT */}
                <PrivateRoute
                  path="/management/custom-statuses"
                  component={CustomStatusListingPage}
                  title="Manage Custom Statuses"
                />

                <PrivateRoute
                  exact
                  path="/management/notification-emails"
                  component={DailyFormNotificationEmailListingPage}
                  title="Manage Emails"
                />

                {/* CLIENT MANAGEMENT */}
                <PrivateRoute
                  path="/management/clients"
                  component={ClientListingPage}
                  title="Manage Clients"
                />

                <PrivateRoute
                  exact
                  path="/management/client"
                  component={ClientManagementPage}
                  title="Add Client"
                />

                <PrivateRoute
                  exact
                  path="/management/client/edit/:clientId"
                  component={ClientManagementPage}
                  title="Manage Client"
                />

                {/* CONTRACTOR MANAGEMENT */}
                <PrivateRoute
                  path="/management/contractors"
                  component={ContractorListingPage}
                  title="Manage Contractors"
                />

                <PrivateRoute
                  exact
                  path="/management/contractor"
                  component={ContractorManagementPage}
                  title="Manage Client"
                />

                <PrivateRoute
                  exact
                  path="/management/contractor/edit/:contractorId"
                  component={ContractorManagementPage}
                  title="Manage Contractor"
                />
              </>
            )
            : <Redirect to="/login" />
        }
      </Switch>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(Routes);