/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import RecursiveDocumentTree from '../../../../../components/RecursiveDocumentTree';
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import OverLay from '../../../../../components/Overlay';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
import * as projectDocumentService from '../../../../../services/project/projectDocumentService';
// HELPERS
import * as helper from '../../../../../helpers/helper';
import constants from '../../../../../constants/constants';

const LogDocumentPage = (props) => {
  const { auth, showAlert } = props;
  const { projectId, folderName, logName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [folderStructure, setFolderStructure] = useState({
    id: 99999,
    name: 'Documents',
    filePath: null,
    children: null
  });

  useEffect(() => {
    fetchProjectDocuments();
  }, []);

  const getParentName = () => {
    if (logName === 'Flange Log') {
      return logName;
    }

    // if (logName === 'Test Package Log') {
    //   return 'Test Packages';
    // }

    return logName;
  };

  const fetchProjectDocuments = () => {
    setIsLoading(true);
    projectDocumentService.getProjectDocumentsByName(projectId, getParentName(), folderName).then((res) => {
      setFolderStructure(res);
    }).catch(() => {
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const addNewFileFolder = (projectDoc) => projectDocumentService.saveProjectDocument(helper.convertJsonToFormData({ ...projectDoc, projectId })).then((res) => {
    showAlert({ text: res.message, state: 'success' });

    return res.projectDocument;
  }).catch((ex) => showAlert({ text: ex.message, state: 'error' }));

  const renameFileFolder = (id, name) => projectDocumentService.renameProjectDocument(id, name).then((res) => {
    showAlert({ text: res.message, state: 'success' });
  }).catch((ex) => showAlert({ text: ex.message, state: 'error' }));

  const deleteFileFolder = (id) => projectDocumentService.deleteProjectDocument(id).then((res) => {
    showAlert({ text: res.message, state: 'success' });
  }).catch((ex) => showAlert({ text: ex.message, state: 'error' }));

  const downloadFile = async (filePath) => {
    setIsLoading(true);
    try {
      window.open(`${constants.API_URL}ProjectDocument/ViewDocument?token=${auth.authToken}&filePath=${filePath}`, '_blank');
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title={logName}
                headerSize="lg"
              />
              <CustomBlock>
                <RecursiveDocumentTree
                  folderStructure={folderStructure}
                  setFolderStructure={setFolderStructure}
                  addNewFileFolder={addNewFileFolder}
                  renameFileFolder={renameFileFolder}
                  deleteFileFolder={deleteFileFolder}
                  isActionsVisible
                  downloadFile={downloadFile}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(LogDocumentPage);