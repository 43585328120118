/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
// DEPENDENCIES
import React from 'react';
// COMPONENTS
import PropTypes from 'prop-types';
// ICONS
// import { TbArrowBigLeft, TbArrowBigLeftLines } from 'react-icons/tb';
import { TbArrowBigLeftLines } from 'react-icons/tb';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const SideNav = (props) => {
  const {
    children,
    backgroundColor,
    isCollapsible,
    isExpanded,
    onClick
  } = props;

  const iconSize = 20;
  return (
    <CustomBlock className={`side-nav-wrapper ${backgroundColor}`}>
      <CustomBlock className={`side-nav ${isExpanded ? 'expanded' : 'collapsed'} ${isCollapsible && 'collapsible'}`}>
        {children}
      </CustomBlock>

      {
        isCollapsible
        && (
          <button
            className="resize-control"
            onClick={onClick}
          >
            <CustomBlock className={`resize-icon-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
              <TbArrowBigLeftLines size={iconSize} className="resize-icon" />
            </CustomBlock>
          </button>
        )
      }
    </CustomBlock>
  );
};

// PROPS
SideNav.propTypes = {
  // MAIN PROPS
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
  isCollapsible: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func
};

// DEFAULT PROPS
SideNav.defaultProps = {
  // MAIN PROPS
  children: null,
  backgroundColor: 'white--sbg',
  isCollapsible: false,
  isExpanded: false,
  onClick: null
};

export default SideNav;