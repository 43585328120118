import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Project/';

const getProjects = async (showCompleted) => axiosInstance.get(`${ENDPOINTORIGIN}GetProjects?showCompleted=${showCompleted}`, constants.REQUIRE_INTERCEPTORS);

const getClientAndContractors = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetClientAndContractors`, constants.REQUIRE_INTERCEPTORS);

const getProjectById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetProjectById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const saveProject = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SaveProject`, model, constants.REQUIRE_INTERCEPTORS);

const deleteProject = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteProject?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const completeProject = async (projectId) => axiosInstance.put(`${ENDPOINTORIGIN}CompleteProject?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const assignProjectUsers = async (projectId, userId) => axiosInstance.post(`${ENDPOINTORIGIN}AssignProjectUsers?projectId=${projectId}&userId=${userId}`, {}, constants.REQUIRE_INTERCEPTORS);

const removeUserAssignment = async (projectId, userId) => axiosInstance.delete(`${ENDPOINTORIGIN}RemoveUserAssignment?projectId=${projectId}&userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const getUsersForProjectAccess = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetUsersForProjectAccess?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const getAvailableUsers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAvailableUsers`, constants.REQUIRE_INTERCEPTORS);

const getKPIReportData = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetKPIReportData?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const getContractorUsersForProject = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetContractorUsersForProject?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const getKPIReportForWeldLogByContractor = async (projectId, contractor) => axiosInstance.get(`${ENDPOINTORIGIN}GetKPIReportForWeldLogByContractor?projectId=${projectId}&contractor=${contractor}`, constants.REQUIRE_INTERCEPTORS);

export {
  getProjects,
  getClientAndContractors,
  getProjectById,
  saveProject,
  deleteProject,
  completeProject,
  assignProjectUsers,
  removeUserAssignment,
  getUsersForProjectAccess,
  getAvailableUsers,
  getKPIReportData,
  getContractorUsersForProject,
  getKPIReportForWeldLogByContractor
};