const constants = {
  API_URL: process.env.REACT_APP_API_URL,
  REQUIRE_INTERCEPTORS: {
    requestHandlerEnabled: true,
    responseHandlerEnabled: true
  },
  MUI_X_LICENSE_KEY: '2668dcfc50334dd626d321103a195f56Tz01NDAwNCxFPTE2OTk1NTY2ODI5MzMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  DEFAULT_COLUMNS: [
    'Work Order #',
    'Equipment ID',
    'Equipment Type',
    'Inspection Type',
    'Inspection Description',
    'Initial Inspection ',
    // 'Setback/Delay ',
    'NDE Required',
    'NDE Request Per Inspection Finding',
    'NDE Request Complete',
    'Eddy Current/ IRIS Examination',
    'UT Examination',
    'PT Examination',
    'MT Examination',
    'Advanced Non-Destructive Examination',
    // 'Repairs Needed/IWR Number',
    // 'Repairs Completed',
    'Final Inspection Review Complete',
    'Closure Form Signed',
    'Inspection Report Uploaded to Inspection Program',
    'General Comments',
  ],
  EXCEL_FILE_TYPES: [
    'application/vnd.ms-excel',
    'application/msexcel',
    'application/x-msexcel',
    'application/x-ms-excel',
    'application/x-excel',
    'application/x-dos_ms_excel',
    'application/xls',
    'application/x-xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  CELL_TYPES: {
    primary: 0,
    secondary: 1,
    tertiary: 2,
    custom: 3
  }
};

export default constants;