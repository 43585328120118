/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { RiBuildingLine } from 'react-icons/ri';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import OverLay from '../../../../../components/Overlay';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as contractorService from '../../../../../services/management/contractorService';

const ContractorListingPage = (props) => {
  const { showAlert, auth, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [contractors, setContractors] = useState([]);

  useEffect(() => {
    getContractors();
  }, []);

  const getContractors = () => {
    setIsLoading(true);
    contractorService.getAllContractors('').then((res) => {
      setContractors(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteContractor = (id) => {
    setIsLoading(true);
    hideConfirmModal();
    contractorService.deleteContractor(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getContractors();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const iconSize = 22;
  const iconColor = 'white--clr';

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              to={`/management/contractor/edit/${contractors[dataIndex].id}`}
            />

            {
              auth.accessLevel >= 50 && (
                <Button
                  text="Delete"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Delete Contractor',
                      text: 'Are you sure you want to delete this contractor?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        deleteContractor(contractors[dataIndex].id);
                      }
                    });
                  }}
                />
              )
            }

          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Contractor',
      options: columnOptions,
    },
    {
      name: 'location',
      label: 'Location',
      options: columnOptions,
    },
    {
      name: 'zipCode',
      label: 'Zip Code',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is Contractor Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (<p>{contractors[dataIndex].isActive ? 'Yes' : 'No'}</p>)
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Contractors"
                headerSize="lg"
                primaryButtonText="New Contractor"
                primaryButtonIconLeft={<RiBuildingLine className={iconColor} size={iconSize} />}
                primaryButtonTo="/management/contractor"
              />

              <CustomBlock>
                <MUIDataTable
                  data={contractors}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  { ...alert.actions, ...confirmModal.actions }
)(ContractorListingPage);