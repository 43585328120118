/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
// import MUIDataTable from 'mui-datatables';
// ICONS
import { FiCheck } from 'react-icons/fi';
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import InputBlock from '../../../../components/InputBlock';
import SelectBlock from '../../../../components/SelectBlock';
import ModalBlock from '../../../../components/ModalBlock';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
// SERVICES AND HELPERS
import * as projectService from '../../../../services/project/projectService';
import ProjectValidator from '../../../../helpers/validators/project/project/ProjectValidator';
import * as helper from '../../../../helpers/helper';

const initialModel = {
  id: 0,
  clientId: '',
  name: '',
  description: '',
  projectNumber: '',
  users: [],
  projectContractors: []
};

const ProjectManagementPage = (props) => {
  const {
    showAlert,
    history,
    showConfirmModal,
    hideConfirmModal,
    auth,
    setProjectDetails
  } = props;
  const { projectId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [project, setProject] = useState(initialModel);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [selectedUserOptions, setSelectedUserOptions] = useState([]);
  const [selectedClientOption, setSelectedClientOption] = useState([]);
  const [selectedContractorOption, setSelectedContractorOption] = useState([]);
  const [isClientLoading, setIsClientLoading] = useState(false);

  useEffect(() => {
    fetchClientsAndPopulateForm();
  }, []);

  const fetchClientsAndPopulateForm = () => {
    setIsClientLoading(true);
    projectService.getClientAndContractors().then((res) => {
      const tempClients = res.clients.map((x) => ({ ...x, label: x.name, value: x.id }));
      setClients(tempClients);

      const tempContractors = res.contractors.map((x) => ({ ...x, label: x.name, value: x.id }));
      setContractors(tempContractors);

      if (projectId) {
        populateForm(tempClients, tempContractors);
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsClientLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: project,
    validationSchema: ProjectValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveProject(values);
    },
  });

  const populateForm = (processedClients, processedContractors) => {
    processedClients = processedClients || clients;
    processedContractors = processedContractors || contractors;
    setIsLoading(true);
    projectService.getProjectById(projectId).then((res) => {
      setProject(res);
      setProjectDetails({
        projectName: `#${res.projectNumber} ${res.name ? ' : ' + res.name : ''}`,
        projectId,
        isCompleted: res.isCompleted
      });
      fetchUsers();
      const savedClient = processedClients.find((x) => x.id === res.clientId);
      if (savedClient) {
        setSelectedClientOption(savedClient);
      }

      if (res.projectContractors) {
        const selectedContractorIds = res.projectContractors.map((a) => a.contractorId);
        const savedContractors = processedContractors.filter((x) => selectedContractorIds.includes(x.id));
        if (savedContractors) {
          setSelectedContractorOption(savedContractors);
        }
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchUsers = () => {
    projectService.getAvailableUsers().then((res) => {
      setUsers(res.map((x) => helper.setToOptionModel(`${x.firstName} ${x.lastName} (${x.role})`, x.id)));
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'danger' });
    });
  };

  const saveProject = (model) => {
    setIsLoading(true);
    projectService.saveProject(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      if (model.id === 0) {
        history.push(`/project/edit/${res.id}`);
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const saveProjectUsers = () => {
    if (selectedUserOptions.length <= 0) {
      return;
    }
    setIsLoading(true);
    projectService.assignProjectUsers({
      projectId: parseInt(projectId, 10),
      users: selectedUserOptions
    }).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      populateForm();
      handleModalClose();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const handleModalClose = () => {
    setIsModalVisible(false);

    setTimeout(() => {
      setSelectedUserOptions([]);
    }, 100);
  };

  // const removeUserAssignment = (id) => {
  //   hideConfirmModal();
  //   setIsLoading(true);
  //   projectService.removeUserAssignment(id).then((res) => {
  //     showAlert({ text: res.message, state: 'success' });
  //     populateForm();
  //     handleModalClose();
  //   }).catch((ex) => {
  //     showAlert({ text: ex.message, state: 'warning' });
  //   }).finally(() => setIsLoading(false));
  // };

  const iconSize = 22;
  const iconColor = 'white--clr';

  // const columnOptions = {
  //   filter: false,
  //   sort: false,
  //   print: false,
  //   download: false
  // };

  // const options = {
  //   selectableRows: 'none',
  //   filter: false,
  //   sort: false,
  //   print: false,
  //   download: false,
  // };

  // const columns = [
  //   {
  //     name: 'id',
  //     label: 'Actions',
  //     options: {
  //       filter: false,
  //       sort: false,
  //       print: false,
  //       download: false,
  //       display: auth.accessLevel >= 40,
  //       customBodyRenderLite: (dataIndex) => (
  //         <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
  //           <Button
  //             text="Remove"
  //             className="danger--bg"
  //             size="xxs"
  //             onClick={() => {
  //               showConfirmModal({
  //                 title: 'Remove  User',
  //                 text: 'Are you sure you want to remove this user from this project?',
  //                 rightBtnText: 'Confirm',
  //                 btnAction: () => removeUserAssignment(project.users[dataIndex].id)
  //               });
  //             }}
  //           />
  //         </CustomBlock>
  //       )
  //     }
  //   },
  //   {
  //     name: 'name',
  //     label: 'User',
  //   },
  //   {
  //     name: 'role',
  //     label: 'Role',
  //     options: columnOptions,
  //   },
  // ];

  const completeProject = () => {
    setIsLoading(true);
    projectService.completeProject(projectId).then((res) => {
      history.push('/projects');
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  return (
    <>
      {(isLoading || isClientLoading) && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <FormBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="Project Details"
              headerSize="lg"
              primaryButtonText={((auth.accessLevel >= 50 && projectId) && !project.isCompleted) ? 'Complete Project' : ''}
              primaryButtonIconLeft={<FiCheck className={iconColor} size={iconSize} />}
              primaryButtonOnClick={() => {
                showConfirmModal({
                  title: 'Complete Project',
                  text: 'Are you sure you want to complete this project?',
                  rightBtnText: 'Confirm',
                  btnAction: () => completeProject()
                });
              }}
            />
            <Section hasNoContainer>
              {/* PROJECT BASIC INFO */}
              <ContentBlock cols={4}>
                <SelectBlock
                  label="Client"
                  placeholder="Select client"
                  options={clients}
                  isDisabled={auth.accessLevel < 40 || project.isCompleted}
                  isRequired
                  closeMenuOnSelect={false}
                  value={selectedClientOption}
                  onChange={(selectedOpt) => {
                    selectedOpt = selectedOpt === null ? null : selectedOpt;
                    setSelectedClientOption(selectedOpt);
                    if (selectedOpt) {
                      formik.setFieldValue('clientId', selectedOpt.id);
                    }
                  }}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="Project#"
                  isRequired
                  errorMessage={formik.errors.projectNumber}
                  inputState={auth.accessLevel < 40 || projectId ? 'disabled' : `${helper.getInputClasses(formik, 'projectNumber')}`}
                  maxLength={10}
                  {...formik.getFieldProps('projectNumber')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Project Name"
                  errorMessage={formik.errors.name}
                  inputState={auth.accessLevel < 40 || project.isCompleted ? 'disabled' : `${helper.getInputClasses(formik, 'name')}`}
                  {...formik.getFieldProps('name')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <SelectBlock
                  label="Contractors"
                  placeholder="Select Contractors"
                  options={contractors}
                  isDisabled={auth.accessLevel < 40 || project.isCompleted}
                  isRequired
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedContractorOption}
                  onChange={(selectedOpt) => {
                    selectedOpt = selectedOpt === null ? null : selectedOpt;
                    setSelectedContractorOption(selectedOpt);
                    if (selectedOpt) {
                      formik.setFieldValue('projectContractors', selectedOpt.map((x) => ({ projectId, contractorId: x.id })));
                    }
                  }}
                />
              </ContentBlock>

              <ContentBlock cols={8}>
                <InputBlock
                  label="Project Description"
                  errorMessage={formik.errors.description}
                  inputState={(auth.accessLevel < 40 || project.isCompleted) ? 'disabled' : `${helper.getInputClasses(formik, 'description')}`}
                  {...formik.getFieldProps('description')}
                />
              </ContentBlock>
              {/* {
                (projectId)
                && (
                  <ContentBlock className="mt-60">
                    <ContentHeader
                      title="QC Users"
                      headerSize="md"
                      className="secondary--clr alt-font fw-600 pb-0"
                      primaryButtonText={auth.accessLevel > 40 ? 'Assign QC' : ''}
                      primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                      primaryButtonOnClick={() => {
                        setIsModalVisible(true);
                      }}
                    />
                    <CustomBlock>
                      <MUIDataTable
                        data={project.users}
                        columns={columns}
                        options={options}
                      />
                    </CustomBlock>
                  </ContentBlock>
                )
              } */}
              {
                (auth.accessLevel >= 40 && !project.isCompleted) && (
                  <ContentBlock className="content-container--actions flex-end">
                    <Button
                      text="Cancel"
                      className="danger--bg"
                      onClick={() => history.push('/projects')}
                    />

                    <Button
                      text={projectId ? 'Save Changes' : 'Create'}
                      className="primary--bg"
                      type="submit"
                      onClick={formik.handleSubmit}
                    />
                  </ContentBlock>
                )
              }

            </Section>
          </FormBlock>
        </Section>
      </CustomBlock>

      {/* ASSIGNMENT MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isModalVisible}
        size="md"
        contentHeader="Assign Users"
        primaryModalActionText={users.length > 0 ? 'Add' : ''}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={saveProjectUsers}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={handleModalClose}
      >
        <FormBlock>
          <Section hasNoContainer>
            <ContentBlock>
              {
                users.length > 0 ? (
                  <SelectBlock
                    label="Select Users"
                    options={users}
                    isRequired
                    isMulti
                    closeMenuOnSelect={false}
                    value={selectedUserOptions}
                    onChange={(selectedOpt) => {
                      selectedOpt = selectedOpt === null ? [] : selectedOpt;
                      setSelectedUserOptions(selectedOpt);
                    }}
                  />
                )
                  : (
                    <strong>No Users Available. All the users are assigned to projects.</strong>
                  )
              }

            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>

  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alert.actions, ...confirmModal.actions, ...projectDetailRedux.actions })(ProjectManagementPage);