import * as Yup from 'yup';

export default Yup.object().shape({
  firstName: Yup.string().required('First Name is required').max(100),
  lastName: Yup.string().required('Last Name is required').max(100),
  // pin: Yup.string().required('Pin is required').min(4).max(4),
  roleId: Yup.number().nullable().required('Please select a Role for the user'),
  email: Yup.string().email('Please enter valid email').required('Email is required').max(250),
  password: Yup.string()
    .required('Password is required')
    .min(5, 'Minimum password length must be at least 5 characters')
    .max(100),
  confirmPassword: Yup.string()
    .required('Please confirm your new password')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  isActive: Yup.bool().nullable(),
});