/* eslint-disable import/prefer-default-export */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'DailyForm/';

const getDailyForms = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetDailyForms?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const deleteDailyForm = async (formId, type) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteDailyForm?formId=${formId}&type=${type}`, constants.REQUIRE_INTERCEPTORS);

export {
  getDailyForms,
  deleteDailyForm
};