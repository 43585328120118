import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'TestPackageLog/';

const getTestPackageLogs = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetTestPackageLogs?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const createTestPackageLog = async (projectId) => axiosInstance.post(`${ENDPOINTORIGIN}CreateTestPackageLog?projectId=${projectId}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteTestPackageLog = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteTestPackageLog?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const updateTestPackageLog = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateTestPackageLog`, model, constants.REQUIRE_INTERCEPTORS);

const getContractors = async (projectId) => axiosInstance.get(`${ENDPOINTORIGIN}GetContractors?projectId=${projectId}`, constants.REQUIRE_INTERCEPTORS);

const getContractorsByTp = async (projectId, tp) => axiosInstance.get(`${ENDPOINTORIGIN}GetContractorsByTp?projectId=${projectId}&tp=${tp}`, constants.REQUIRE_INTERCEPTORS);

const bulkUpdate = async (projectId, model) => axiosInstance.post(`${ENDPOINTORIGIN}BulkUpdate?projectId=${projectId}`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getTestPackageLogs,
  createTestPackageLog,
  deleteTestPackageLog,
  updateTestPackageLog,
  getContractors,
  getContractorsByTp,
  bulkUpdate
};