/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
// COMPONENTS
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import { HiArrowSmRight } from 'react-icons/hi';
// CUSTOM COMPONENTS
import ContentBlock from './ContentBlock';
import CustomBlock from './CustomBlock';
import CheckboxBlock from './CheckboxBlock';
import Button from './Button';
import Section from './Section';
import TextHeader from './TextHeader';
// SERVICES AND HELPERS
import * as helper from '../helpers/helper';
import * as testPackageLogService from '../services/project/testPackageLogService';
import * as weldLogService from '../services/project/weldLogService';
import * as flangeLogService from '../services/project/flangeLogService';
import * as psvLogLogService from '../services/project/psvLogService';
import Overlay from './Overlay';

const ExcelImportChangeLogWizard = (props) => {
  const {
    projectId,
    columns,
    excelJson,
    data,
    type,
    fetchLogs,
    handleModalOnClose
  } = props;
  const { previousStep, nextStep } = useWizard();
  const [viewAll, setViewAll] = useState(false);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const updatedValues = helper.findChangedObjects(data, excelJson.filter((x) => x.id !== 0));
    setUpdatedRows(updatedValues);
  }, []);

  const generateBasedOnChange = (id, key, val) => {
    const parent = data.find((x) => x.id === id);

    if (parent) {
      return (parent[key] === val)
        ? parent[key]
        : (
          <CustomBlock className="changelog-block has-reporter-initials">
            <CustomBlock className="changelog-details">
              <CustomBlock className="changelog-changes">
                {
                  parent[key]
                  && (
                    <CustomBlock className="change-block">
                      <p className="change-item previous">{parent[key]}</p>
                    </CustomBlock>
                  )
                }

                <CustomBlock className="icon">
                  <HiArrowSmRight className="dark-blue--clr" size={20} />
                </CustomBlock>
                <CustomBlock className="change-block">
                  <p className="change-item current">{val}</p>
                </CustomBlock>
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        );
    }

    return (<p>Invalid!</p>);
  };

  const applyChange = () => {
    let updateFunction;
    const model = [...updatedRows, ...(excelJson.filter((x) => x.id === 0))];

    switch (type) {
      case 'TESTPACKAGELOG':
        updateFunction = testPackageLogService.bulkUpdate(projectId, model);
        break;
      case 'WELDLOG':
        updateFunction = weldLogService.bulkUpdate(projectId, model);
        break;
      case 'FLANGELOG':
        updateFunction = flangeLogService.bulkUpdate(projectId, model);
        break;
      case 'PSVLOG':
        updateFunction = psvLogLogService.bulkUpdate(projectId, model);
        break;
      default:
        break;
    }

    setIsLoading(true);
    updateFunction.then((res) => {
      fetchLogs();
      setErrors(res.errors);
      if (res.errors.length === 0) {
        handleModalOnClose();
      }
    }).catch((ex) => {
      console.log(ex);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Section hasNoContainer>
      <TextHeader
        title="Step 2: Updates/Changes to Log"
        size="md"
        subtitle="Preview the changes before you save the changes"
        subtitleSize="sm"
        className="primary--clr mb-5"
        subtitleClassName="mb-20"
      />

      {
        isLoading ? (
          <Overlay hasLoader />
        ) : (
          <>
            {
              (errors.length > 0)
                ? (
                  <>
                    <Alert variant="warning">
                      <Alert.Heading>Warning!</Alert.Heading>
                      <p>
                        Aww! Some of the updates were not able to sync.
                        Please see the error list below.
                      </p>
                    </Alert>
                    <Section hasNoContainer>
                      <Table
                        bordered
                      >
                        <thead>
                          <tr>
                            <th>Errors</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            errors.map((error) => (
                              <tr>
                                <td>
                                  {error}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </Section>
                  </>
                ) : (
                  <>
                    <ContentBlock>
                      <Section hasNoContainer>
                        <ContentBlock>
                          {excelJson.filter((x) => x.id > 0).length > 0 && (
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>Change/s</Accordion.Header>
                                <Accordion.Body style={{ overflowX: 'scroll' }}>
                                  <ContentBlock>
                                    <Section hasNoContainer>
                                      <ContentBlock cols={12}>
                                        <CheckboxBlock
                                          label="View All"
                                          id="viewAll"
                                          isChecked={viewAll}
                                          onChange={(preval) => {
                                            setViewAll((prevVal) => !prevVal);
                                          }}
                                        />
                                      </ContentBlock>
                                    </Section>
                                  </ContentBlock>
                                  <Table
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        {
                                          columns.map((x) => x.headerName !== 'Actions'
                                            && (
                                              <th>
                                                {x.headerName}
                                              </th>
                                            ))
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        (viewAll
                                          ? excelJson.filter((x) => x.id > 0)
                                          : updatedRows).map((row) => (
                                            <tr>
                                              {
                                                Object.keys(row).map((key) => key !== 'id' && (
                                                  <td>
                                                    {generateBasedOnChange(row.id, key, row[key])}
                                                  </td>
                                                ))
                                              }
                                            </tr>
                                        ))
                                      }
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        </ContentBlock>
                        {excelJson.filter((x) => x.id === 0).length > 0 && (
                          <ContentBlock className="mt-10">
                            <Accordion defaultActiveKey="1">
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>New Addition/s</Accordion.Header>
                                <Accordion.Body style={{ overflowX: 'scroll' }}>
                                  <Table
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        {
                                          columns.map((x) => x.headerName !== 'Actions'
                                            && (
                                              <th>
                                                {x.headerName}
                                              </th>
                                            ))
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        excelJson.filter((x) => x.id === 0).map((row) => (
                                          <tr>
                                            {
                                              Object.keys(row).map((key) => key !== 'id' && (
                                                <td>
                                                  {row[key]}
                                                </td>
                                              ))
                                            }
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </ContentBlock>
                        )}
                      </Section>
                    </ContentBlock>

                    <ContentBlock className="content-container--actions flex-end">
                      <Button
                        text="Previous Step"
                        className="danger--bg"
                        onClick={previousStep}
                      />
                      <Button
                        text="Apply Changes"
                        className="teal--bg"
                        onClick={applyChange}
                      />
                    </ContentBlock>
                  </>
                )
            }

          </>
        )
      }
    </Section>
  );
};

export default ExcelImportChangeLogWizard;