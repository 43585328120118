/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import {
  FiEye,
  FiEyeOff,
  FiMail
} from 'react-icons/fi';
// import { IoIosKeypad } from 'react-icons/io';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import InputBlock from '../../../../../components/InputBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import SelectBlock from '../../../../../components/SelectBlock';
import OverLay from '../../../../../components/Overlay';
// HELPERS AND SERVICES
import * as userService from '../../../../../services/management/userService';
import * as roleService from '../../../../../services/management/roleService';
import * as helper from '../../../../../helpers/helper';
import RegisterUserValidator from '../../../../../helpers/validators/management/user/RegisterUserValidator';
import UpdatePasswordValidator from '../../../../../helpers/validators/management/user/UpdatePasswordValidator';
import EditUserValidator from '../../../../../helpers/validators/management/user/EditUserValidator';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as auth from '../../../../../redux/authRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';

const userRegisterModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
  // pin: '',
  password: '',
  confirmPassword: '',
  isActive: true,
};

const initialUpdatePasswordModel = {
  id: 0,
  password: '',
  confirmPassword: '',
};

const initialEditModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
  // pin: '',
  isActive: true,
};

const UserListingPage = (props) => {
  const {
    showAlert,
    auth,
    location,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [updatePasswordModalVisible, setUpdatePasswordRegisterModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePasswordModel, setUpdatePasswordModel] = useState(initialUpdatePasswordModel);
  const [editUserModel, setEditUserModel] = useState(initialEditModel);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  useEffect(() => {
    getAllUsers();
    getAllRoles();
    if (location.state) {
      setRegisterModalVisible(location.state.addNew);
    }
  }, []);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const inputIconColor = 'grey--clr';
  // const pinCodeIcon = <IoIosKeypad size={iconSize} className={inputIconColor} />;
  const emailIcon = <FiMail size={iconSize} className={inputIconColor} />;

  const getAllRoles = () => {
    roleService.getAllRoles().then((res) => {
      setRoleOptions(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => { });
  };

  const registerUserFormik = useFormik({
    initialValues: userRegisterModel,
    validationSchema: RegisterUserValidator,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      registerUser(values);
    },
  });

  const updatePasswordUserFormik = useFormik({
    initialValues: updatePasswordModel,
    validationSchema: UpdatePasswordValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateUserPassword(values);
    },
  });

  const editUserFormik = useFormik({
    initialValues: editUserModel,
    validationSchema: EditUserValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      editUser(values);
    },
  });

  const getUserDetails = (id) => {
    setIsLoading(true);
    userService.getUserById(id).then((res) => {
      setEditUserModel(res);
      setEditModalVisible(true);
      const userInRole = roleOptions.find((x) => x.value === res.roleId);
      if (userInRole) {
        setSelectedRole(userInRole);
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getAllUsers = () => {
    setIsLoading(true);
    userService.getAllUsers().then((res) => {
      setUserList(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const registerUser = (model) => {
    setIsLoading(true);
    userService.register(model).then((res) => {
      getAllUsers();
      showAlert({ text: res.message, state: 'success' });
      closeRegisterModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const closeRegisterModal = () => {
    setRegisterModalVisible(false);
    registerUserFormik.resetForm();
    setSelectedRole(null);
  };

  const closePasswordModal = () => {
    setUpdatePasswordModel(initialUpdatePasswordModel);
    updatePasswordUserFormik.resetForm();
    setUpdatePasswordRegisterModalVisible(false);
  };

  const updateUserPassword = (model) => {
    setIsLoading(true);
    userService.changeUserPassword(model.id, model.password).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      closePasswordModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const editUser = (model) => {
    setIsLoading(true);
    userService.updateUser(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      closeEditModal();
      getAllUsers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
    setTimeout(() => {
      editUserFormik.resetForm();
      setEditUserModel(initialEditModel);
      setSelectedRole(null);
    }, 100);
  };

  const deleteUser = (userId) => {
    hideConfirmModal();
    setIsLoading(true);

    userService.deleteUser(userId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllUsers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        display: auth.accessLevel >= 50,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                getUserDetails(userList[dataIndex].id);
              }}
            />

            <Button
              text="Reset Password"
              className="secondary--bg ml-5"
              size="xxs"
              onClick={() => {
                setUpdatePasswordRegisterModalVisible(true);
                setUpdatePasswordModel({ ...updatePasswordModel, id: userList[dataIndex].id });
              }}
            />

            <Button
              text="Delete"
              className="danger--bg ml-5"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Delete User',
                  text: 'Are you sure you want to delete this user?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteUser(userList[dataIndex].id);
                    hideConfirmModal();
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: columnOptions,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: columnOptions,
    },
    {
      name: 'email',
      label: 'Email',
      options: columnOptions,
    },
    {
      name: 'role',
      label: 'Role',
      options: columnOptions,
    },
    {
      name: 'client',
      label: 'Client',
      options: columnOptions,
    },
    {
      name: 'contractor',
      label: 'Contractor',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is User Active?',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (<p>{userList[dataIndex].isActive ? 'Yes' : 'No'}</p>)
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}

      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Users"
                headerSize="lg"
                primaryButtonText={auth.accessLevel >= 50 ? 'Add New User' : ''}
                primaryButtonIconLeft={<IoPersonSharp className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  setRegisterModalVisible(true);
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={userList}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* REGISTER MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={registerModalVisible}
        size="lg"
        contentHeader="New User"
        primaryModalActionText="Add"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={registerUserFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={closeRegisterModal}
      >
        <FormBlock onSubmit={registerUserFormik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                label="First Name"
                isRequired
                errorMessage={registerUserFormik.errors.firstName}
                inputState={`${helper.getInputClasses(registerUserFormik, 'firstName')}`}
                {...registerUserFormik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Last Name"
                isRequired
                errorMessage={registerUserFormik.errors.lastName}
                inputState={`${helper.getInputClasses(registerUserFormik, 'lastName')}`}
                {...registerUserFormik.getFieldProps('lastName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <SelectBlock
                label="User Role"
                placeholder="Select a user role"
                id="User Role"
                name="User Role"
                options={roleOptions}
                isRequired
                value={selectedRole}
                onChange={(opt) => {
                  opt = opt === null ? [] : opt;
                  setSelectedRole(opt);
                  registerUserFormik.setFieldValue('roleId', opt.value);
                }}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Email Address"
                iconLeft={emailIcon}
                isRequired
                errorMessage={registerUserFormik.errors.email}
                inputState={`${helper.getInputClasses(registerUserFormik, 'email')}`}
                {...registerUserFormik.getFieldProps('email')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="Password"
                placeholder="Enter password"
                isRequired
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={registerUserFormik.errors.password}
                inputState={`${helper.getInputClasses(registerUserFormik, 'password')}`}
                {...registerUserFormik.getFieldProps('password')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm Password"
                placeholder="Enter password"
                isRequired
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={registerUserFormik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(registerUserFormik, 'confirmPassword')}`}
                {...registerUserFormik.getFieldProps('confirmPassword')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <CheckboxBlock
                label="Is user active?"
                id="isActive"
                {...registerUserFormik.getFieldProps('isActive')}
                isChecked={registerUserFormik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* RESET PASSWORD MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={updatePasswordModalVisible}
        size="lg"
        centered
        contentHeader="Reset User Password"
        primaryModalActionText="Reset"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={updatePasswordUserFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          setUpdatePasswordRegisterModalVisible(false);
        }}
      >
        <FormBlock onSubmit={updatePasswordUserFormik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="New Password"
                placeholder="Enter new password"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={updatePasswordUserFormik.errors.password}
                inputState={`${helper.getInputClasses(updatePasswordUserFormik, 'password')}`}
                {...updatePasswordUserFormik.getFieldProps('password')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm New Password"
                placeholder="Confirm new password"
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={updatePasswordUserFormik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(updatePasswordUserFormik, 'confirmPassword')}`}
                {...updatePasswordUserFormik.getFieldProps('confirmPassword')}
                isRequired
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* EDIT MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={editModalVisible}
        size="lg"
        contentHeader="Edit User"
        primaryModalActionText="Update"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={editUserFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          closeEditModal();
        }}
      >
        <FormBlock onSubmit={editUserFormik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                label="First Name"
                isRequired
                inputState={`${helper.getInputClasses(editUserFormik, 'firstName')}`}
                errorMessage={editUserFormik.errors.firstName}
                {...editUserFormik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Last Name"
                inputState={`${helper.getInputClasses(editUserFormik, 'lastName')}`}
                errorMessage={editUserFormik.errors.lastName}
                {...editUserFormik.getFieldProps('lastName')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <SelectBlock
                label="User Role"
                placeholder="Select a user role"
                options={roleOptions}
                isRequired
                value={selectedRole}
                onChange={(opt) => {
                  opt = opt === null ? [] : opt;
                  setSelectedRole(opt);
                  editUserFormik.setFieldValue('roleId', opt.value);
                }}
              />
            </ContentBlock>

            {/* <ContentBlock cols={6}>
              <InputBlock
                label="4-Digit Pin"
                iconLeft={pinCodeIcon}
                mask="9999"
                isRequired
                errorMessage={editUserFormik.errors.pin}
                inputState={`${helper.getInputClasses(editUserFormik, 'pin')}`}
                {...editUserFormik.getFieldProps('pin')}
              />
            </ContentBlock> */}

            <ContentBlock cols={6}>
              <InputBlock
                label="Email"
                errorMessage={editUserFormik.errors.email}
                inputState={`${helper.getInputClasses(editUserFormik, 'email')}`}
                {...editUserFormik.getFieldProps('email')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6} className="mt-50">
              <CheckboxBlock
                label="Is user active?"
                id="isActive"
                {...editUserFormik.getFieldProps('isActive')}
                isChecked={editUserFormik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModal.actions
})(UserListingPage);