/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { TbMail } from 'react-icons/tb';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import InputBlock from '../../../../../components/InputBlock';
import OverLay from '../../../../../components/Overlay';
// HELPERS AND SERVICES
import * as dailyFormNotificationEmailService from '../../../../../services/management/dailyFormNotificationEmailService';
import * as helper from '../../../../../helpers/helper';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as auth from '../../../../../redux/authRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';

const initialModel = {
  id: 0,
  email: '',
};

const DailyFormNotificationEmailListingPage = (props) => {
  const {
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(initialModel);

  useEffect(() => {
    getAllEmails();
  }, []);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const inputIconColor = 'grey--clr';
  // const pinCodeIcon = <IoIosKeypad size={iconSize} className={inputIconColor} />;
  const emailIcon = <TbMail size={iconSize} className={inputIconColor} />;

  const getAllEmails = () => {
    dailyFormNotificationEmailService.getAll().then((res) => {
      setEmails(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => { });
  };

  const formik = useFormik({
    initialValues: model,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      email: Yup.string().email('Please enter valid email').required('Email is required').max(250),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveNotificationEmail(values);
    },
  });

  const saveNotificationEmail = (model) => {
    setIsLoading(true);
    dailyFormNotificationEmailService.save(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      handleModalClose();
      getAllEmails();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeEmail = (id) => {
    setIsLoading(true);
    hideConfirmModal();
    dailyFormNotificationEmailService.deleteEmail(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllEmails();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        display: auth.accessLevel >= 50,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                setModel(emails[dataIndex]);
                setIsModalVisible(true);
              }}
            />
            {
              auth.accessLevel >= 50 && (

                <Button
                  text="Delete"
                  className="danger--bg ml-2"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Delete Email',
                      text: 'Are you sure you want to remove this email?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        removeEmail(emails[dataIndex].id);
                      }
                    });
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: columnOptions,
    },
  ];

  const handleModalClose = () => {
    formik.resetForm();
    setModel(initialModel);
    setIsModalVisible(false);
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}

      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Notification Emails"
                headerSize="lg"
                primaryButtonText={auth.accessLevel >= 50 ? 'Add New Email' : ''}
                primaryButtonIconLeft={<TbMail className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  setIsModalVisible(true);
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={emails}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      <ModalBlock
        hasCloseAction
        centered
        isVisible={isModalVisible}
        size="md"
        contentHeader={formik.values.id === 0 ? 'Add Email' : 'Update Email'}
        primaryModalActionText={formik.values.id === 0 ? 'Add' : 'Update'}
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={formik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={handleModalClose}
      >
        <FormBlock onSubmit={formik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={12}>
              <InputBlock
                label="Email Address"
                iconLeft={emailIcon}
                isRequired
                errorMessage={formik.errors.email}
                inputState={`${helper.getInputClasses(formik, 'email')}`}
                {...formik.getFieldProps('email')}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(DailyFormNotificationEmailListingPage);