/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import { Route, useHistory } from 'react-router-dom';
// ICONS
import {
  TbNotes,
  TbBuildingStore,
  TbTools,
  TbUsers,
  TbSettings,
  TbMailForward,
  TbMenu,
  TbX
} from 'react-icons/tb';
// CUSTOM COMPONENTS;
import DrawerNav from '../components/DrawerNav';
import NavBlock from '../components/NavBlock';
import CustomBlock from '../components/CustomBlock';
import TopNav from '../components/TopNav';
// ASSETS
import Icon from '../assets/img/icon.svg';
// REDUX
import * as auth from '../redux/authRedux';
import * as projectDetail from '../redux/projectDetailRedux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const history = useHistory();
  const iconSize = 24;
  const iconSizeLarge = 30;
  const iconColor = 'primary--clr';
  const mainPage = '/projects';

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigateToPath = (path) => {
    history.push(path);
    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 250);
  };

  const topNavMenuItems = [
    {
      id: 1,
      name: 'Projects',
      to: '/projects',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 0,
      iconLeft: <TbNotes className={iconColor} size={iconSize} />,
    },
    {
      id: 2,
      name: 'Clients',
      to: '/management/clients',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 40,
      iconLeft: <TbBuildingStore className={iconColor} size={iconSize} />,
    },
    {
      id: 3,
      name: 'Contractors',
      to: '/management/contractors',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 40,
      iconLeft: <TbTools className={iconColor} size={iconSize} />,
    },
    {
      id: 4,
      name: 'Users',
      to: '/management/users',
      action: null,
      submenuItems: null,
      isVisible: rest.auth.accessLevel >= 40,
      iconLeft: <TbUsers className={iconColor} size={iconSize} />,
    },
    {
      id: 5,
      name: 'Settings',
      to: '',
      action: null,
      isVisible: rest.auth.accessLevel >= 40,
      iconLeft: <TbSettings className={iconColor} size={iconSize} />,
      submenuItems: [
        {
          id: 1,
          name: 'Notification Emails',
          to: '/management/notification-emails',
          iconLeft: <TbMailForward size={22} className={iconColor} />
        },
      ]
    },
  ];

  return (
    <Route
      {...rest} render={(props) => (
        <>
          <TopNav
            hasReturnAction
            hasReturnActionBackground
            hasNavigation
            hasHeaderTitle
            icon={Icon}
            iconTo={mainPage}
            headerTitle={rest.title}
            routePath={rest.path}
            userInitials={`${rest.auth.user.firstName[0]}${rest.auth.user.lastName[0]}`}
            menuItems={topNavMenuItems.filter((x) => x.isVisible)}
            menuToggleIcon={
              isDrawerOpen
                ? (
                  <TbX
                    size={iconSizeLarge} className="danger--clr"
                    strokeWidth="3"
                  />
                ) : (
                  <TbMenu
                    size={iconSizeLarge} className="dark-blue--clr"
                    strokeWidth="3"
                  />
                )
            }
            secondaryIconActionOnClick={() => {
              rest.resetProjectDetails();
              rest.logout();
            }}
            onClickAvatar={() => {
              history.push('/my-profile');
            }}
            onClick={() => {
              toggleDrawer();
            }}
          />

          <DrawerNav
            isOpen={isDrawerOpen}
            onClick={() => {
              setIsDrawerOpen(false);
            }}
            onClickLogout={() => {
              rest.resetProjectDetails();
              rest.logout();
            }}
            navigationItems={
              topNavMenuItems.map((item, index) => (
                <NavBlock
                  tooltipText={item.name}
                  key={index}
                  title={item.name}
                  iconLeft={item.iconLeft}
                  titleColor={item.to === rest.path ? 'info--clr' : 'dark-blue--clr'}
                  backgroundColor={item.to === rest.path ? 'lighter-blue--sbg' : 'white--sbg'}
                  onClick={() => {
                    navigateToPath(item.subMenuItems == null ? item.to : item.subMenuItems.to);
                  }}
                />
              ))
            }
          />

          <CustomBlock className="content-wrapper">
            <CustomBlock className="component-wrapper">
              <Component {...props} />
            </CustomBlock>
          </CustomBlock>
        </>
      )}
    />
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...projectDetail.actions })(PrivateRoute);