/* eslint-disable default-param-last */
/* eslint-disable no-console */
/* eslint-disable max-len */
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SetProjectDetails: 'SET_PROJECT_DETAILS',
  ResetProjectDetails: 'RESET_PROJECT_DETAILS'
};

const initialProjectDetails = { projectName: '', projectId: 0, isCompleted: false };

export const reducer = persistReducer(
  { storage, key: 'project-details', whitelist: ['projectName', 'projectId', 'isCompleted'] },
  (state = initialProjectDetails, action) => {
    switch (action.type) {
      case actionTypes.SetProjectDetails: {
        return {
          projectName: action.payload.projectName,
          projectId: action.payload.projectId,
          isCompleted: action.payload.isCompleted
        };
      }

      case actionTypes.ResetProjectDetails: {
        return initialProjectDetails;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setProjectDetails: (model) => ({ type: actionTypes.SetProjectDetails, payload: model }),
  resetProjectDetails: () => ({ type: actionTypes.ResetProjectDetails })
};