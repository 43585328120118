/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// COMPONENTS
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as projectDetailRedux from '../../../../redux/projectDetailRedux';
// HELPERS & SERVICES
import * as projectService from '../../../../services/project/projectService';

const ProjectAccessPage = (props) => {
  const { showAlert } = props;
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dataAdmins, setDataAdmins] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [contractorUsers, setContractorUsers] = useState([]);

  useEffect(() => {
    fetchProjectUsers();
  }, []);

  const fetchProjectUsers = () => {
    setIsLoading(true);

    projectService.getUsersForProjectAccess(projectId).then((res) => {
      setClientUsers(res.clientUsers);
      setContractorUsers(res.contractorUsers);
      setDataAdmins(res.dataAdmins);
    }).catch((ex) => {
      showAlert({ Text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleDataAdminChecked = (userId, isChecked) => {
    const tempDataAdminUsers = [...dataAdmins];
    const user = tempDataAdminUsers.find((x) => x.userId === userId);
    user.isChecked = isChecked;

    setDataAdmins(tempDataAdminUsers);
    toggleAssignment(userId, isChecked);
  };

  const handleClientChecked = (userId, isChecked) => {
    const tempClientUsers = [...clientUsers];
    const user = tempClientUsers.find((x) => x.userId === userId);
    user.isChecked = isChecked;

    setClientUsers(tempClientUsers);
    toggleAssignment(userId, isChecked);
  };

  const handleContractorChecked = (contractorName, userId, isChecked) => {
    const tempContractorUsers = [...contractorUsers];
    const contractor = tempContractorUsers.find((x) => x.contractor === contractorName);
    const user = contractor.users.find((x) => x.userId === userId);
    user.isChecked = isChecked;

    setContractorUsers(tempContractorUsers);
    toggleAssignment(userId, isChecked);
  };

  const toggleAssignment = (userId, isChecked) => {
    if (isChecked) {
      projectService.assignProjectUsers(projectId, userId).then(() => {

      }).catch((ex) => {
        showAlert({ Text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      projectService.removeUserAssignment(projectId, userId).then(() => {

      }).catch((ex) => {
        showAlert({ Text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Project Access"
                headerSize="lg"
              />
              <CustomBlock>
                <Accordion
                  defaultActiveKey="0"
                  flush
                  alwaysOpen
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Data Admins</Accordion.Header>
                    <Accordion.Body>
                      <ContentBlock cols={12}>
                        <Table
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <tr>
                              <th style={{ width: '75px' }}>Access</th>
                              <th>Name</th>
                              <th>Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              dataAdmins.map((user) => (
                                <tr key={user.id}>
                                  <td>
                                    <input
                                      checked={user.isChecked}
                                      type="checkbox"
                                      style={{ display: 'block', width: '50px', height: '25px' }}
                                      onChange={({ target }) => handleDataAdminChecked(user.userId, target.checked)}
                                    />
                                  </td>
                                  <td>{user.name}</td>
                                  <td>{user.role}</td>
                                </tr>
                              ))
                            }

                          </tbody>
                        </Table>
                      </ContentBlock>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Client Users</Accordion.Header>
                    <Accordion.Body>
                      <ContentBlock cols={12}>
                        <Table
                          striped
                          bordered
                          hover
                        >
                          <thead>
                            <tr>
                              <th style={{ width: '75px' }}>Access</th>
                              <th>Name</th>
                              <th>Role</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              clientUsers.map((client) => (
                                <tr key={client.id}>
                                  <td>
                                    <input
                                      checked={client.isChecked}
                                      type="checkbox"
                                      style={{ display: 'block', width: '50px', height: '25px' }}
                                      onChange={({ target }) => handleClientChecked(client.userId, target.checked)}
                                    />
                                  </td>
                                  <td>{client.name}</td>
                                  <td>{client.role}</td>
                                </tr>
                              ))
                            }

                          </tbody>
                        </Table>
                      </ContentBlock>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Contractor Users</Accordion.Header>
                    <Accordion.Body>
                      <ContentBlock cols={12}>
                        {
                          contractorUsers.map((contractor) => (
                            <Table
                              key={contractor.contractor}
                              striped
                              bordered
                              hover
                            >
                              <thead>
                                <tr>
                                  <th colSpan={3}>{contractor.contractor}</th>
                                </tr>
                              </thead>
                              <thead>
                                <tr>
                                  <th style={{ width: '75px' }}>Access</th>
                                  <th>Name</th>
                                  <th>Role</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  contractor.users.map((user) => (
                                    <tr key={user.userId}>
                                      <td>
                                        <input
                                          checked={user.isChecked}
                                          type="checkbox"
                                          style={{ display: 'block', width: '50px', height: '25px' }}
                                          onChange={({ target }) => handleContractorChecked(contractor.contractor, user.userId, target.checked)}
                                        />
                                      </td>
                                      <td>{user.name}</td>
                                      <td>{user.role}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                          ))
                        }
                      </ContentBlock>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({
  auth: state.auth,
  projectDetail: state.projectDetail
});

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions,
    ...projectDetailRedux.actions
  }
)(ProjectAccessPage);