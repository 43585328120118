/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
// COMPONENTS
import Table from 'react-bootstrap/Table';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import OverLay from '../../../../components/Overlay';
import SelectBlock from '../../../../components/SelectBlock';
import WeldLogReportTable from './WeldLogReportTable';
// SERVICES
import * as weldLogService from '../../../../services/project/weldLogService';
import * as testPackageLogService from '../../../../services/project/testPackageLogService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const WeldLogPage = (props) => {
  const { showAlert } = props;
  const { projectId, testPackage, contractor } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState(null);

  useEffect(() => {
    if (contractor) {
      setSelectedContractor({ label: decodeURIComponent(contractor), value: decodeURIComponent(contractor) });
    } else {
      setSelectedContractor({ label: 'All', value: null });
    }
    getContractors();
  }, []);

  useEffect(() => {
    if (selectedContractor) {
      getWeldLogReport(selectedContractor.value);
    }
  }, [selectedContractor]);

  const getContractors = () => {
    setIsLoading(true);

    const contractorPromise = testPackage ? testPackageLogService.getContractorsByTp(projectId, testPackage) : testPackageLogService.getContractors(projectId);
    contractorPromise.then((res) => {
      setContractors([{ label: 'All', value: null }, ...res]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getWeldLogReport = (contractorValue) => {
    setIsLoading(true);
    const reportPromise = testPackage ? weldLogService.getReportData(projectId, testPackage, contractorValue || '') : weldLogService.getCompositeReportData(projectId, contractorValue || '');
    reportPromise.then((res) => {
      setData(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title={testPackage ? `Weld Log Report: ${testPackage}` : 'Composite Weld Log Report (All Test Packages)'}
                headerSize="lg"
              />
              <ContentBlock cols={3} className="mb-20">
                <SelectBlock
                  label="Contractor"
                  placeholder="Select a Contractor"
                  id="show-project"
                  name="show-project"
                  options={contractors}
                  isRequired
                  value={selectedContractor}
                  onChange={(opt) => {
                    setSelectedContractor(opt);
                  }}
                />
              </ContentBlock>
              <Section hasNoContainer>
                <ContentBlock cols={2}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Total Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.length}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>

                <ContentBlock cols={5}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Total Shop Welds</th>
                        <th>Total Field Welds</th>
                        <th>Total Tie-in Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.filter((x) => x.weldLocation === 'Shop Weld').length}</td>
                        <td>{data.filter((x) => x.weldLocation === 'Field Weld').length}</td>
                        <td>{data.filter((x) => x.weldLocation === 'Tie-in Weld').length}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>

                <ContentBlock cols={5}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Total Completed  Welds</th>
                        <th>Completed Shop Welds</th>
                        <th>Completed Field Welds</th>
                        <th>Completed Tie-in Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.filter((x) => x && x.vtBy && x.vtDate).length}</td>
                        <td>{data.filter((x) => x.weldLocation === 'Shop Weld' && x.vtBy && x.vtDate).length}</td>
                        <td>{data.filter((x) => x.weldLocation === 'Field Weld' && x.vtBy && x.vtDate).length}</td>
                        <td>{data.filter((x) => x.weldLocation === 'Tie-in Weld' && x.vtBy && x.vtDate).length}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>
              </Section>
              <Section hasNoContainer>
                <ContentBlock cols={3}>
                  <Table
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th colSpan={4} style={{ textAlign: 'center' }}>VT</th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>%</th>
                        <th>SW</th>
                        <th>FW</th>
                        <th>TW</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>100%</td>
                        <td>
                          {data.filter((x) => x.weldLocation === 'Shop Weld').length}
                          /
                          {data.filter((x) => x.weldLocation === 'Shop Weld' && x.vtDate).length}
                        </td>
                        <td>
                          {data.filter((x) => x.weldLocation === 'Field Weld').length}
                          /
                          {data.filter((x) => x.weldLocation === 'Field Weld' && x.vtDate).length}
                        </td>
                        <td>
                          {data.filter((x) => x.weldLocation === 'Tie-in Weld').length}
                          /
                          {data.filter((x) => x.weldLocation === 'Tie-in Weld' && x.vtDate).length}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {/* <Table
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th colSpan={4} style={{ textAlign: 'center' }}>VT Rejects</th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>%</th>
                        <th>SW</th>
                        <th>FW</th>
                        <th>TW</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>100%</td>
                        <td>
                          {data.filter((x) => x.weldLocation === 'Shop Weld' && x.accRej === 'REJ.').length}
                        </td>
                        <td>
                          {data.filter((x) => x.weldLocation === 'Field Weld' && x.accRej === 'REJ.').length}
                        </td>
                        <td>
                          {data.filter((x) => x.weldLocation === 'Tie-in Weld' && x.accRej === 'REJ.').length}
                        </td>
                      </tr>
                    </tbody>
                  </Table> */}
                </ContentBlock>

                <ContentBlock cols={3}>
                  <WeldLogReportTable
                    tableName="XRAY UT"
                    fivePercentCompleteSw={data.filter((x) => x.xrayUtPercentage === '5%' && x.weldLocation === 'Shop Weld').length}
                    fivePercentInCompleteSw={data.filter((x) => x.xrayUtPercentage === '5%' && x.rtUtDate && x.weldLocation === 'Shop Weld').length}
                    fivePercentCompleteFw={data.filter((x) => x.xrayUtPercentage === '5%' && x.weldLocation === 'Field Weld').length}
                    fivePercentInCompleteFw={data.filter((x) => x.xrayUtPercentage === '5%' && x.rtUtDate && x.weldLocation === 'Field Weld').length}
                    fivePercentCompleteTw={data.filter((x) => x.xrayUtPercentage === '5%' && x.weldLocation === 'Tie-in Weld').length}
                    fivePercentInCompleteTw={data.filter((x) => x.xrayUtPercentage === '5%' && x.rtUtDate && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentCompleteSw={data.filter((x) => x.xrayUtPercentage === '10%' && x.weldLocation === 'Shop Weld').length}
                    tenPercentInCompleteSw={data.filter((x) => x.xrayUtPercentage === '10%' && x.rtUtDate && x.weldLocation === 'Shop Weld').length}
                    tenPercentCompleteFw={data.filter((x) => x.xrayUtPercentage === '10%' && x.weldLocation === 'Field Weld').length}
                    tenPercentInCompleteFw={data.filter((x) => x.xrayUtPercentage === '10%' && x.rtUtDate && x.weldLocation === 'Field Weld').length}
                    tenPercentCompleteTw={data.filter((x) => x.xrayUtPercentage === '10%' && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentInCompleteTw={data.filter((x) => x.xrayUtPercentage === '10%' && x.rtUtDate && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentCompleteSw={data.filter((x) => x.xrayUtPercentage === '20%' && x.weldLocation === 'Shop Weld').length}
                    twentyPercentInCompleteSw={data.filter((x) => x.xrayUtPercentage === '20%' && x.rtUtDate && x.weldLocation === 'Shop Weld').length}
                    twentyPercentCompleteFw={data.filter((x) => x.xrayUtPercentage === '20%' && x.weldLocation === 'Field Weld').length}
                    twentyPercentInCompleteFw={data.filter((x) => x.xrayUtPercentage === '20%' && x.rtUtDate && x.weldLocation === 'Field Weld').length}
                    twentyPercentCompleteTw={data.filter((x) => x.xrayUtPercentage === '20%' && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentInCompleteTw={data.filter((x) => x.xrayUtPercentage === '20%' && x.rtUtDate && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentCompleteSw={data.filter((x) => x.xrayUtPercentage === '100%' && x.weldLocation === 'Shop Weld').length}
                    hundredPercentInCompleteSw={data.filter((x) => x.xrayUtPercentage === '100%' && x.rtUtDate && x.weldLocation === 'Shop Weld').length}
                    hundredPercentCompleteFw={data.filter((x) => x.xrayUtPercentage === '100%' && x.weldLocation === 'Field Weld').length}
                    hundredPercentInCompleteFw={data.filter((x) => x.xrayUtPercentage === '100%' && x.rtUtDate && x.weldLocation === 'Field Weld').length}
                    hundredPercentCompleteTw={data.filter((x) => x.xrayUtPercentage === '100%' && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentInCompleteTw={data.filter((x) => x.xrayUtPercentage === '100%' && x.rtUtDate && x.weldLocation === 'Tie-in Weld').length}
                    naSwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.xrayUtPercentage) && x.weldLocation === 'Shop Weld').length}
                    naFwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.xrayUtPercentage) && x.weldLocation === 'Field Weld').length}
                    naTwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.xrayUtPercentage) && x.weldLocation === 'Tie-in Weld').length}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <WeldLogReportTable
                    tableName="PT/MT"
                    fivePercentCompleteSw={data.filter((x) => x.ptMtPercentage === '5%' && x.weldLocation === 'Shop Weld').length}
                    fivePercentInCompleteSw={data.filter((x) => x.ptMtPercentage === '5%' && x.ptMtDate && x.weldLocation === 'Shop Weld').length}
                    fivePercentCompleteFw={data.filter((x) => x.ptMtPercentage === '5%' && x.weldLocation === 'Field Weld').length}
                    fivePercentInCompleteFw={data.filter((x) => x.ptMtPercentage === '5%' && x.ptMtDate && x.weldLocation === 'Field Weld').length}
                    fivePercentCompleteTw={data.filter((x) => x.ptMtPercentage === '5%' && x.weldLocation === 'Tie-in Weld').length}
                    fivePercentInCompleteTw={data.filter((x) => x.ptMtPercentage === '5%' && x.ptMtDate && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentCompleteSw={data.filter((x) => x.ptMtPercentage === '10%' && x.weldLocation === 'Shop Weld').length}
                    tenPercentInCompleteSw={data.filter((x) => x.ptMtPercentage === '10%' && x.ptMtDate && x.weldLocation === 'Shop Weld').length}
                    tenPercentCompleteFw={data.filter((x) => x.ptMtPercentage === '10%' && x.weldLocation === 'Field Weld').length}
                    tenPercentInCompleteFw={data.filter((x) => x.ptMtPercentage === '10%' && x.ptMtDate && x.weldLocation === 'Field Weld').length}
                    tenPercentCompleteTw={data.filter((x) => x.ptMtPercentage === '10%' && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentInCompleteTw={data.filter((x) => x.ptMtPercentage === '10%' && x.ptMtDate && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentCompleteSw={data.filter((x) => x.ptMtPercentage === '20%' && x.weldLocation === 'Shop Weld').length}
                    twentyPercentInCompleteSw={data.filter((x) => x.ptMtPercentage === '20%' && x.ptMtDate && x.weldLocation === 'Shop Weld').length}
                    twentyPercentCompleteFw={data.filter((x) => x.ptMtPercentage === '20%' && x.weldLocation === 'Field Weld').length}
                    twentyPercentInCompleteFw={data.filter((x) => x.ptMtPercentage === '20%' && x.ptMtDate && x.weldLocation === 'Field Weld').length}
                    twentyPercentCompleteTw={data.filter((x) => x.ptMtPercentage === '20%' && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentInCompleteTw={data.filter((x) => x.ptMtPercentage === '20%' && x.ptMtDate && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentCompleteSw={data.filter((x) => x.ptMtPercentage === '100%' && x.weldLocation === 'Shop Weld').length}
                    hundredPercentInCompleteSw={data.filter((x) => x.ptMtPercentage === '100%' && x.ptMtDate && x.weldLocation === 'Shop Weld').length}
                    hundredPercentCompleteFw={data.filter((x) => x.ptMtPercentage === '100%' && x.weldLocation === 'Field Weld').length}
                    hundredPercentInCompleteFw={data.filter((x) => x.ptMtPercentage === '100%' && x.ptMtDate && x.weldLocation === 'Field Weld').length}
                    hundredPercentCompleteTw={data.filter((x) => x.ptMtPercentage === '100%' && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentInCompleteTw={data.filter((x) => x.ptMtPercentage === '100%' && x.ptMtDate && x.weldLocation === 'Tie-in Weld').length}
                    naSwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.ptMtPercentage) && x.weldLocation === 'Shop Weld').length}
                    naFwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.ptMtPercentage) && x.weldLocation === 'Field Weld').length}
                    naTwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.ptMtPercentage) && x.weldLocation === 'Tie-in Weld').length}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <WeldLogReportTable
                    tableName="PMI"
                    fivePercentCompleteSw={data.filter((x) => x.pmiPercentage === '5%' && x.weldLocation === 'Shop Weld').length}
                    fivePercentInCompleteSw={data.filter((x) => x.pmiPercentage === '5%' && x.pmiDate && x.weldLocation === 'Shop Weld').length}
                    fivePercentCompleteFw={data.filter((x) => x.pmiPercentage === '5%' && x.weldLocation === 'Field Weld').length}
                    fivePercentInCompleteFw={data.filter((x) => x.pmiPercentage === '5%' && x.pmiDate && x.weldLocation === 'Field Weld').length}
                    fivePercentCompleteTw={data.filter((x) => x.pmiPercentage === '5%' && x.weldLocation === 'Tie-in Weld').length}
                    fivePercentInCompleteTw={data.filter((x) => x.pmiPercentage === '5%' && x.pmiDate && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentCompleteSw={data.filter((x) => x.pmiPercentage === '10%' && x.weldLocation === 'Shop Weld').length}
                    tenPercentInCompleteSw={data.filter((x) => x.pmiPercentage === '10%' && x.pmiDate && x.weldLocation === 'Shop Weld').length}
                    tenPercentCompleteFw={data.filter((x) => x.pmiPercentage === '10%' && x.weldLocation === 'Field Weld').length}
                    tenPercentInCompleteFw={data.filter((x) => x.pmiPercentage === '10%' && x.pmiDate && x.weldLocation === 'Field Weld').length}
                    tenPercentCompleteTw={data.filter((x) => x.pmiPercentage === '10%' && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentInCompleteTw={data.filter((x) => x.pmiPercentage === '10%' && x.pmiDate && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentCompleteSw={data.filter((x) => x.pmiPercentage === '20%' && x.weldLocation === 'Shop Weld').length}
                    twentyPercentInCompleteSw={data.filter((x) => x.pmiPercentage === '20%' && x.pmiDate && x.weldLocation === 'Shop Weld').length}
                    twentyPercentCompleteFw={data.filter((x) => x.pmiPercentage === '20%' && x.weldLocation === 'Field Weld').length}
                    twentyPercentInCompleteFw={data.filter((x) => x.pmiPercentage === '20%' && x.pmiDate && x.weldLocation === 'Field Weld').length}
                    twentyPercentCompleteTw={data.filter((x) => x.pmiPercentage === '20%' && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentInCompleteTw={data.filter((x) => x.pmiPercentage === '20%' && x.pmiDate && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentCompleteSw={data.filter((x) => x.pmiPercentage === '100%' && x.weldLocation === 'Shop Weld').length}
                    hundredPercentInCompleteSw={data.filter((x) => x.pmiPercentage === '100%' && x.pmiDate && x.weldLocation === 'Shop Weld').length}
                    hundredPercentCompleteFw={data.filter((x) => x.pmiPercentage === '100%' && x.weldLocation === 'Field Weld').length}
                    hundredPercentInCompleteFw={data.filter((x) => x.pmiPercentage === '100%' && x.pmiDate && x.weldLocation === 'Field Weld').length}
                    hundredPercentCompleteTw={data.filter((x) => x.pmiPercentage === '100%' && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentInCompleteTw={data.filter((x) => x.pmiPercentage === '100%' && x.pmiDate && x.weldLocation === 'Tie-in Weld').length}
                    naSwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.pmiPercentage) && x.weldLocation === 'Shop Weld').length}
                    naFwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.pmiPercentage) && x.weldLocation === 'Field Weld').length}
                    naTwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.pmiPercentage) && x.weldLocation === 'Tie-in Weld').length}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <WeldLogReportTable
                    tableName="PWHT"
                    fivePercentCompleteSw={data.filter((x) => x.pwhtPercentage === '5%' && x.weldLocation === 'Shop Weld').length}
                    fivePercentInCompleteSw={data.filter((x) => x.pwhtPercentage === '5%' && x.pwhtDate && x.weldLocation === 'Shop Weld').length}
                    fivePercentCompleteFw={data.filter((x) => x.pwhtPercentage === '5%' && x.weldLocation === 'Field Weld').length}
                    fivePercentInCompleteFw={data.filter((x) => x.pwhtPercentage === '5%' && x.pwhtDate && x.weldLocation === 'Field Weld').length}
                    fivePercentCompleteTw={data.filter((x) => x.pwhtPercentage === '5%' && x.weldLocation === 'Tie-in Weld').length}
                    fivePercentInCompleteTw={data.filter((x) => x.pwhtPercentage === '5%' && x.pwhtDate && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentCompleteSw={data.filter((x) => x.pwhtPercentage === '10%' && x.weldLocation === 'Shop Weld').length}
                    tenPercentInCompleteSw={data.filter((x) => x.pwhtPercentage === '10%' && x.pwhtDate && x.weldLocation === 'Shop Weld').length}
                    tenPercentCompleteFw={data.filter((x) => x.pwhtPercentage === '10%' && x.weldLocation === 'Field Weld').length}
                    tenPercentInCompleteFw={data.filter((x) => x.pwhtPercentage === '10%' && x.pwhtDate && x.weldLocation === 'Field Weld').length}
                    tenPercentCompleteTw={data.filter((x) => x.pwhtPercentage === '10%' && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentInCompleteTw={data.filter((x) => x.pwhtPercentage === '10%' && x.pwhtDate && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentCompleteSw={data.filter((x) => x.pwhtPercentage === '20%' && x.weldLocation === 'Shop Weld').length}
                    twentyPercentInCompleteSw={data.filter((x) => x.pwhtPercentage === '20%' && x.pwhtDate && x.weldLocation === 'Shop Weld').length}
                    twentyPercentCompleteFw={data.filter((x) => x.pwhtPercentage === '20%' && x.weldLocation === 'Field Weld').length}
                    twentyPercentInCompleteFw={data.filter((x) => x.pwhtPercentage === '20%' && x.pwhtDate && x.weldLocation === 'Field Weld').length}
                    twentyPercentCompleteTw={data.filter((x) => x.pwhtPercentage === '20%' && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentInCompleteTw={data.filter((x) => x.pwhtPercentage === '20%' && x.pwhtDate && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentCompleteSw={data.filter((x) => x.pwhtPercentage === '100%' && x.weldLocation === 'Shop Weld').length}
                    hundredPercentInCompleteSw={data.filter((x) => x.pwhtPercentage === '100%' && x.pwhtDate && x.weldLocation === 'Shop Weld').length}
                    hundredPercentCompleteFw={data.filter((x) => x.pwhtPercentage === '100%' && x.weldLocation === 'Field Weld').length}
                    hundredPercentInCompleteFw={data.filter((x) => x.pwhtPercentage === '100%' && x.pwhtDate && x.weldLocation === 'Field Weld').length}
                    hundredPercentCompleteTw={data.filter((x) => x.pwhtPercentage === '100%' && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentInCompleteTw={data.filter((x) => x.pwhtPercentage === '100%' && x.pwhtDate && x.weldLocation === 'Tie-in Weld').length}
                    naSwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.pwhtPercentage) && x.weldLocation === 'Shop Weld').length}
                    naFwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.pwhtPercentage) && x.weldLocation === 'Field Weld').length}
                    naTwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.pwhtPercentage) && x.weldLocation === 'Tie-in Weld').length}
                  />
                </ContentBlock>

                {/* <ContentBlock cols={3}>
                  <Table
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th colSpan={4} style={{ textAlign: 'center' }}>XRAY UT Rejects</th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>%</th>
                        <th>SW</th>
                        <th>FW</th>
                        <th>TW</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ['5%', '10%', '20%', '100%'].map((percent) => (
                          <tr>
                            <td>{percent}</td>
                            <td>
                              {data.filter((x) => x.xrayUtPercentage === percent && x.weldLocation === 'Shop Weld' && x.weldAcceptedRejected === 'REJ.').length}
                            </td>
                            <td>
                              {data.filter((x) => x.xrayUtPercentage === percent && x.weldLocation === 'Field Weld' && x.weldAcceptedRejected === 'REJ.').length}
                            </td>
                            <td>
                              {data.filter((x) => x.xrayUtPercentage === percent && x.weldLocation === 'Tie-in Weld' && x.weldAcceptedRejected === 'REJ.').length}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </ContentBlock>
                 */}
                <ContentBlock cols={3}>
                  <WeldLogReportTable
                    tableName="RT/UT Before Stress"
                    fivePercentCompleteSw={data.filter((x) => x.afterStressPercentage === '5%' && x.weldLocation === 'Shop Weld').length}
                    fivePercentInCompleteSw={data.filter((x) => x.afterStressPercentage === '5%' && x.afterStressDate && x.weldLocation === 'Shop Weld').length}
                    fivePercentCompleteFw={data.filter((x) => x.afterStressPercentage === '5%' && x.weldLocation === 'Field Weld').length}
                    fivePercentInCompleteFw={data.filter((x) => x.afterStressPercentage === '5%' && x.afterStressDate && x.weldLocation === 'Field Weld').length}
                    fivePercentCompleteTw={data.filter((x) => x.afterStressPercentage === '5%' && x.weldLocation === 'Tie-in Weld').length}
                    fivePercentInCompleteTw={data.filter((x) => x.afterStressPercentage === '5%' && x.afterStressDate && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentCompleteSw={data.filter((x) => x.afterStressPercentage === '10%' && x.weldLocation === 'Shop Weld').length}
                    tenPercentInCompleteSw={data.filter((x) => x.afterStressPercentage === '10%' && x.afterStressDate && x.weldLocation === 'Shop Weld').length}
                    tenPercentCompleteFw={data.filter((x) => x.afterStressPercentage === '10%' && x.weldLocation === 'Field Weld').length}
                    tenPercentInCompleteFw={data.filter((x) => x.afterStressPercentage === '10%' && x.afterStressDate && x.weldLocation === 'Field Weld').length}
                    tenPercentCompleteTw={data.filter((x) => x.afterStressPercentage === '10%' && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentInCompleteTw={data.filter((x) => x.afterStressPercentage === '10%' && x.afterStressDate && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentCompleteSw={data.filter((x) => x.afterStressPercentage === '20%' && x.weldLocation === 'Shop Weld').length}
                    twentyPercentInCompleteSw={data.filter((x) => x.afterStressPercentage === '20%' && x.afterStressDate && x.weldLocation === 'Shop Weld').length}
                    twentyPercentCompleteFw={data.filter((x) => x.afterStressPercentage === '20%' && x.weldLocation === 'Field Weld').length}
                    twentyPercentInCompleteFw={data.filter((x) => x.afterStressPercentage === '20%' && x.afterStressDate && x.weldLocation === 'Field Weld').length}
                    twentyPercentCompleteTw={data.filter((x) => x.afterStressPercentage === '20%' && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentInCompleteTw={data.filter((x) => x.afterStressPercentage === '20%' && x.afterStressDate && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentCompleteSw={data.filter((x) => x.afterStressPercentage === '100%' && x.weldLocation === 'Shop Weld').length}
                    hundredPercentInCompleteSw={data.filter((x) => x.afterStressPercentage === '100%' && x.afterStressDate && x.weldLocation === 'Shop Weld').length}
                    hundredPercentCompleteFw={data.filter((x) => x.afterStressPercentage === '100%' && x.weldLocation === 'Field Weld').length}
                    hundredPercentInCompleteFw={data.filter((x) => x.afterStressPercentage === '100%' && x.afterStressDate && x.weldLocation === 'Field Weld').length}
                    hundredPercentCompleteTw={data.filter((x) => x.afterStressPercentage === '100%' && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentInCompleteTw={data.filter((x) => x.afterStressPercentage === '100%' && x.afterStressDate && x.weldLocation === 'Tie-in Weld').length}
                    naSwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.afterStressPercentage) && x.weldLocation === 'Shop Weld').length}
                    naFwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.afterStressPercentage) && x.weldLocation === 'Field Weld').length}
                    naTwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.afterStressPercentage) && x.weldLocation === 'Tie-in Weld').length}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <WeldLogReportTable
                    tableName="RT/UT After Stress"
                    fivePercentCompleteSw={data.filter((x) => x.rtUtAsPercentage === '5%' && x.weldLocation === 'Shop Weld').length}
                    fivePercentInCompleteSw={data.filter((x) => x.rtUtAsPercentage === '5%' && x.rtUtAsDate && x.weldLocation === 'Shop Weld').length}
                    fivePercentCompleteFw={data.filter((x) => x.rtUtAsPercentage === '5%' && x.weldLocation === 'Field Weld').length}
                    fivePercentInCompleteFw={data.filter((x) => x.rtUtAsPercentage === '5%' && x.rtUtAsDate && x.weldLocation === 'Field Weld').length}
                    fivePercentCompleteTw={data.filter((x) => x.rtUtAsPercentage === '5%' && x.weldLocation === 'Tie-in Weld').length}
                    fivePercentInCompleteTw={data.filter((x) => x.rtUtAsPercentage === '5%' && x.rtUtAsDate && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentCompleteSw={data.filter((x) => x.rtUtAsPercentage === '10%' && x.weldLocation === 'Shop Weld').length}
                    tenPercentInCompleteSw={data.filter((x) => x.rtUtAsPercentage === '10%' && x.rtUtAsDate && x.weldLocation === 'Shop Weld').length}
                    tenPercentCompleteFw={data.filter((x) => x.rtUtAsPercentage === '10%' && x.weldLocation === 'Field Weld').length}
                    tenPercentInCompleteFw={data.filter((x) => x.rtUtAsPercentage === '10%' && x.rtUtAsDate && x.weldLocation === 'Field Weld').length}
                    tenPercentCompleteTw={data.filter((x) => x.rtUtAsPercentage === '10%' && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentInCompleteTw={data.filter((x) => x.rtUtAsPercentage === '10%' && x.rtUtAsDate && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentCompleteSw={data.filter((x) => x.rtUtAsPercentage === '20%' && x.weldLocation === 'Shop Weld').length}
                    twentyPercentInCompleteSw={data.filter((x) => x.rtUtAsPercentage === '20%' && x.rtUtAsDate && x.weldLocation === 'Shop Weld').length}
                    twentyPercentCompleteFw={data.filter((x) => x.rtUtAsPercentage === '20%' && x.weldLocation === 'Field Weld').length}
                    twentyPercentInCompleteFw={data.filter((x) => x.rtUtAsPercentage === '20%' && x.rtUtAsDate && x.weldLocation === 'Field Weld').length}
                    twentyPercentCompleteTw={data.filter((x) => x.rtUtAsPercentage === '20%' && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentInCompleteTw={data.filter((x) => x.rtUtAsPercentage === '20%' && x.rtUtAsDate && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentCompleteSw={data.filter((x) => x.rtUtAsPercentage === '100%' && x.weldLocation === 'Shop Weld').length}
                    hundredPercentInCompleteSw={data.filter((x) => x.rtUtAsPercentage === '100%' && x.rtUtAsDate && x.weldLocation === 'Shop Weld').length}
                    hundredPercentCompleteFw={data.filter((x) => x.rtUtAsPercentage === '100%' && x.weldLocation === 'Field Weld').length}
                    hundredPercentInCompleteFw={data.filter((x) => x.rtUtAsPercentage === '100%' && x.rtUtAsDate && x.weldLocation === 'Field Weld').length}
                    hundredPercentCompleteTw={data.filter((x) => x.rtUtAsPercentage === '100%' && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentInCompleteTw={data.filter((x) => x.rtUtAsPercentage === '100%' && x.rtUtAsDate && x.weldLocation === 'Tie-in Weld').length}
                    naSwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.rtUtAsPercentage) && x.weldLocation === 'Shop Weld').length}
                    naFwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.rtUtAsPercentage) && x.weldLocation === 'Field Weld').length}
                    naTwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.rtUtAsPercentage) && x.weldLocation === 'Tie-in Weld').length}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <WeldLogReportTable
                    tableName="BHN"
                    fivePercentCompleteSw={data.filter((x) => x.bhnPercentage === '5%' && x.weldLocation === 'Shop Weld').length}
                    fivePercentInCompleteSw={data.filter((x) => x.bhnPercentage === '5%' && x.bhnDate && x.weldLocation === 'Shop Weld').length}
                    fivePercentCompleteFw={data.filter((x) => x.bhnPercentage === '5%' && x.weldLocation === 'Field Weld').length}
                    fivePercentInCompleteFw={data.filter((x) => x.bhnPercentage === '5%' && x.bhnDate && x.weldLocation === 'Field Weld').length}
                    fivePercentCompleteTw={data.filter((x) => x.bhnPercentage === '5%' && x.weldLocation === 'Tie-in Weld').length}
                    fivePercentInCompleteTw={data.filter((x) => x.bhnPercentage === '5%' && x.bhnDate && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentCompleteSw={data.filter((x) => x.bhnPercentage === '10%' && x.weldLocation === 'Shop Weld').length}
                    tenPercentInCompleteSw={data.filter((x) => x.bhnPercentage === '10%' && x.bhnDate && x.weldLocation === 'Shop Weld').length}
                    tenPercentCompleteFw={data.filter((x) => x.bhnPercentage === '10%' && x.weldLocation === 'Field Weld').length}
                    tenPercentInCompleteFw={data.filter((x) => x.bhnPercentage === '10%' && x.bhnDate && x.weldLocation === 'Field Weld').length}
                    tenPercentCompleteTw={data.filter((x) => x.bhnPercentage === '10%' && x.weldLocation === 'Tie-in Weld').length}
                    tenPercentInCompleteTw={data.filter((x) => x.bhnPercentage === '10%' && x.bhnDate && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentCompleteSw={data.filter((x) => x.bhnPercentage === '20%' && x.weldLocation === 'Shop Weld').length}
                    twentyPercentInCompleteSw={data.filter((x) => x.bhnPercentage === '20%' && x.bhnDate && x.weldLocation === 'Shop Weld').length}
                    twentyPercentCompleteFw={data.filter((x) => x.bhnPercentage === '20%' && x.weldLocation === 'Field Weld').length}
                    twentyPercentInCompleteFw={data.filter((x) => x.bhnPercentage === '20%' && x.bhnDate && x.weldLocation === 'Field Weld').length}
                    twentyPercentCompleteTw={data.filter((x) => x.bhnPercentage === '20%' && x.weldLocation === 'Tie-in Weld').length}
                    twentyPercentInCompleteTw={data.filter((x) => x.bhnPercentage === '20%' && x.bhnDate && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentCompleteSw={data.filter((x) => x.bhnPercentage === '100%' && x.weldLocation === 'Shop Weld').length}
                    hundredPercentInCompleteSw={data.filter((x) => x.bhnPercentage === '100%' && x.bhnDate && x.weldLocation === 'Shop Weld').length}
                    hundredPercentCompleteFw={data.filter((x) => x.bhnPercentage === '100%' && x.weldLocation === 'Field Weld').length}
                    hundredPercentInCompleteFw={data.filter((x) => x.bhnPercentage === '100%' && x.bhnDate && x.weldLocation === 'Field Weld').length}
                    hundredPercentCompleteTw={data.filter((x) => x.bhnPercentage === '100%' && x.weldLocation === 'Tie-in Weld').length}
                    hundredPercentInCompleteTw={data.filter((x) => x.bhnPercentage === '100%' && x.bhnDate && x.weldLocation === 'Tie-in Weld').length}
                    naSwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.bhnPercentage) && x.weldLocation === 'Shop Weld').length}
                    naFwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.bhnPercentage) && x.weldLocation === 'Field Weld').length}
                    naTwTotal={data.filter((x) => ['', null, 'N/A'].includes(x.bhnPercentage) && x.weldLocation === 'Tie-in Weld').length}
                  />
                </ContentBlock>
              </Section>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(WeldLogPage);