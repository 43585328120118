/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Chart } from 'react-google-charts';
// import moment from 'moment';
// COMPONENTS
import Table from 'react-bootstrap/Table';
import { HiOutlineRefresh } from 'react-icons/hi';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import SelectBlock from '../../../../components/SelectBlock';
import OverLay from '../../../../components/Overlay';
// SERVICES
import * as weldLogService from '../../../../services/project/weldLogService';
import * as projectService from '../../../../services/project/projectService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const KPIReportPage = (props) => {
  const { showAlert } = props;
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [contractors, setContractors] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState({ label: 'All', value: null });

  const [data, setData] = useState({
    flangeLogReport: [],
    testPackageReport: {
      totalTestPackages: 0,
      ndePercentageComplete: 0,
      hydroPercentageComplete: 0,
      postPercentageComplete: 0,
      packageSoldPercentageComplete: 0,
      overallTotalCompleted: 0
    },
    weldLogReport: {
      totalWelds: 0,
      totalShopWelds: 0,
      totalFieldWelds: 0,
      totalTieInWelds: 0,
      completedShopWelds: 0,
      completedFieldWelds: 0,
      completedTieInWelds: 0,
      acceptedShopWelds: 0,
      acceptedTieInFieldWelds: 0,
      rejectedShopWelds: 0,
      rejectedTieInFieldWelds: 0,
      shopWeldRejectionRate: 0,
      tieInFieldWeldRejectionRate: 0
    },
    ndeRequest: {
      completedNDERequest: 0,
      totalNDERequest: 0
    },
    psvLogReport: {
      totalApiTestReportCompleted: 0,
      totalPopTestReportCompleted: 0,
      totalPsv: 0,
      totalQcCompleted: 0
    }
  });
  const [flangeChartData, setFlangeChartData] = useState([
    ['Size', 'Total number of bolt ups', 'Bolt ups Reported Completed', 'Total bolt ups QC Complete', 'Total bolt ups QA Complete', 'Total Torque Complete']
  ]);
  const [flangeChartDataByPsv, setFlangeChartDataByPsv] = useState([
    ['Size', 'Total number of bolt ups', 'Bolt ups Reported Completed', 'Total bolt ups QC Complete', 'Total bolt ups QA Complete', 'Total Torque Complete']
  ]);
  const [calcData, setCalcData] = useState({
    totalNumberOfBoltUps: 0,
    boltUpsReportedComplete: 0,
    totalBoltUpsQcComplete: 0,
    totalBoltUpsQaComplete: 0,
    totalTorqueComplete: 0,
    percentReportedComplete: 0,
    percentRemaining: 0
  });
  const [calcDataByPsv, setCalcDataByPsv] = useState({
    totalNumberOfBoltUps: 0,
    boltUpsReportedComplete: 0,
    totalBoltUpsQcComplete: 0,
    totalBoltUpsQaComplete: 0,
    totalTorqueComplete: 0,
    percentReportedComplete: 0,
    percentRemaining: 0
  });
  const [flangeLogData, setFlangeLogData] = useState([]);
  const [tpChartData, setTpChartData] = useState([
    ['Type', '% Complete']
  ]);
  const [psvChartData, setPsvChartData] = useState([
    ['Report', '# Completed']
  ]);

  const [hasDataLoaded, setHasDataLoaded] = useState(false);
  const [totalWeldChartData, setTotalWeldChartData] = useState(['Weld Location', 'Total', 'Completed']);
  const [rejectWeldChartData, setRejectWeldChartData] = useState([]);
  const [ndeRequestData, setNDERequestData] = useState([
    ['NDE Request', 'Count']
  ]);
  const [flangeLogMetaData, setFlangeLogMetaData] = useState({
    totalFlange: 0,
    totalQAComplete: 0,
    totalQCComplete: 0,
    totalFlangeByPsv: 0,
    totalQACompleteByPsv: 0,
    totalQCCompleteByPsv: 0,
  });

  useEffect(() => {
    getContractors();
    getKPIReport();
  }, []);

  useEffect(() => {
    if (selectedContractor && hasDataLoaded) {
      getWeldLogKPIData();
    }
  }, [selectedContractor]);

  const getWeldLogKPIData = () => {
    setIsLoading(true);

    projectService.getKPIReportForWeldLogByContractor(projectId, selectedContractor.value || '').then((res) => {
      const tempData = { ...data };
      setData({ ...tempData, weldLogReport: res.weldLogReport });
      prepareTotalWeldLogChart(res.weldLogReport);
      prepareRejectedWeldLogChart(res.weldLogReport);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getContractors = () => {
    setIsLoading(true);

    weldLogService.getContractors(projectId).then((res) => {
      setContractors([{ label: 'All', value: null }, ...res]);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getKPIReport = () => {
    setIsLoading(true);
    projectService.getKPIReportData(projectId).then((res) => {
      setData(res);
      setFlangeLogData(res.flangeLogReport);
      prepareFlangeLogChart(res.flangeLogReport);
      prepareFlangeLogChartByPsv(res.flangeLogReportByPsv);
      prepareTestPackageChart(res.testPackageReport);
      prepareTotalWeldLogChart(res.weldLogReport);
      prepareRejectedWeldLogChart(res.weldLogReport);
      prepareNDERequestLogChart(res.ndeRequest);
      preparePsvLogChart(res.psvLogReport);
      setFlangeLogMetaData(res.flangeLogMetaData);
      setHasDataLoaded(true);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const preparePsvLogChart = (res) => {
    setPsvChartData(
      [
        ['Report', '# Completed'],
        ['Total Psv', res.totalPsv],
        ['QC Done', res.totalQcCompleted],
        ['Pop Test Report Completed', res.totalPopTestReportCompleted],
        ['API Report Completed', res.totalApiTestReportCompleted]
      ]
    );
  };

  const prepareTestPackageChart = (res) => {
    setTpChartData(
      [
        ['Type', '% Complete'],
        ['NDE% Complete', res.ndePercentageComplete],
        ['Hydro% Complete', res.hydroPercentageComplete],
        ['Post% Complete', res.postPercentageComplete],
        ['Package Sold%', res.packageSoldPercentageComplete]
      ]
    );
  };

  const prepareNDERequestLogChart = (res) => {
    setNDERequestData(
      [
        ['NDE Request', 'Count'],
        ['Total NDE Requests', res.totalNDERequest],
        ['Completed NDE Requests', res.completedNDERequest]
      ]
    );
  };

  const prepareTotalWeldLogChart = (res) => {
    setTotalWeldChartData(
      [
        ['Weld Location', 'Total', 'Completed'],
        ['Shop Weld', res.totalShopWelds, res.completedShopWelds],
        ['Field Weld', res.totalFieldWelds, res.completedFieldWelds],
        ['Tie In Weld', res.totalTieInWelds, res.completedTieInWelds]
      ]
    );
  };

  const prepareRejectedWeldLogChart = (res) => {
    setRejectWeldChartData(
      [
        ['Weld Location', 'Total', 'Accepted', 'Rejected'],
        ['Shop Weld', res.totalShopWelds, res.acceptedShopWelds, res.rejectedShopWelds],
        ['Field/Tie-in Weld', res.totalFieldWelds + res.totalTieInWelds, res.acceptedTieInFieldWelds, res.rejectedTieInFieldWelds],
      ]
    );
  };

  const prepareFlangeLogChart = (res) => {
    const totalNumberOfBoltUps = res.reduce((a, v) => a + v.totalNumberOfBoltUps, 0);
    const boltUpsReportedComplete = res.reduce((a, v) => a + v.boltUpsReportedComplete, 0);
    const totalBoltUpsQcComplete = res.reduce((a, v) => a + v.totalBoltUpsQcComplete, 0);
    const totalBoltUpsQaComplete = res.reduce((a, v) => a + v.totalBoltUpsQaComplete, 0);
    const totalTorqueComplete = res.reduce((a, v) => a + v.totalTorqueComplete, 0);
    const percentReportedComplete = totalNumberOfBoltUps === 0 ? 0 : boltUpsReportedComplete / totalNumberOfBoltUps;
    const percentRemaining = 100 - (percentReportedComplete * 100);

    setCalcData({
      totalNumberOfBoltUps,
      boltUpsReportedComplete,
      totalBoltUpsQcComplete,
      percentReportedComplete,
      percentRemaining
    });

    const modifiedChartData = [...flangeChartData, ['Total', totalNumberOfBoltUps, boltUpsReportedComplete, totalBoltUpsQcComplete, totalBoltUpsQaComplete, totalTorqueComplete]];

    adjustChartData(res, modifiedChartData);
  };

  const prepareFlangeLogChartByPsv = (res) => {
    const totalNumberOfBoltUps = res.reduce((a, v) => a + v.totalNumberOfBoltUps, 0);
    const boltUpsReportedComplete = res.reduce((a, v) => a + v.boltUpsReportedComplete, 0);
    const totalBoltUpsQcComplete = res.reduce((a, v) => a + v.totalBoltUpsQcComplete, 0);
    const totalBoltUpsQaComplete = res.reduce((a, v) => a + v.totalBoltUpsQaComplete, 0);
    const totalTorqueComplete = res.reduce((a, v) => a + v.totalTorqueComplete, 0);

    const percentReportedComplete = totalNumberOfBoltUps === 0 ? 0 : boltUpsReportedComplete / totalNumberOfBoltUps;
    const percentRemaining = 100 - (percentReportedComplete * 100);

    setCalcDataByPsv({
      totalNumberOfBoltUps,
      boltUpsReportedComplete,
      totalBoltUpsQcComplete,
      percentReportedComplete,
      percentRemaining
    });

    const modifiedChartData = [...flangeChartDataByPsv, ['Total', totalNumberOfBoltUps, boltUpsReportedComplete, totalBoltUpsQcComplete, totalBoltUpsQaComplete, totalTorqueComplete]];

    adjustChartDataByPsv(res, modifiedChartData);
  };

  const adjustChartData = (arr, modifiedChartData) => {
    arr.forEach((x) => {
      modifiedChartData.push([x.size, x.totalNumberOfBoltUps, x.boltUpsReportedComplete, x.totalBoltUpsQcComplete, x.totalBoltUpsQaComplete, x.totalTorqueComplete]);
    });

    setFlangeChartData(modifiedChartData);
    console.log(calcData, flangeLogData, flangeLogMetaData);
  };

  const adjustChartDataByPsv = (arr, modifiedChartData) => {
    arr.forEach((x) => {
      modifiedChartData.push([x.size, x.totalNumberOfBoltUps, x.boltUpsReportedComplete, x.totalBoltUpsQcComplete, x.totalBoltUpsQaComplete, x.totalTorqueComplete]);
    });

    setFlangeChartDataByPsv(modifiedChartData);
    console.log(calcDataByPsv, flangeLogData);
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="KPI Report"
                headerSize="lg"
                primaryButtonText="Refresh"
                primaryButtonIconLeft={<HiOutlineRefresh className="white--clr" size={24} />}
                primaryButtonOnClick={() => window.location.reload()}
              />

              <ContentBlock cols={12} className="mb-30">
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="400px"
                  data={tpChartData}
                  options={{
                    chart: {
                      title: 'Composite Test Package Log Report',
                      subtitle: 'Test Package % complete',
                    },
                  }}
                />
              </ContentBlock>
              <ContentBlock cols={12} className="d-flex">
                <Table
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <th>Total Test Packages</th>
                      <th>NDE% Complete</th>
                      <th>Hydro% Complete</th>
                      <th>Post% Complete</th>
                      <th>Package Sold%</th>
                      <th>Overall Total #</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.testPackageReport.totalTestPackages}</td>
                      <td>
                        {(data.testPackageReport.ndePercentageComplete).toFixed(2)}
                        %
                      </td>
                      <td>
                        {(data.testPackageReport.hydroPercentageComplete).toFixed(2)}
                        %
                      </td>
                      <td>
                        {(data.testPackageReport.postPercentageComplete).toFixed(2)}
                        %
                      </td>
                      <td>
                        {(data.testPackageReport.packageSoldPercentageComplete).toFixed(2)}
                        %
                      </td>
                      <td>{data.testPackageReport.overallTotalCompleted}</td>
                    </tr>
                  </tbody>
                </Table>
              </ContentBlock>

              <Section hasNoContainer className="mb-30">
                {/* <ContentBlock cols={5}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Size</th>
                        <th>Total number of bolt ups</th>
                        <th>Bolt ups Reported Complete</th>
                        <th>Total bolt ups QC Complete</th>
                        <th>Percent Reported complete</th>
                        <th>Percent remaining</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Total</th>
                        <td>{calcData.totalNumberOfBoltUps}</td>
                        <td>{calcData.boltUpsReportedComplete}</td>
                        <td>{calcData.totalBoltUpsQcComplete}</td>
                        <td>
                          {(calcData.percentReportedComplete * 100).toFixed(2)}
                          %
                        </td>
                        <td>
                          {calcData.percentRemaining.toFixed(2)}
                          %
                        </td>
                      </tr>
                      {
                        flangeLogData.map((x) => (
                          <tr>
                            <td>{x.size}</td>
                            <td>{x.totalNumberOfBoltUps}</td>
                            <td>{x.boltUpsReportedComplete}</td>
                            <td>{x.totalBoltUpsQcComplete}</td>
                            <td>
                              {(x.percentReportedComplete * 100).toFixed(2)}
                              %
                            </td>
                            <td>
                              {x.totalNumberOfBoltUps === 0 ? '0.00' : x.percentRemaining.toFixed(2)}
                              %
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </ContentBlock> */}

                <ContentBlock cols={12} className="mt-20">
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={flangeChartData}
                    options={{
                      chart: {
                        title: 'Composite Flange Tracker Summary by Test Package',
                        subtitle: 'Flange Bolt-up Completion Summary',
                      },
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={12} className="mt-20">
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={flangeChartDataByPsv}
                    options={{
                      chart: {
                        title: 'Composite Flange Tracker Summary by PSV Log',
                        subtitle: 'Flange Bolt-up Completion Summary',
                      },
                    }}
                  />
                </ContentBlock>
              </Section>

              <ContentBlock cols={3} className="mb-20">
                <SelectBlock
                  label="Contractor"
                  placeholder="Select a Contractor"
                  id="show-project"
                  name="show-project"
                  options={contractors}
                  isRequired
                  value={selectedContractor}
                  onChange={(opt) => {
                    setSelectedContractor(opt);
                  }}
                />
              </ContentBlock>

              <Section hasNoContainer className="mb-30">
                <ContentBlock cols={12} className="mt-20">
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={totalWeldChartData}
                    options={{
                      chart: {
                        title: 'Composite Weld Log Completion Summary',
                        subtitle: 'Weld Log Completion Summary',
                      },
                      colors: ['#4285f4', '#0f9d58']
                    }}
                  />
                </ContentBlock>
              </Section>

              <Section hasNoContainer>
                <ContentBlock cols={2}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Total Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.weldLogReport.totalWelds}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>

                <ContentBlock cols={5}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Total Shop Welds</th>
                        <th>Total Field Welds</th>
                        <th>Total Tie-in Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.weldLogReport.totalShopWelds}</td>
                        <td>{data.weldLogReport.totalFieldWelds}</td>
                        <td>{data.weldLogReport.totalTieInWelds}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>

                <ContentBlock cols={5}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Completed Shop Welds</th>
                        <th>Completed Field Welds</th>
                        <th>Completed Tie-in Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.weldLogReport.completedShopWelds}</td>
                        <td>{data.weldLogReport.completedFieldWelds}</td>
                        <td>{data.weldLogReport.completedTieInWelds}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>

                <Section hasNoContainer className="mb-30">
                  <ContentBlock cols={12} className="mt-20">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="400px"
                      data={rejectWeldChartData}
                      options={{
                        chart: {
                          title: 'Composite Acc/Rej Weld Summary',
                          subtitle: 'Weld Log Acc/Rej Summary',
                        },
                        colors: ['#4285f4', '#0f9d58', '#db4437']
                      }}
                    />
                  </ContentBlock>
                </Section>

                <ContentBlock cols={6}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Rejection Rate</th>
                        <th>Total Shop Weld</th>
                        <th>Accepted Shop Welds</th>
                        <th>Rejected Shop Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {(data.weldLogReport.shopWeldRejectionRate).toFixed(2)}
                          %
                        </td>
                        <td>{data.weldLogReport.totalShopWelds}</td>
                        <td>{data.weldLogReport.acceptedShopWelds}</td>
                        <td>{data.weldLogReport.rejectedShopWelds}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>

                <ContentBlock cols={6}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Rejection Rate</th>
                        <th>Total Field/Tie-in Weld</th>
                        <th>Accepted Field/Tie-in Welds</th>
                        <th>Rejected Field/Tie-in Welds</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {(data.weldLogReport.tieInFieldWeldRejectionRate).toFixed(2)}
                          %
                        </td>
                        <td>{data.weldLogReport.totalTieInWelds + data.weldLogReport.totalFieldWelds}</td>
                        <td>{data.weldLogReport.acceptedTieInFieldWelds}</td>
                        <td>{data.weldLogReport.rejectedTieInFieldWelds}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>

                <ContentBlock cols={12} className="mt-30 mb-30">
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={psvChartData}
                    options={{
                      chart: {
                        title: 'Composite PSV Log Report',
                        subtitle: 'PSV completed report',
                      },
                    }}
                  />
                </ContentBlock>

                <Section hasNoContainer className="mb-30">
                  <ContentBlock cols={12} className="mt-20">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="400px"
                      data={ndeRequestData}
                      options={{
                        chart: {
                          title: 'NDE Request Form Completion Summary',
                          subtitle: 'NDE Request Form Summary',
                        },
                        colors: ['#4285f4', '#0f9d58', '#db4437']
                      }}
                    />
                  </ContentBlock>
                </Section>

                <ContentBlock cols={12}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Total NDE Request</th>
                        <th>Completed NDE Request</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data.ndeRequest.totalNDERequest}</td>
                        <td>{data.ndeRequest.completedNDERequest}</td>
                      </tr>
                    </tbody>
                  </Table>
                </ContentBlock>
              </Section>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(KPIReportPage);