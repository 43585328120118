/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable import/order */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// CUSTOM COMPONENTS
import Overlay from '../../../../../components/Overlay';
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import InputBlock from '../../../../../components/InputBlock';
import Button from '../../../../../components/Button';
import SelectBlock from '../../../../../components/SelectBlock';
import ModalBlock from '../../../../../components/ModalBlock';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as contractorService from '../../../../../services/management/contractorService';
import ContractorValidator from '../../../../../helpers/validators/management/contractor/ContractorValidator';
import * as helper from '../../../../../helpers/helper';

const initialContractorModel = {
  id: 0,
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  isActive: true,
  users: []
};

const ContractorManagementPage = (props) => {
  const {
    showAlert,
    history,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const { contractorId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contractor, setContractor] = useState(initialContractorModel);
  const [users, setUsers] = useState([]);
  const [selectedUserOptions, setSelectedUserOptions] = useState([]);

  useEffect(() => {
    if (contractorId) {
      populateForm();
      fetchUsers();
    }
  }, []);

  const formik = useFormik({
    initialValues: contractor,
    validationSchema: ContractorValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (contractorId) {
        updateContractor(values);
      } else {
        createContractor(values);
      }
    },
  });

  const populateForm = () => {
    setIsLoading(true);
    contractorService.getContractorById(contractorId).then((res) => {
      setContractor(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchUsers = () => {
    contractorService.getAvailableUsers().then((res) => {
      setUsers(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'danger' });
    });
  };

  const createContractor = (model) => {
    setIsLoading(true);
    contractorService.createContractor(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/management/contractor/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateContractor = (model) => {
    setIsLoading(true);
    contractorService.updateContractor(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove  User',
                  text: 'Are you sure you want to remove this user from this contractor?',
                  rightBtnText: 'Confirm',
                  btnAction: () => removeUserAssignment(contractor.users[dataIndex].id)
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'User',
    },
    {
      name: 'role',
      label: 'Role',
    }
  ];

  const removeUserAssignment = (id) => {
    handleModalClose();
    hideConfirmModal();
    setIsLoading(true);
    contractorService.removeUserAssignment(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      populateForm();
      fetchUsers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const handleModalClose = () => {
    setIsModalVisible(false);

    setTimeout(() => {
      setSelectedUserOptions([]);
    }, 100);
  };

  const saveContractorUsers = () => {
    if (selectedUserOptions.length <= 0) {
      return;
    }
    setIsLoading(true);
    contractorService.assignContractorUsers({
      contractorId: parseInt(contractorId, 10),
      users: selectedUserOptions
    }).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      populateForm();
      handleModalClose();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };
  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.handleSubmit}>
            <ContentHeader
              title="Contractor Details"
              headerSize="lg"
            />

            <Section hasNoContainer>
              <ContentBlock>
                <ContentHeader
                  title="Basic Information"
                  headerSize="md"
                  className="alt-font fw-600 pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Contractor Name"
                  isRequired
                  errorMessage={formik.errors.name}
                  inputState={`${helper.getInputClasses(formik, 'name')}`}
                  {...formik.getFieldProps('name')}
                />
              </ContentBlock>

              <ContentBlock>
                <CheckboxBlock
                  label="Is active?"
                  id="isActive"
                  {...formik.getFieldProps('isActive')}
                  isChecked={formik.values.isActive}
                />
              </ContentBlock>

              <ContentBlock className="mt-30">
                <ContentHeader
                  title="Location Information"
                  headerSize="md"
                  className="alt-font fw-600 alt--clr pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Street Address"
                  placeholder="e.g. 3900 N Causeway Blvd"
                  isRequired
                  errorMessage={formik.errors.address1}
                  inputState={`${helper.getInputClasses(formik, 'address1')}`}
                  {...formik.getFieldProps('address1')}
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Suite/Unit/Bldg No."
                  placeholder="e.g. #1350"
                  errorMessage={formik.errors.address2}
                  inputState={`${helper.getInputClasses(formik, 'address2')}`}
                  {...formik.getFieldProps('address2')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="City"
                  placeholder="e.g. New Orleans"
                  isRequired
                  errorMessage={formik.errors.city}
                  inputState={`${helper.getInputClasses(formik, 'city')}`}
                  {...formik.getFieldProps('city')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="State"
                  placeholder="e.g. Louisiana"
                  isRequired
                  errorMessage={formik.errors.state}
                  inputState={`${helper.getInputClasses(formik, 'state')}`}
                  {...formik.getFieldProps('state')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="Zip Code"
                  placeholder="e.g. 70002"
                  mask="99999"
                  isRequired
                  errorMessage={formik.errors.zipCode}
                  inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                  {...formik.getFieldProps('zipCode')}
                />
              </ContentBlock>

              {
                (contractorId)
                && (
                  <ContentBlock className="mt-60">
                    <ContentHeader
                      title="Contractor Users"
                      headerSize="md"
                      className="secondary--clr alt-font fw-600 pb-0"
                      primaryButtonText="Assign Contractor Users"
                      primaryButtonIconLeft={<IoMdAddCircle className="white--clr" size={22} />}
                      primaryButtonOnClick={() => {
                        setIsModalVisible(true);
                      }}
                    />
                    <CustomBlock>
                      <MUIDataTable
                        data={contractor.users}
                        columns={columns}
                        options={{
                          selectableRows: 'none',
                          filter: false,
                          sort: false,
                          print: false,
                          download: false,
                        }}
                      />
                    </CustomBlock>
                  </ContentBlock>
                )
              }

              <ContentBlock className="content-container--actions flex-end">
                <Button
                  text="Cancel"
                  className="danger--bg"
                  onClick={() => history.push('/management/contractors')}
                />

                <Button
                  text={contractorId ? 'Save Changes' : 'Create'}
                  className="primary--bg"
                  onClick={formik.handleSubmit}
                />
              </ContentBlock>
            </Section>
          </FormBlock>
        </Section>

        {/* ASSIGNMENT MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={isModalVisible}
          size="md"
          contentHeader="Assign Users"
          primaryModalActionText={users.length > 0 ? 'Add' : ''}
          primaryModalActionColor="primary--bg"
          primaryModalActionOnClick={saveContractorUsers}
          secondaryModalActionText="Cancel"
          secondaryModalActionColor="danger--bg"
          onHide={handleModalClose}
        >
          <FormBlock>
            <Section hasNoContainer>
              <ContentBlock>
                {
                  users.length > 0 ? (
                    <SelectBlock
                      label="Select Users"
                      options={users}
                      isRequired
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedUserOptions}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? [] : selectedOpt;
                        setSelectedUserOptions(selectedOpt);
                      }}
                    />
                  )
                    : (
                      <strong>
                        No Users Available. All users are assigned to other contractors.
                      </strong>
                    )
                }

              </ContentBlock>
            </Section>
          </FormBlock>
        </ModalBlock>
      </CustomBlock>
    </>
  );
};

export default connect(null, {
  ...alert.actions,
  ...confirmModal.actions
})(ContractorManagementPage);